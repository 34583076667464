.payment-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .paymentsuccess-img {
    width: 45%;
    height: 90vh;
  }
  
  .payment-success p {
    font-size: 16px;
    font-weight: 500;
    color: #555;
  }
  .payment-success .back-btn {
    color: #e91e63;
    font-size: 16px;
    font-weight: 500;
    /* color: #555; */
  }
  @media screen and (min-width: 1400px) and (max-width: 1900px) {
    .paymentsuccess-img {
      width: 40%;
      height: 65vh;
    }
  }
  @media screen and (min-width: 786px) and (max-width: 1400px) {
    .paymentsuccess-img {
      width: 60%;
      height: 55vh;
    }
    .payment-success h3 {
      font-size: 28px;
    }
    .payment-success p {
      padding: 10px;
      font-size: 17px;
    }
    .payment-success .back-btn {
      font-size: 17px;
    }
  }
  @media screen and (max-width: 786px) and (min-width: 520px) {
    .paymentsuccess-img {
      width: 75%;
      height: 45vh 
    }
    .payment-success h3 {
      font-size: 30px;
    }
    .payment-success p {
      padding: 10px;
      font-size: 18px;
    }
    .payment-success .back-btn {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 520px) {
    .paymentsuccess-img {
      width: 70%;
      height: 35vh;
    }
    .payment-success h3 {
      font-size: 22px;
    }
    .payment-success p {
      padding: 10px;
      font-size: 14px;
    }
    .payment-success .back-btn {
      font-size: 14px;
    }
  }
  