/* Boost Listing */
.boost-listing {
    width: 100%;
  }
  
  .boost-header {
    background-color: #43455c;
    color: #fff;
    font-size: 14px;
    padding: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .boost-header button {
    background-color: unset;
    border: none;
    outline: none;
    font-size: 18px;
    width: 30px;
  }
  
  .boost-body {
    padding: 20px;
  }
  
  .boost-body-listing {
    border: 1px solid #ddd;
  }
  
  .boost-body-subscription {
    width: 200px;
    border: 1px solid #ddd;
    margin: 15px 0px;
    overflow: hidden;
    position: relative;
  }
  
  .boost-body-subscription-toppart {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .checkmark {
    height: 45px;
    width: 48px;
    background-color: #5cd1b7;
    rotate: 120deg;
    margin-left: -30px;
    margin-top: -29px;
  }
  .checkmark1 {
    height: 45px;
    width: 48px;
    background-color: gray;
    rotate: 120deg;
    margin-left: -30px;
    margin-top: -29px;
  }
  
  .check-mark {
    position: absolute;
    top: 95px;
    left: 20px;
    color: #fff
  }
  
  .boost-body {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .boost-body-subscription-text {
    text-align: center;
    color: #2b98d5;
    font-size: 13px;
    padding: 10px;
  }
  .boost-body-subscription-text1 {
    text-align: center;
    color: gray;
    font-size: 13px;
    padding: 10px;
  }
  
  .boost-body-footer-text {
    font-size: 14px;
    font-weight: 600;
    color: #555;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .boost-body-footer-text button {
    background-color: #2b98d5;
    color: #fff;
    outline: none;
    border: none;
    padding: 10px;
  
  }
  
  
  
  /* Step2 */
  
  .boostlisting-step2 {
    width: 100%;
  }
  
  .boostlisting-step2-header {
    background-color: #43455c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .boostlisting-step2-header div {
    font-size: 14px;
    color: #fff;
  }
  
  .boostlisting-step2-header button {
    background-color: unset;
    outline: none;
    border: none;
  }
  
  .boostlisting-step2-body {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 30px 20px;
  }
  
  .alldata {
    background-color: #5cd1b7;
    padding: 15px;
    color: #fff;
  }
  
  .alldata p {
    font-size: 11px;
    margin-bottom: 0px;
  }
  
  .display-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
  }
  
  .alldata-header {
    font-size: 14px;
  }
  
  .boost-for-visibility {
    padding-top: 30px;
    color: #bbb;
  }
  
  .boost-for-visibility-header {
    font-size: 12px;
  }
  
  .boost-for-visibility-boxbody {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
  
  }
  
  .boost-for-visibility-singlebox {
    border: 1px solid #ddd;
    padding: 10px 20px;
    font-size: 12px
  }
  
  .boost-for-visibility-lastbox {
    border-bottom: 1px solid #ddd;
    width: 220px;
    font-size: 12px;
  }
  
  .boost-for-visibility-lastbox input {
    margin-top: 25px;
    width: 45px;
    border: 1px solid #bbb;
    color: #bbb;
    font-size: 14px;
    /* margin: 5px 0px; */
  }
  
  .boosting-tnc {
    font-size: 12px;
    color: #000;
  }
  
  .boosting-btn {
    background-color: #2b98d5;
    color: #fff;
    outline: none;
    border: none;
    padding: 10px;
    margin: 5px;
  }
  
  .boosting-step2-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
  }
  
  
  
  
  
  /* Step3 */
  .boostlisting-step3 {
    width: 100%;
  }
  
  .boostlisting-step3-header {
    background-color: #43455c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .boostlisting-step3-header div {
    font-size: 12px;
    color: #fff;
  }
  
  .boostlisting-step3-header button {
    background-color: unset;
    outline: none;
    border: none;
  }
  
  .boostlisting-step3-body {
    padding: 20px 30px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .display-between {
    display: flex;
  
    /* width: 70%; */
  }
  
  .boost-surity {
    font-size: 14px;
    font-weight: 600;
  }
  
  .boostlisting-step3-body-subhead {
    padding: 10px;
    width: 70%;
  }
  
  .boostlisting-step3-body-subhead div {
    margin-top: 3px;
  }
  
  .boostlisting-step3-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    padding: 15px 10px 0px 10px;
  }
  
  
  
  
  /* SKipToPost */
  .skip-to-post {
    width: 100%;
    padding: 15px 60px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .skip-to-post-top {
    /* width: 90%; */
    padding: 0px 15px;
    border: 1px solid #ddd;
    font-size: 11px;
  }
  
  .skip-to-post-advertise-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .skip-to-post-advertise-details img {
    height: 60px;
    width: 60px;
  }
  
  .listing-box {
    border: 1px solid #f3f3f6;
    padding: 10px 25px;
    margin: 5px;
    font-size: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    gap: 15px;
  }
  
  .listing-last-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
  }
  
  .listing-last-box-inner {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    font-size: 11px;
    padding: 3px;
  }
  
  .listing-last-box-inner input {
    border: none;
  
  }
  
  .skip-to-post-top-midpart {
    margin-top: 25px;
  }
  
  .skip-to-post-bottom-midpart {
    background-color: #effbf9;
    padding: 30px 50px;
  }
  
  .skip-to-post-bottom-midpart-leftpanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 11px;
  }
  
  .skip-to-post-bottom-midpart-leftpanel .title {
    font-size: 17px;
    font-weight: 600;
  }
  
  .skip-to-post-bottom-midpart-leftpanel p {
    text-align: center;
    margin-top: 15px;
  }
  
  .skip-to-post-bottom-midpart-rightpanel {
    background-color: #fff;
    padding: 10px;
  }
  
  .skip-to-post-bottom-midpart-rightpanel .listing-plan {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 11px;
  }
  
  .listing-plans-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .font-small-bold {
    font-size: 11px;
    font-weight: 600;
  }
  
  .skip-to-post-bottom {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  
  .skip-to-post-bottom button {
    padding: 10px 20px;
    font-size: 12px;
    border: none;
    outline: none;
    background-color: #0077df;
    color: #fff;
  }
  
  
  
  
  
  /* choosingSubcription */
  .choosing-subscription {
    width: 100%;
    padding: 30px 100px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .choosing-subscription-body {
    height: 360px;
  }
  
  .choosing-subscription-body-title {
    font-size: 16px;
    font-weight: 600;
  }
  
  .choosing-subscription-body-subtitle {
    font-size: 13px;
  }
  
  .filter-options {
    font-weight: 600;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
  }
  
  .filter {
    border: 1px solid #ddd;
    padding: 2px 4px;
    font-size: 10px;
    color: #555;
  }
  
  .filter-unique {
    background-color: #f8fffc;
    border: 1px solid #2b98d5;
    border-radius: 35px;
    height: 20px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-size: 10px;
  }
  
  .subscription-plans {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    /* border: 1px solid #ddd; */
    box-shadow: rgba(43, 152, 213, .2) 0px 8px 7px 0px;
    padding: 20px;
    gap: 40px;
    margin-top: 40px;
    font-size: 10px;
  }
  
  .choosing-subscription-footer {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .choosing-subscription-footer button {
    padding: 6px 25px;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 3px;
    background-color: rgba(43, 152, 213, .5)
  }
  
  .choosing-subscription-footer p {
    color: #bbb;
    flex-wrap: wrap;
    font-size: 9px;
  }
  
  
  /* property-features */
  .property-features {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 10px;
    width: 100%;
  }
  
  .cancel-btn-grp {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .cancel-btn-grp button{
    background-color: unset;
    border: none;
    outline: none;
  }
  .property-features-box {
    padding:5px 15px;
  }
  .describe-features-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  .describe-features{
    font-size: 14px;
    font-weight: 600;
    color: #555;
  }
  .property-changes{
    background-color: #f5ffff;
    padding: 15px;
    font-size: 12px;
  }
  .feature-box{
    border: 1px solid #ddd;
    font-size: 10px;
    padding: 3px;
    width: 300px;
    margin: 5px;
  }
  .feature-box select{
    width: 100%;
    border: unset;
    font-weight: 600;
    color: #555;
  }
  .property-features-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    padding:15px 30px;
  } 
  .property-features-footer button{
    padding: 6px 12px;
    margin: 5px;
    border: none;
    outline: none;
    border-radius: 3px;
  }