/* dashboard styles  */
.component__card .component__card_container1 .cardpremium span {
  background-color: #036157;
  padding: 4px 8px 4px 8px;
  color: #fff;
}

/* listings css */

.modalpesponses {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 696px;
}
.modalpesponsespremium {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 1300px;
}
.modal-body {
  padding: 0;
}

.labeltext {
  color: gray;
  font-weight: 500;
  width: 300px;
  font-size: 16px;
}
.please-paragraph {
  color: gray;
  font-weight: 400;
  font-size: 16px;
}

.recall-text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
.remaining-text {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.listings {
  color: #000;
  font-size: 16px;
}
.bistingbold {
  font-weight: 600;
}

.mainrecallbox {
  padding: 20px;
  padding-left: 40px;
  padding-top: 10px;
}
.recallmaintexttop {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.areyoutext {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
.buttonprevois {
  font-size: 14px !important;
  padding: 12px 34px;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 15px;
}
.buttonboost {
  font-size: 14px !important;
  padding: 12px 34px;
  color: #fff;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  margin-top: 15px;
}

/* Ganesh  */
.responsesone-text {
  color: #000;
  font-size: 14px;
}

@media only screen and (max-width: 600px) and (min-width: 360px) {
  .labeltext {
    font-size: 12px;
  }
  .please-paragraph {
    color: gray;
    font-size: 13px;
  }

  .recall-text {
    font-size: 12px;
    margin: 0;
  }
  .remaining-text {
    font-size: 12px;
  }
  .listings {
    font-size: 12px;
  }
  .bistingbold {
    font-weight: 600;
  }
  .mainrecallbox {
    padding: 5px;
  }
  .recallmaintexttop {
    font-size: 14px;
  }

  .firstboxres {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .secondboxres {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .thirdboxres {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .responsesone-text {
    font-size: 12px;
  }
  .firstboxres p,
  .secondboxres p,
  .thirdboxres p {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .firstboxres {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .secondboxres {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .thirdboxres {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .responsesone-text {
    font-size: 12px;
    /* margin-bottom: 0 !important; */
  }
  .firstboxres p,
  .secondboxres p,
  .thirdboxres p {
    margin-bottom: 0 !important;
  }
}

.modalopen {
  position: absolute;
  background: white;
  right: 0;
  z-index: 1;
  /* padding:30px; */
  justify-content: center;
  display: flex;
}

.secondbox-mainbox{
  margin-bottom: 10px;
}

.card-blue-main {
  background: #f5ffff;
  border: 1px solid #eee;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 500px) and (min-width: 360px) {
  .modalboxone {
    width: 100% !important;
  }
  .modalboxtwo {
    width: 100% !important;
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .labeltext {
    width: fit-content !important;
  }
  .download-responses-button {
    font-size: 14px !important;
  }
  .download-raw-file {
    font-size: 14px !important;
  }
}
.download-responses-button {
  font-size: 14px;
}
.download-raw-file {
  font-size: 14px;
}

/* premium text styles  */
.subuserbox {
  display: flex;
  justify-content: space-around;
}
.span_getleadssubuser {
  color: #3270fc;
  font-size: 14px;
  font-weight: 500;
}

.cardboxone {
  width: 500px;
  align-items: center;
  display: grid;
  justify-content: center;
}
.cardboxtwo {
  width: 100px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.cardboxthree {
  width: 300px;
  align-items: center;
  display: grid;
  justify-content: center;
}
.subcardboxone {
  display: flex;
}

@media only screen and (max-width: 768px) and (min-width: 360px) {
  .subuserbox {
    display: grid;
    justify-content: flex-end;
  }
  .span_getleadssubuser {
    /* width: 80px; */
    font-size: 10px;
    /* background: pink; */
  }
  .iconspremium {
    text-align: center;
  }
  .iconsflexbox {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .premuiumtext {
    font-size: 12px;
  }
  .cardboxone {
    width: 100% !important;
    /* align-items: center; */
    /* display: flex; */
    justify-content: flex-start;
  }
  .subcardboxone {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    /* align-items: center;
   
    justify-content: center; */
  }
  .cardboxtwo {
    /* width: 100% !important; */
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    /* align-items: center;
    justify-content: center; */
  }
  .cardboxthree {
    width: 100% !important;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }

  .cardtext {
    font-size: 10px;
  }

  .cautionimage {
    margin-right: 5px;
    margin-left: 5px;
  }
  .component__card_container2 p {
    margin-left: 0 !important;
    margin: 0;
  }
  .component__card_container3 p {
    margin-left: 0 !important;
    margin: 0;
  }
  .deatilsboxone {
    width: 100% !important;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
  }
  .detailsboxtwo {
    width: 100% !important;
    align-items: flex-start;
    display: grid;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .detailsboxthree {
    width: 100% !important;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .span_getleads-assign {
    font-size: 10px !important;
  }

  /* ======================================= */
  .card-assign-align {
    /* background-color: red; */
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }

  .cardpremium {
    /* align-items: center;
    display: flex; */
    margin-top: 8px;
  }
}

.bottomcard-design {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.summary-text-box {
  margin-left: 15px;
}

.deatilsboxone {
  width: 250px;
}
.detailsboxtwo {
  width: 250px;
}
.detailsboxthree {
  width: 250px;
}

.the-add-text {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-left: 0px;
}
.propertytext {
  color: #000;
}
.navblue-text {
  color: #3270fc;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  margin-right: 10px;
}
.cautionimage {
  width: 30px;
  background: #fdf7e4;
  border-radius: 50px;
  padding: 2px;
  margin-left: 10px;
  margin-right: 10px;
}
.span_getleads-assign {
  color: #3270fc;
  font-size: 13px;
  font-weight: 500;
}
.mainbarcircular {
  background-color: transparent !important;
  padding: 0 !important;
  border-radius: 12px;
  margin-top: 0 !important;
  display: flex;
}

.qualityScoreWidget_propertyScore__28KaJ .circularbarsizing {
  width: 45px !important;
  flex: 45px 0 !important;
  height: 45px !important;
}

/* budget drop down styles  */

.budget-select {
  padding: 10px;
  font-size: 1em;
  font-size-adjust: 0.3;
  margin: 5px;
}
.budget-select option {
  padding: 10px;
}

.clear-all-filters-button {
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  margin: 0;
  vertical-align: middle;
  cursor: pointer;
  min-height: 40px;
  border: 1px #0078db solid;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #0078db;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

/* premium modal new styles  */
.youarespedning {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}
.areyoutext {
  text-align: left;
}
