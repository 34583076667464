/* BottomSheet.css */

.mainrbscontainer {
  display: flex;
}
.slidervsbox {
  width: 30%;
  background-color: rgb(244, 245, 247);
  height: 75vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.slidervsbox::-webkit-scrollbar {
  display: none;
}

.rbsnav p {
  margin-top: 30px;
  margin-bottom: 30px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  font-size: 14px;
  overflow-wrap: break-word;
  font-weight: 500;
}

.mainrbsbox {
  width: 70%;
  /* background-color: #ddd; */
  height: 75vh;
  overflow-y: scroll;
  scrollbar-width: none;
}
.mainrbsbox::-webkit-scrollbar {
  display: none;
}

.bottom-sheet {
  position: fixed;
  bottom: -100%;
  left: 0;
  right: 0;
  background-color: transparent;
  transition: bottom 0.3s ease-out;
  z-index: 99999;
}

.bottom-sheet.open {
  /* position: fixed;
    bottom: 10%;
    left: 0;
    right: 0;
    background-color: #fff;
    transition: bottom 0.3s ease-out;
    z-index: 99999; */
  bottom: 0;
}

.bottom-sheet-content {
  /* padding: 20px; */
  /* Add other styles as needed */
}

.bottomdiv {
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: relative;
}

.bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90vh;
  background-color: white;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  /* padding: 16px; */
  padding: 5px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  z-index: 99999;
}

.rbstopcontent {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}

.filtertext {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}
.clearalltext {
  color: #0078db;
  font-size: 12px;
  font-weight: 500;
}

.mainbarfiltercomponents {
  margin-left: 5px;
  margin-right: 5px;
}

.propertyboxes {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.propertyboxes input[type="checkbox" i] {
  height: 18px !important;
  width: 15px !important;
}
.propertytextpara {
  margin-top: 5px;
  margin-left: 3px;
}

.propertyboxes p {
  color: #000;
  font-size: 16px;
}

.verifiedgreentext {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
}
.greenbarcolors {
  background-color: #28ad35;
  padding: 1px;
  display: flex;
  height: fit-content;
  border-radius: 2px;
  padding-left: 2px;
  padding-right: 6px;
}
.bymgh {
  font-weight: 400;
  font-size: 12px;
  color: #000;
}

.topverifiedbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 2px 2px 3px #eee;
  padding: 3px;
  margin: 3px;
}
.budgettext {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}
.inrtext {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.circularborder {
  border: 1px solid #ddd;
  border-radius: 30px;
  width: 80px;
}

.zerotext {
  margin-left: 5px;
}
.hundredtext {
  margin-left: 5px;
}

.sliderpartmain {
  display: flex;
}
.sliderpartmain .slidernumberingone {
  width: 50%;
  height: 66vh;
  overflow-y: scroll;
  scrollbar-width: none;
}
.sliderpartmain .slidernumberingone p {
  text-align: center;
  padding: 5px;
  color: #000;
  font-size: 14px;
}
.sliderpartmain .slidernumberingtwo {
  width: 50%;
  height: 66vh;
  overflow-y: scroll;
  scrollbar-width: none;
}
.sliderpartmain .slidernumberingtwo::-webkit-scrollbar {
  display: none;
}
.sliderpartmain .slidernumberingone::-webkit-scrollbar {
  display: none;
}
.sliderpartmain .slidernumberingarea::-webkit-scrollbar {
  display: none;
}
.sliderpartmain .slidernumberingtwo p {
  text-align: center;
  padding: 5px;
  color: #000;
  font-size: 14px;
}

.sliderpartmain .slidernumberingarea {
  width: 100%;
  height: 66vh;
  overflow-y: scroll;
  scrollbar-width: none;
}
.sliderpartmain .slidernumberingarea p {
  text-align: center;
  padding: 5px;
  color: #000;
  font-size: 14px;
}

.bottombuttonsboxes {
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
  padding-bottom: 5px;
}

.cancelboxes p {
  color: #0078db;
}
.cancelboxes {
  width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.seeallpropertiesboxes button {
  background-color: rgb(0, 120, 219);
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 2px;
}
.seeallpropertiesboxes {
  width: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}
