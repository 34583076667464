.user-profile .widget-content-area {
  border-radius: 6px;
}
.widget-content-area {
  border: 1px solid #e0e6ed;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
}
.widget-content-area {
  padding: 20px;
  position: relative;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #e0e6ed;
  border-top: none;
}
.user-profile .widget-content-area .user-info {
  margin-top: 20px;
}
.user-profile .widget-content-area .user-info p {
  font-size: 20px;
  font-weight: 600;
  margin-top: 22px;
  color: #009688;
  text-align: center;
}
.user-profile .widget-content-area .edit-profile {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: #4361ee;
  background: linear-gradient(to right, #3cba92 0%, #0ba360 100%);
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

.user-profile .widget-content-area .edit-profile svg {
  font-size: 17px;
  vertical-align: middle;
  margin-right: 0;
  color: #e0e6ed;
  width: 19px;
  align-self: center;
}

.btn svg {
  pointer-events: none;
  height: 22px;
  width: 22px;
  vertical-align: middle;
}
.btn.btn-icon.btn-rounded {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
}
.btn.btn-icon {
  padding: 7.5px 9px;
}
.user-profile .widget-content-area .user-info img {
  border-radius: 9px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  height: 30%;
  width: 30%;
}
.user-profile .widget-content-area .user-info-list > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.user-profile .widget-content-area .user-info-list ul.contacts-block {
  border: none;
  max-width: 217px;
  /* margin: 30px 0 0 0; */
}

.user-profile .widget-content-area .user-info-list ul.contacts-block li {
  margin-bottom: 13px;
  font-weight: 600;
  font-size: 13px;
}

.user-profile
  .widget-content-area
  .user-info-list
  ul.contacts-block
  li
  a:not(.btn) {
  font-weight: 600;
  font-size: 15px;
  color: #009688;
}
