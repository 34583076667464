* {
  margin: 0;
  padding: 0;
}

.swippercss {
  height: 600px;
  width: "100%";
  background: #000 !important;
}
.swippercss_slide {
  background: #000 !important;
}

.swippercss .swiper-button-next,
.swippercss .swiper-button-prev {
  color: white;
}
.ImageProperties {
  height: 550px !important;
  width: 850px !important;
}
.ImageProperties2 {
  height: 50px !important;
  width: 90px !important;
  border: 3px solid;
  filter: brightness(1);
}
.ImageProperties2:hover {
  border: 3px solid #34777d;
  opacity: 1;
  filter: brightness(1.3);
}
.smallswiper {
  height: 100%;
}
.mySwiper1 {
  height: 30%;
  background-color: #000;
}
