#radios .labelforradio {
  cursor: pointer;
}

.colour {
  color: gray;
  font-weight: "700";
  display: flex;
  justify-content: center;
  align-items: center;
}
.colour span {
  padding: 3px;
}
.colourAdd {
  color: #000;
  font-weight: "700";
  display: flex;
  justify-content: center;
  align-items: center;
}
.colourAdd span {
  padding: 3px;
}

.custom_toast{
  margin-top: 70px !important;
}

.main-header {
  z-index: 99999 !important;
}
.editlocIcon {
  font-size: 15px;
  color: #000;
}

.filterdisplayflex {
  display: none;
}
.mobilewhatsapp {
  display: none;
}
.walcon {
  width: 100%;
  margin-top: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.whatsappcontainer1 {
  position: fixed;
  bottom: 100px;
  right: 0px;
  z-index: 99999;
  margin-bottom: 20px;
}
.whatsappcontainer {
  position: absolute;
  right: 30px;
  display: block;
  justify-content: center;
  align-items: center;
  height: 400px;
}
.homewhatsapp {
  position: fixed;
  top: 80px;
  right: 0px;
  z-index: 99999;
  margin-bottom: 20px;
}
.homewhatsapp1 {
  position: absolute;
  right: 10px;
  display: block;
  justify-content: center;
  align-items: center;
}
.imgshare {
  height: 50px;
  width: 50px;
}

/* --------------Nishitha Code------------- */
.containerofinpagesearchbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.innercontainerofinpagesearchbox {
  width: 65%;
  z-index: 9;
  position: absolute;
  top: 17.5rem;
  height: 7rem;
  border-radius: 15px 15px 41px 41px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  box-shadow: rgba(136, 133, 133, 0.24) 0px 3px 8px;
}
.sub_container_of_inpage {
  height: 40%;
  width: 100%;
}
.tab__tabsContent {
  width: 100%;
  background-color: white !important;
  border-radius: 41px 41px 41px 41px !important;
}
.inPageSearchBox__searchTabWrap {
  background-color: white !important;
  border-radius: 41px 41px 41px 41px !important;
}
.subtwo_container_of_inpage {
  height: 60%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  border-radius: 41px 41px 41px 41px;
}
.sub_inner_container {
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 35px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  justify-content: space-evenly;
  gap: 0px;
}
.sub_two_container_above_form {
  padding: 0.5rem;
  padding-left: 1rem;
}
.subcontainerrrr {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}
.subconat {
  display: flex;
  align-items: center;
  width: 30%;
  border-right: 1px solid gainsboro;
}
#innerdemo_sub {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.hr_content {
  /* margin-top:-8px; */
  transition: all 2s;
}
.subconat .input1 {
  border: none;
  width: 70%;
  color: black !important ;
  background-color: white !important;
  display: flex;
}

.container_input {
  width: 75%;
  font-size: 1rem;
  height: 100%;
}
.container_input input {
  width: 75%;
  height: 100%;
  border: none;
  background-color: white !important;
  font-size: 0.9rem;
}
#buttonsearchsub {
  background-color: #36c991;
  width: 7rem;
  margin-right: 1rem;
  height: 3rem;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  border-radius: 3rem;
}
#searchbtn {
  background-color: #36c991;
  color: #ffffff;
  border: 1px solid #36c991;
  border-radius: 20px !important;
}
/* ----------------------- */
.topmargins {
  margin-top: 12vh;
}
.menubar {
  position: fixed;
  top: 70px;
  /* left: 0; */
  width: 250px;
  bottom: 0;
  border-right: 1px solid #eee;
  background: #fff;
  z-index: 3;
  overflow: hidden;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.07);
  right: 0 !important;
  z-index: 9999 !important;
}

.strong_medium {
  font-size: 10px;
  line-height: 16px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: center;
}

.menuseachinput {
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: 2px;
  font-weight: 400;
  box-shadow: none;
  background-color: #f4f5f7;
  border: 1px solid #fff;
  padding: 25px;
}

.menusearch {
  display: flex;
  justify-content: center;
  height: 45px;
  position: relative;
  background-color: #f4f5f7;
  margin-bottom: 10px;
}

.menusearchicon {
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  position: absolute;
  right: 0;
  padding-right: 5px;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;
  color: #42526f;
  padding-top: 6px;
}

.postSuccess-main {
  margin: 0px 220px;
  /* background-color: #ddd; */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
}
.postSuccess-heading-title em {
  font-size: 22px;
}
.postSuccess-heading-title {
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.property-expiry {
  display: flex;
  align-items: center;
  gap: 50px;
  font-size: 13px;
}
.property-expiry div span {
  color: #bbb;
}
.property-advertise {
  margin: 20px 0px 30px 0px;
}
.property-advertise p {
  font-size: 16px;
  color: #000;
}
.facebook-logo {
  height: 40px;
  width: 120px;
}
.brand-promotion {
  background-color: rgba(250, 182, 193, 0.6);
  padding: 10px;
  border-radius: 15px;
  margin-top: 50px;
  width: 100%;
}
.brand-promotion h3 {
  font-weight: 600;
}
.brand-promotion-text em {
  font-weight: 600;
  color: #000;
  margin-right: 5px;
}
.brand-promotion-leftpanel {
  text-align: left;
  margin-right: 120px;
}
.brand-promotion-btn {
  background-color: #000;
  padding: 6px 12px;
  color: #fff;
  text-align: center;
  margin-top: 50px;
}
.brand-promotion-text div {
  margin: 12px 0px;
  color: #171717;
}
.postSuccess-footer {
  background-color: #eee;
}
.postSuccess-footer-heading {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 20px;
}
.postSuccess-footer-heading h2 {
  font-size: 32px;
  font-weight: 600;
  color: #aaa;
}
.postSuccess-footer-end {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 420px;
  padding: 20px;
}
.postSuccess-footer-end .strong-text {
  font-weight: 600;
  color: #000;
}
.postProperty-Review {
  margin: 50px 200px;
  display: flex;
  flex-direction: column;
  text-align: left;
}
.postProperty-Review-section h1 {
  font-size: 25px;
  text-align: left;
  font-weight: 600;
}
.postProperty-Review-ratings {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 20px 0px;
}
.postProperty-Review button {
  background-color: #0078db;
  color: #fff;
  border: none;
  outline: none;
  padding: 10px 20px;
  margin-top: 15px;
  width: 180px;
  border-radius: 8px;
}
.brand-promotion-content {
  display: flex;
  align-items: center;
  gap: 60px;
}
.propertyScore {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; */
  /* height: 80vh; */
}
.propertyScore-footer {
  background-color: #f7faef;
}
.content-space {
  /* width: 75vw; */
  padding: 20px 120px;
}
.propertyScore-main-title h3 {
  font-size: 28px;
  color: #555;
}
.propertyScore-main-title p {
  font-size: 14px;
  color: #777;
  text-align: center;
}
.propertyScore-main-cntborder {
  border: 1px solid #ddd;
  width: 250px;
  margin: auto;
  padding: 20px;
  color: #bbb;
}
.key-icon {
  margin: 10px 0px;
}
.propertyScore-main-cntborder-top div {
  font-size: 14px;
}
.propertyScore-main-cntborder-top div {
  margin: 10px 0px;
}
.propertyScore-main-cntborder-bottom {
  margin-top: 45px;
}
.propertyScore-main-cntborder-bottom .percentage {
  font-size: 22px;
}
.add-details {
  border: 1px solid #ddd;
  padding: 3px 10px;
  margin: 10px 0px;
}
.propertyScore-footer h3 {
  font-size: 28px;
  color: #555;
}
.searchcon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
  padding: 0px !important;
  width: 100% !important;
}

.wallet_container1 .wallet_mainhead {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  text-align: left;
  margin: 20px;
}

.walsubcon {
  width: 45%;
  margin: 2.5%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  padding: 2%;
}

.walsubcon1 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2.5%;
}

.walsubcon2 input {
  width: 80%;
  height: 5vh;
  margin: 1.8%;
}

.wallet_buy_button input {
  margin: 1.6%;
}

.wallet_buy_button {
  display: flex;
  justify-content: end;
  padding-right: 8.5%;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*========================= search page start ======================= */
.mainCOntainer {
  position: relative;
  height: 62vh;
}
.serchsugist {
  display: block;
  position: absolute;
  z-index: 9999;
  /* border: 1px solid #3270fc; */
  background-color: #fff;
  width: 100%;
  padding: 0.5%;
  border-radius: 4px;
}
.serchsugist h6 {
  color: #757b8d;
  font-weight: 400;
}

/* ===========================search page end ======================= */

#radios .labelforradio + .labelforradio {
  margin-left: 15px;
}

.labelInput[type="radio"] {
  opacity: 0;
  /* position: absolute; */
}

.labelInput[type="radio"] + span {
  font-family: "Material Icons";
  color: #7d93b2;
  border-radius: 10px;
  padding: 7px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.labelInput[type="radio"]:checked + span {
  color: #d9e7fd;
  background-color: #4285f4;
}

.labelInput[type="radio"]:focus + span {
  color: #fff;
}

.labelInput[type="checkbox"] {
  opacity: 0;
  /* position: absolute; */
}

.labelInput[type="checkbox"] + span {
  font-family: "Material Icons";
  color: #7d93b2;
  border-radius: 10px;
  padding: 7px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
}

.labelInput[type="checkbox"]:checked + span {
  color: #d9e7fd;
  background-color: #4285f4;
}

.labelInput[type="checkbox"]:focus + span {
  color: #fff;
}

.bedroomsComplete {
  color: #fff;
  background: #3270fc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.addOtherBed {
  color: #3270fc;
  border-radius: 4px;
}

.ReservedParkingLabel {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.CoverParkingCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.CoverParkingCounter .coverminus {
  border: 1px solid #ced4dd;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CoverParkingCounter .coverminus1 {
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: "600";
}

/* for date css  */
/* src/components/BeautifulDateInput.css */
.beautiful-date-input {
  appearance: none;
  outline: none;
  border: 2px solid #ccc;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.beautiful-date-input::-webkit-calendar-picker-indicator {
  filter: invert(0.6);
}

.beautiful-date-input:focus {
  border-color: #007bff;
}

/* new styles  */
.custom-form input[type="date"] {
  float: left;
  border: 1px solid #e5e7f2;
  background: #f5f7fb;
  width: 100%;
  padding: 15px 20px 15px 70px;
  border-radius: 4px;
  color: #7d93b2;
  font-size: 12px;
  -webkit-appearance: none;
  outline: none;
  overflow: hidden;
  z-index: 1;
  font-weight: 400;
}

.errorOtp {
  color: red;
  font-size: 11px;
  padding-right: 200px;
  margin-bottom: 10px;
}

/* new  */
.log-out-btn {
  color: #3270fc;
  font-size: 13px;
}

.large-select {
  font-size: 15px;
}

.coloringRoot {
  color: green !important;
}

/* ================= Recommended Properties ======================== */

.recom-property {
  margin-bottom: 30px;
}

.recom-property h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
  margin-left: 48px;
}

.recom-property p {
  color: #878c9f;
  margin-top: 5px;
  margin-left: 48px;
}

.recommended-swiper {
  margin-top: 28px;
  margin-left: 48px;
  margin-right: 48px;
}

.geodir-category-footer {
  display: flex;
  justify-content: space-between;
}

.geodir-category-footer a {
  color: #878c9f;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding: 15px;
}

.listing-rating {
  margin-bottom: 10px;
}

.listing-rating .re_stars-title {
  position: absolute;
  left: -10px;
}

.listing-rating .price {
  font-weight: bold;
  background-color: #fff !important;
  color: black !important;
  padding: 5px 10px 5px 10px !important;
  border-radius: 5px !important;
}

.geodir-category-content .agent_card-title h4 {
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.geodir-category-content .agent_card-title h5 {
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* ================= Recommended Properties End ======================== */

/* ================= Insights & Tools Properties ======================== */

.insight-tools {
  background: #f5f7fb;
  padding-top: 10px;
  margin-top: 48px;
}

.insight-tools h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
  margin-left: 48px;
}

.insight-tools p {
  color: #878c9f;
  margin-top: 5px;
  margin-left: 48px;
}

.insight-swiper {
  margin-top: 28px;
  margin-left: 48px;
  margin-right: 48px;
}

.swiper-card {
  justify-content: center;
  align-content: center;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* transition: 0.3s; */
  width: 100%;
  /* height: 100% !important; */
  border-radius: 20px;
  margin: 20px;
  padding: 10px;
}

.swiper-card .swiper-card-img {
  text-align: center;
  width: 40%;
  margin: 10px;
  padding: 10px;
  border-radius: 50%;
  background-color: rgb(240, 249, 255);
  margin-left: 100px;
  max-height: 300px;
}

.swiper-card .swiper-card-img img {
  /* display: flex;
  justify-content: center;
  text-align: center; */
  width: 100px;
  padding: 5px;
  height: 100px;
}

.swiper-card .swiper-card-text {
  height: 100px;
  margin: 8px;
}

.swiper-card .swiper-card-text h2 {
  font-weight: bold;
  margin-top: 5px;
}

.swiper-card .swiper-card-text p {
  /* height: 50px; */
  margin-left: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* ================= Insights & Tools Properties End ======================== */

/* ================= Popular Builders ======================== */
.popular-builders {
  padding-top: 10px;
  margin-top: 48px;
}

.builders-head h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
  margin-left: 48px;
}

.builders-head p {
  color: #878c9f;
  margin-top: 5px;
  margin-left: 48px;
}

.builders-swiper {
  margin-left: 48px;
  margin-right: 48px;
  margin-top: 28px;
}

.popular-swiper-card {
  width: 100%;
  display: flex;
  border: 1px solid rgba(128, 128, 128, 0.389);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
}

.popular-swiper-card .popular-swiper-card-img {
  border-radius: 50%;
  border: 1px solid gray;
  padding: 10px;
}

.popular-swiper-card .popular-swiper-card-img img {
  width: 100px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 100px;
}

.popular-swiper-card .popular-swiper-card-text {
  margin-left: 30px;
  margin-top: 30px;
  text-align: left;
}

.popular-swiper-card .popular-swiper-card-text h2 {
  max-height: 50px;
  font-size: 15px;
  font-weight: 600;
}

.popular-swiper-card .popular-swiper-card-text p {
  height: 20px;
  /* font-size: 18px; */
}

/* ================= Popular Builders End ======================== */

/* ================= BHK ChoiceInMinf Start ======================== */

.liclas {
  text-align: left;

  padding: 12px 20px;
  border: 1px solid #f7f7f7;
  cursor: pointer;
  border-bottom: 1px solid #ebecf0;
}

.bhk-choice {
  background: #f5f7fb;
  padding-top: 10px;
  margin-top: 48px;
}

.bhk-choice h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
  margin-left: 48px;
}

.bhk-choice p {
  color: #878c9f;
  margin-top: 5px;
  margin-left: 48px;
}

.bhk-swiper {
  margin-top: 28px;
  margin-left: 48px;
  margin-right: 48px;
}

.bhk-swiper-card {
  justify-content: center;
  align-content: center;
  /* text-align: center; */
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  height: 100% !important;
  border-radius: 20px;
  margin: 20px;
  padding: 20px;
}

.bhk-swiper-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.bhk-swiper-card .bhk-swiper-card-icon {
  text-align: center;
  font-size: 50px;
  width: 40%;
  margin: 10px;
  margin-left: 70px;
}

.bhk-swiper-card .bhk-swiper-card-img img {
  width: 100px;
  padding: 5px;
}

.bhk-swiper-card .bhk-swiper-card-text {
  text-align: center;
  width: 40%;
  margin: 10px;
  height: 30px;
  margin-left: 70px;
}

.bhk-swiper-card .bhk-swiper-card-text h2 {
  text-align: center;
  max-height: 50px;
  font-weight: bold;
  margin-top: 5px;
}

.bhk-swiper-card .bhk-swiper-card-text p {
  height: 20px;
  text-align: center;
  margin-left: 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* ================= BHK ChoiceInMinf End ======================== */

/* ================= serch page start  ======================== */
.mainsearchcont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f5f7;
}
.mainsearchcont .searchcont_box {
  width: 95%;
  display: flex;
  padding: 1%;
}
.searchcont_box .searchcont_leftbox {
  width: 25%;
  padding: 1%;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  max-height: 1900px;
}
.searchcont_leftbox_fixed {
  position: fixed;
  top: auto;
  bottom: 10px;
  z-index: 2;
  width: 23%;
  max-height: 85vh;
  overflow: auto;
  background-color: #ffffff;
  border-radius: 10px;
}
.filters {
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.side-panel {
  margin: 8px;
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.font-style {
  font-size: 16px;
  font-weight: 600;
  color: #555;
}
.range-bar {
  position: relative;
}
.slider-box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slider-box div {
  background-color: #091e42;
  padding: 5px;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
}
.input-box {
  margin-top: 35px;
}
.heading {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
}
.input-box-cont {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /* flex-direction: column; */
}
.near-location {
  margin-top: 15px;
  text-align: left;
}
.budget-filter {
  border: 1px solid #ddd;
  padding: 15px;
  color: #555;
  font-size: 13px;
}
.searchcont_box .searchcont_rightbox {
  width: 75%;
  padding: 1%;
  display: flex;
  flex-direction: column;
}

.searchcont_rightbox h2 {
  text-align: left;
}
.searchcont_rightbox .search_propertybox {
  width: 100%;
  /* height: 52vh; */
  margin-top: 1vh;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.searchcont_rightbox .search_propertybox:hover {
  box-shadow: rgb(187, 219, 240) 0px 15px 25px, rgb(174, 213, 239) 0px 5px 10px;
  cursor: pointer;
}
.search_propertybox .search_propertyboxTop {
  width: 100%;
  display: flex;
}

.search_propertyboxTop .search_pro_imageBox {
  width: 30%;
  height: 40vh;
  display: flex;
  padding: 0 10px 10px 0;
}
.search_pro_imageBox img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.search_propertyboxTop .search_pro_Detail_box {
  width: 70%;
}
.search_pro_Detail_box .Detail_box_name {
  display: flex;
  width: 100%;
  height: 4vh;
}
.Detail_box_name .Detail_box_namebox {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.Detail_box_namebox h4 {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  color: #000;
}

.Detail_box_name .Detail_box_name_icons {
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins";
  color: #000;
  width: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Detail_box_name_icons .icon_name {
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins";
  color: #000;
  margin: 0 2%;
}

.search_pro_Detail_box .name_appartment {
  width: 100%;
  height: 2.5vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.search_pro_Detail_box .price_squre_box {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1vh;
}
.price_squre_box .squre_box_one {
  width: 20%;
  height: 100%;
  display: flex;
}
.squre_box_one .squre_box_main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.price_squre_box .squre_box_two {
  width: 30%;
  height: 100%;
  display: flex;
}
.squre_box_main .squre_box_top {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.squre_box_top h1 {
  font-size: 21px;
  font-weight: 600;
  font-family: "Poppins";
  color: #000;
}
.squre_box_top h1 span {
  font-size: 13px;
  font-weight: 400;
  font-family: "Poppins";
  color: #000;
}
.squre_box_main .squre_box_bottom {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.squre_box_bottom h4 {
  font-size: 10px;
  font-weight: 400;
  color: #000;
}
.price_squre_box .squre_box_three {
  width: 20%;
  height: 100%;
  display: flex;
}

.search_pro_Detail_box .nearby_box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f0f9ff;
  padding: 1vh;
  flex-wrap: wrap;
}

.nearby_box .nearby_boxone {
  height: 4vh;
  display: flex;
  justify-content: flex-start;
  justify-content: center;
  text-align: center;
  font-size: 13px;
  margin: 0 1vh;
  color: #42526e;
  font-weight: 500;
}

.search_pro_Detail_box .proprty_detail_blog {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.proprty_detail_blog h6 {
  width: 90%;
  font-size: 14px;
  color: #091e42;
  font-weight: 500;
  text-align: justify;
}
.proprty_detail_blog h6 span {
  color: #0078db;
}

.search_pro_Detail_box .property_sale_detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 1vh 0;
}
.property_sale_detail .sale_detail_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5vh;
  background: #ebecf0;
  color: #42526e;
  margin: 0 1vh;
  padding: 0 1vh;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 11px;
}

.search_propertybox .search_propertybox_Bottom {
  width: 100%;
  height: 10vh;
  display: flex;
  padding: 0.2vh;
}
.search_propertybox_Bottom .propertybox_Bottom_left {
  width: 50%;
  height: 100%;
}

.propertybox_Bottom_left .squre_box_main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.squre_box_main .Bottom_left_bottom {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Bottom_left_bottom h1 {
  font-size: 21px;
  font-weight: 600;
  font-family: "Poppins";
  color: #000;
}

.squre_box_main .Bottom_left_top {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
}

.Bottom_left_top h4 {
  font-size: 10px;
  font-weight: 400;
  color: #000;
}
.search_propertybox_Bottom .propertybox_Bottom_right {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.propertybox_Bottom_right .contact_button {
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  background-color: #0076d6;
  color: #fff;
  border: 1px solid #0076d6;
  border-radius: 7px;
  margin: 5px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins";
}
.propertybox_Bottom_right .vianumber_button {
  height: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 20px;
  background-color: #fff;
  color: #0076d6;
  border: 1px solid #fff;
  border-radius: 7px;
  margin: 5px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins";
}
.propertybox_Bottom_right .vianumber_button:hover {
  background-color: #f0f9ff;
  border: 1px solid #f0f9ff;
}

.serch_property_btn2 {
  align-items: center;
  padding: 7px 25px;
  background-color: #0076d6;
  color: #fff;
  border: 1px solid #0076d6;
  border-radius: 10px;
  margin: 5px;
  font-size: 15px;
  font-weight: 600;
  font-family: "Poppins";
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.m-10 {
  margin: 18px;
}
.verified-property {
  width: 65px;
}
.flex-start {
  display: flex;
  /* justify-content: ; */
  align-items: center;
  margin: 8px;
}
.option-bg-color {
  background-color: #fff;
  width: 120px;
  border: 1px solid #eee;
  color: #555;
  padding: 4px;
  margin: 2px;
  border-radius: 20px;
}
.search_propertybox_cnt .price-quote div.text-bold {
  font-weight: 600;
  color: #000;
  font-size: 15px;
}

/* ================= serch page  End ======================== */

/* ================= ApartmentsVillas Start ======================== */

.apartment-villa {
  padding-top: 10px;
  margin-top: 48px;
}

.apartment-villa-swiper {
  margin-top: 28px;
  margin-left: 48px;
  margin-right: 48px;
}

.apartment-villa .apartment-head h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
  margin-left: 48px;
}

.apartment-villa .apartment-head p {
  color: #878c9f;
  margin-top: 5px;
  margin-left: 48px;
}

.apartment-villa-img1 img {
  background-color: #ffdede;
  border-radius: 15px;
}

.apartment-villa-img2 img {
  background-color: rgb(240, 249, 255);
  border-radius: 15px;
}

.apartment-villa-img3 img {
  background-color: #b3e4ada6;
  border-radius: 15px;
}

.apartment-villa-img4 img {
  background-color: #ffdede;
  border-radius: 15px;
}

.apartment-villa-img5 img {
  background-color: rgb(240, 249, 255);
  border-radius: 15px;
}

.apartment-villa-img6 img {
  background-color: #b3e4ada6;
  border-radius: 15px;
}

.apartment-villa-img7 img {
  background-color: #ffdede;
  border-radius: 15px;
}

.apartment-villas-item {
  height: 400px;
}

.apartment-villas-item .apartment-villas-content {
  position: absolute;
  text-align: left;
  left: 0;
  right: 0;
  padding-left: 20px;
  bottom: 280px;
  z-index: 10;
}

.apartment-villas-item .apartment-villas-content h3 a {
  color: #42526e;
  text-align: left;
  font-weight: 700;
  font-size: 20px;
}

.apartment-villas-item .apartment-villas-content p {
  color: #878c9f;
  text-align: left;
}

/* ================= ApartmentsVillas End ======================== */

/* ================= MoveInNow Start ======================== */

.movein-now {
  padding-top: 10px;
  margin-top: 48px;
}

.movein-now-swiper {
  margin-top: 28px;
  margin-left: 48px;
  margin-right: 48px;
}

.movein-now .movein-head h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
  margin-left: 48px;
}

.movein-now .movein-head p {
  color: #878c9f;
  margin-top: 5px;
  margin-left: 48px;
}

.movein-now-img1 img {
  width: 100%;
  padding-top: 130px;
  background-color: #ffdede;
  border-radius: 15px;
}

.movein-now-img2 img {
  width: 100%;
  padding-top: 130px;
  background-color: rgb(240, 249, 255);
  border-radius: 15px;
}

.movein-now-img3 img {
  width: 100%;
  padding-top: 130px;
  background-color: #b3e4ada6;
  border-radius: 15px;
}

.movein-now-img4 img {
  width: 100%;
  padding-top: 130px;
  background-color: #ffdede;
  border-radius: 15px;
}

.movein-now-img5 img {
  width: 100%;
  padding-top: 130px;
  background-color: rgb(240, 249, 255);
  border-radius: 15px;
}

.movein-now-img6 img {
  width: 100%;
  padding-top: 130px;
  background-color: #b3e4ada6;
  border-radius: 15px;
}

.movein-now-img7 img {
  width: 100%;
  padding-top: 130px;
  background-color: #ffdede;
  border-radius: 15px;
}

.movein-now-item {
  height: 350px;
}

.movein-now-item .movein-now-content {
  position: absolute;
  text-align: left;
  left: 0;
  right: 0;
  padding-left: 20px;
  bottom: 230px;
  z-index: 10;
}

.movein-now-item .movein-now-content h3 a {
  color: #42526e;
  text-align: left;
  font-weight: 700;
  font-size: 20px;
}

.movein-now-item .movein-now-content p {
  color: #878c9f;
  text-align: left;
}

/* ================= MovieInNow End ======================== */

/* ================= CheckProperty Start ======================== */

.check-property {
  padding-top: 10px;
  margin-top: 48px;
}

.property-head h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
  margin-left: 48px;
}

.property-head p {
  color: #878c9f;
  margin-top: 5px;
  margin-left: 48px;
}

.property-swiper {
  background-color: #f4f5f7;
  margin-top: 13px;
  margin-left: 48px;
  margin-right: 48px;
  border-radius: 10px;
}

.check-property .property-top-nav {
  display: flex;
  color: #253858;
  margin-top: 30px;
  margin-left: 48px;
}

.check-property .property-top-nav-item {
  margin-right: 15px;
}

.check-property .active {
  border-bottom: 2px solid #007bff;
}

.property-swiper-card {
  justify-content: center;
  align-content: center;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 350px;
  height: 350px;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
}

.property-swiper-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.linebar-head {
  display: flex;
  justify-content: space-between;
  color: #091e42;
  font-weight: 600;
  font-size: 15px;
  margin-top: 20px;
}

.linebar-head p {
  display: flex;
  justify-content: space-between;
  color: #878c9f;
  font-weight: 400;
  font-size: 15px;
}

.property-swiper-card-text-top-linebar h2 {
  border-bottom-style: 3px dashed #878c9f;
}

.property-swiper-card .property-swiper-card-chart img {
  text-align: center;
  width: 60%;
  padding: 30px;
}

.property-swiper-card .property-swiper-card-text-top {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.property-swiper-card-text-top .property-swiper-card-text-top-in h2 {
  color: #091e42;
  font-weight: 600;
  font-size: 20px;
}

.property-swiper-card
  .property-swiper-card-text-top
  .property-swiper-card-text-top-icon {
  font-weight: bold;
  color: #878c9f;
  font-size: 25px;
}

.property-swiper-card .property-swiper-card-text-bottom {
  text-align: left;
}

.property-swiper-card .property-bottom-text-blue a h2 {
  color: #2664f4;
  margin-top: 60px;
}

.property-swiper-card-text-bottom .property-bottom-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

/* ================= CheckProperty End ======================== */

/* ================= ExploreOurServices Start ======================== */

.explore-container {
  background: #f5f7fb;
  height: 80vh;
}

.explore-container .explore-head {
  text-align: left;
  padding-top: 10px;
  margin-top: 48px;
  margin-left: 48px;
}

.explore-container .explore-head p {
  color: #878c9f;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}

.explore-container .explore-head h1 {
  color: #091e42;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
}

.explore-container .explore-card {
  width: 90%;
  height: 40vh;
  /* background-color: #fff; */
  margin-top: 48px;
  /* margin-left: 60px; */
  display: flex;
  border-radius: 10px;
}

.explore-card .row {
  width: 100%;
  height: 60px;
  margin-top: 50px;
  margin-left: 30px;
}

.explore-card .row .col-inner {
  justify-content: center;
  align-items: center;
}

.explore-card .row .col-inner .col-text {
  text-align: left;
  font-size: 10px;
  width: 250px;
  height: 100px;
  margin-left: 10px;
}

.explore-card .row .col-inner .col-text h2 {
  color: #091e42;
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}

.explore-card .row .col-inner .col-text p {
  color: #878c9f;
  font-size: 12px;
  text-align: left;
  line-height: 18px;
  margin-top: 5px;
  margin-bottom: 3px;
  height: 40px;
  width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.explore-card .row .col-inner .col-text hr {
  width: 150px;
  color: #878c9f;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.explore-card .row .col-inner .col-img {
  width: 60px;
  height: 60px;
}

.explore-card .row .col-inner .col-img img {
  width: 100%;
  height: 100%;
}

.col-inner .inner-box-1 {
  width: 300px;
  height: 100px;
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 10px;
  padding: 10px;
  border-radius: 6px;
  background-color: #fff;
}

.classforheadfon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.classforheadfon .headfonIcon {
  font-size: 16px;
  margin-right: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.classforheadfon .headfonCall {
}

.classforheadfon .headfonCall .tollfree {
  font-size: 12px;
  padding: 0px !important;
}

.classforheadfon .headfonCall .tollfreeNumber {
  color: #253858;
}

.classforheadfon1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 6px;
  border: 1.5px solid blue;
  border-color: blue;
  border-radius: 5px;
}

.classforheadfon1 .requestcallIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.classforheadfon1 .requestcallIcon .requestIconCo {
  color: blue;
  font-size: 18px;
}

.classforheadfon1 .requestcall {
  color: blue;
  font-size: 14px;
}
@media screen and (max-width: 1510px) {
  .postSuccess-main,
  .postSuccess-footer-end {
    margin: 0px 120px;
  }
}

@media screen and (max-width: 520px) and (min-width: 420px) {
  .mobilewhatsapp {
    display: block;
  }
  .desktopwhatsapp {
    display: none;
  }
  .walcon {
    display: flex;
    flex-direction: column !important;
  }

  .walsubcon {
    width: 100%;
  }

  .liclas {
    padding: 4px 6px;
    font-size: 12px;
  }

  .serchsugist {
    top: 70px;
  }

  .explore-container {
    background: #f5f7fb;
    height: 120vh;
  }

  .col-inner .inner-box-1 {
    width: 400px;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
  }

  .explore-container .explore-card {
    width: 70%;
    height: 85vh;
    background-color: #fff;
    margin-top: 48px;
    display: block;
    padding-top: 20px;
    border-radius: 10px;
  }

  .explore-card .row {
    width: 100%;
    height: 200px;
    margin-top: 10px;
    margin-left: 30px;
  }
  .postSuccess-main,
  .postSuccess-footer-end {
    margin: 0px 5px;
  }
  .postSuccess-main {
    padding: 15px 0px 0px 0px;
  }
  .postSuccess-heading-title em {
    font-size: 18px;
  }
  .postSuccess-heading-title {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .property-expiry {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 13px;
  }
  .property-expiry div span {
    color: #bbb;
  }
  .property-advertise {
    margin: 8px 0px 10px 0px;
  }
  .property-advertise p {
    font-size: 16px;
    color: #000;
  }
  .brand-promotion {
    background-color: rgba(250, 182, 193, 0.6);
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
    width: 100%;
  }
  .facebook-logo {
    width: 80px;
  }
  .brand-promotion h3 {
    font-weight: 600;
  }
  .brand-promotion-text em {
    font-weight: 600;
    color: #000;
    margin-right: 5px;
  }
  .brand-promotion-leftpanel {
    text-align: left;
    margin-right: 30px;
  }
  .brand-promotion-btn {
    background-color: #000;
    padding: 6px 6px;
    color: #fff;
    text-align: center;
    margin-top: 50px;
    font-size: 14px;
  }
  .postSuccess-footer-heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
  .postSuccess-footer-heading h2 {
    font-size: 22px;
  }
  .postSuccess-footer-end .strong-text {
    font-weight: 600;
    color: #000;
  }
  .content-space {
    /* width: 75vw; */
    padding: 10px 20px;
  }
  .propertyScore-main-title h3 {
    font-size: 18px;
    color: #555;
  }
  .propertyScore-main-title p {
    font-size: 13px;
    color: #777;
    text-align: center;
  }
  .propertyScore-main-cntborder {
    border: 1px solid #ddd;
    width: 200px;
    margin: auto;
    padding: 20px;
    color: #bbb;
  }
  .propertyScore-main-cntborder-bottom {
    margin-top: 5px;
  }
  .propertyScore-main-cntborder-bottom .percentage {
    font-size: 18px;
  }
  .add-details {
    border: 1px solid #ddd;
    padding: 3px 7px;
    margin: 10px 0px;
  }
  .propertyScore-footer h3 {
    font-size: 18px;
    color: #555;
  }
}

@media screen and (max-width: 720px) and (min-width: 520px) {
  .walcon {
    display: flex;
    flex-direction: column !important;
  }

  .walsubcon {
    width: 100%;
  }

  .explore-container {
    background: #f5f7fb;
    height: 120vh;
  }

  .col-inner .inner-box-1 {
    width: 400px;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
  }

  .explore-container .explore-card {
    width: 80%;
    height: 85vh;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 48px;
    display: block;
    padding-top: 20px;
  }

  .explore-card .row {
    width: 100%;
    height: 200px;
    margin-top: 10px;
    margin-left: 30px;
  }
  .postSuccess-main,
  .postSuccess-footer-end {
    margin: 0px 30px;
  }
  .postSuccess-heading-title em {
    font-size: 18px;
  }
  .postSuccess-heading-title {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .property-expiry {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 13px;
  }
  .property-expiry div span {
    color: #bbb;
  }
  .property-advertise {
    margin: 8px 0px 10px 0px;
  }
  .property-advertise p {
    font-size: 16px;
    color: #000;
  }
  .brand-promotion {
    background-color: rgba(250, 182, 193, 0.6);
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
    width: 100%;
  }
  .facebook-logo {
    width: 80px;
  }
  .brand-promotion h3 {
    font-weight: 600;
  }
  .brand-promotion-text em {
    font-weight: 600;
    color: #000;
    margin-right: 5px;
  }
  .brand-promotion-leftpanel {
    text-align: left;
    margin-right: 30px;
  }
  .brand-promotion-btn {
    background-color: #000;
    padding: 6px 6px;
    color: #fff;
    text-align: center;
    margin-top: 50px;
    font-size: 14px;
  }
  .postSuccess-footer-heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
  .postSuccess-footer-heading h2 {
    font-size: 22px;
  }
  .postSuccess-footer-end .strong-text {
    font-weight: 600;
    color: #000;
  }
  .propertyScore-footer {
    background-color: #f7faef;
  }
  .content-space {
    /* width: 75vw; */
    padding: 20px 80px;
  }
  .propertyScore-main-title h3 {
    font-size: 22px;
    color: #555;
  }
  .propertyScore-main-title p {
    font-size: 13px;
    color: #777;
    text-align: center;
  }
  .propertyScore-main-cntborder {
    border: 1px solid #ddd;
    width: 240px;
    margin: auto;
    padding: 20px;
    color: #bbb;
  }
  .propertyScore-main-cntborder-bottom {
    margin-top: 25px;
  }
  .propertyScore-main-cntborder-bottom .percentage {
    font-size: 22px;
  }
  .add-details {
    border: 1px solid #ddd;
    padding: 3px 10px;
    margin: 10px 0px;
  }
  .propertyScore-footer h3 {
    font-size: 22px;
    color: #555;
  }
}

@media screen and (max-width: 960px) and (min-width: 720px) {
  .walcon {
    display: flex;
    flex-direction: column !important;
  }

  .walsubcon {
    width: 100%;
  }

  .explore-container {
    background: #f5f7fb;
    height: 120vh;
  }

  .col-inner .inner-box-1 {
    width: 400px;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
  }

  .explore-container .explore-card {
    width: 80%;
    height: 85vh;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 48px;
    display: block;
    padding-top: 20px;
  }

  .explore-card .row {
    width: 100%;
    height: 100px;
    margin-top: 10px;
    margin-left: 30px;
  }
  .postSuccess-main,
  .postSuccess-footer-end {
    margin: 0px 40px;
  }
  .brand-promotion-leftpanel {
    margin-right: 40px;
  }
}

@media screen and (max-width: 1080px) and (min-width: 960px) {
  .explore-container {
    background: #f5f7fb;
    height: 70vh;
  }

  .col-inner .inner-box-1 {
    width: 250px;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .explore-container .explore-card {
    width: 100%;
    height: 40vh;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 48px;
    display: flex;
    padding-top: 20px;
  }

  .explore-card .row {
    width: 100%;
    height: 200px;
    margin-top: 10px;
    margin-left: 30px;
  }

  .explore-card .row .col-inner {
    justify-content: center;
    align-items: center;
  }

  .explore-card .row .col-inner .col-text {
    text-align: left;
    font-size: 10px;
    width: 100px;
    height: 100px;
    margin-left: 20px;
  }

  .explore-card .row .col-inner .col-text h2 {
    color: #091e42;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
  }

  .explore-card .row .col-inner .col-text p {
    color: #878c9f;
    font-size: 12px;
    text-align: left;
    line-height: 18px;
    margin-top: 5px;
    margin-bottom: 3px;
    height: 40px;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .explore-card .row .col-inner .col-text hr {
    width: 150px;
    color: #878c9f;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .explore-card .row .col-inner .col-img {
    width: 30px;
    height: 30px;
  }

  .explore-card .row .col-inner .col-img img {
    width: 100%;
    height: 100%;
  }
  .mainsearchcont .searchcont_box {
    width: 100%;
    display: flex;
    padding: 1%;
  }
  .searchcont_box .searchcont_leftbox {
    width: 25%;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 14px;
  }
  .font-style {
    font-size: 12px;
    font-weight: 600;
    color: #555;
  }
  .heading {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
  }
  .verified-property {
    width: 45px;
  }
  .input-box-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .near-location {
    margin-top: 10px;
    font-size: 10px;
  }
}
@media screen and (max-width: 420px) and (min-width: 360px) {
  .mobilewhatsapp {
    display: block;
  }
  .desktopwhatsapp {
    display: none;
  }
  .wallet_buy_button input {
    width: 88%;
  }

  .walcon {
    display: flex;
    flex-direction: column !important;
  }

  .walsubcon {
    width: 100%;
  }

  .explore-container {
    background: #f5f7fb;
    height: 120vh;
  }

  .col-inner .inner-box-1 {
    width: 400px;
    height: 100px;
    display: flex;
    flex-wrap: wrap;
  }

  .explore-container .explore-card {
    width: 80%;
    height: 85vh;
    background-color: #fff;
    margin-top: 48px;
    display: block;
    padding-top: 20px;
    border-radius: 10px;
  }

  .explore-card .row {
    width: 100%;
    height: 200px;
    margin-top: 10px;
    margin-left: 30px;
  }

  .liclas {
    padding: 4px 6px;
    font-size: 12px;
  }

  .serchsugist {
    top: 67px;
  }
  .postSuccess-main,
  .postSuccess-footer-end {
    margin: 0px 5px;
  }
  .postSuccess-main {
    padding: 15px 0px 0px 0px;
  }
  .postSuccess-heading-title em {
    font-size: 18px;
  }
  .postSuccess-heading-title {
    text-align: left;
  }
  .postSuccess-heading-title {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .property-expiry {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 13px;
  }
  .property-expiry div span {
    color: #bbb;
  }
  .property-advertise {
    margin: 8px 0px 10px 0px;
  }
  .property-advertise p {
    font-size: 14px;
    color: #000;
  }
  .brand-promotion {
    background-color: rgba(250, 182, 193, 0.6);
    padding: 10px;
    border-radius: 15px;
    margin-top: 10px;
    width: 100%;
  }
  .facebook-logo {
    width: 80px;
  }
  .brand-promotion h3 {
    font-weight: 600;
    font-size: 14px;
  }
  .brand-promotion-text em {
    font-weight: 600;
    color: #000;
    margin-right: 5px;
  }
  .brand-promotion-text {
    font-size: 14px;
  }
  .brand-promotion-leftpanel {
    text-align: left;
    margin-right: 15px;
    font-size: 14px;
  }
  .brand-promotion-btn {
    background-color: #000;
    padding: 6px 6px;
    color: #fff;
    text-align: center;
    margin-top: 50px;
    font-size: 14px;
  }
  .postSuccess-footer-heading {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }
  .postSuccess-footer-heading h2 {
    font-size: 18px;
  }
  .postSuccess-footer-end .div {
    font-size: 12px;
  }
  .postSuccess-footer-end .strong-text {
    font-weight: 500;
    color: #000;
  }
  .content-space {
    padding: 5px;
  }
  .propertyScore-main-title h3 {
    font-size: 16px;
    font-weight: 600;
    color: #555;
  }
  .propertyScore-main-title p {
    font-size: 13px;
    color: #777;
    text-align: center;
  }
  .propertyScore-main-cntborder {
    border: 1px solid #ddd;
    width: 180px;
    margin: auto;
    padding: 20px;
    color: #bbb;
  }
  .key-icon {
    margin: 3px 0px;
  }
  .propertyScore-main-cntborder-bottom {
    margin-top: 3px;
  }
  .propertyScore-main-cntborder-bottom .percentage {
    font-size: 18px;
  }
  .propertyScore-footer h3 {
    font-size: 16px;
    font-weight: 600;
    color: #555;
  }
}

/* ================= ExploreOurServices End ======================== */

/* ================= ExploreRealEstate Start ======================== */

.realestate-container {
  height: 60vh;
}

.realestate-swiper {
  margin-left: 48px;
}

.realestate-container .realestate-head {
  padding-top: 10px;
  margin-left: 48px;
}

.realestate-container .realestate-head p {
  color: #878c9f;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}

.realestate-container .realestate-head h1 {
  color: #091e42;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
}

.estate-card {
  width: 90%;
  height: 35vh;
  background-color: #fff;
  margin-top: 48px;
  display: flex;
}

.estate-card .col {
  width: 100%;
  height: 60px;
  display: grid;
  column-gap: 10px;
  column-rule: 1px solid lightblue;
  grid-template-columns: 3fr 3fr 3fr 3fr;
}

.estate-card .col .col-inner-estate {
  margin-top: 30px;
  width: 250px;
}

.col-inner-estate .estate-inner-box-1 {
  height: 100px;
  display: flex;
}

.col-inner-estate .estate-inner-box-1 .estate-col-text {
  text-align: left;
  width: 250px;
  margin-left: -25px;
}

.col-inner-estate .estate-inner-box-1 .estate-col-text h2 {
  color: #091e42;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
}

.col-inner-estate .estate-inner-box-1 .estate-col-text p {
  color: #878c9f;
  font-size: 16px;
  text-align: left;
  line-height: 18px;
  margin-bottom: 3px;
  width: 100px;
  margin-top: 5px;
}

.col-inner-estate .estate-inner-box-1 .estate-col-img {
  width: 300px;
  height: 150px;
}

.col-inner-estate .estate-inner-box-1 .estate-col-img img {
  width: 50%;
  height: 50%;
  border-radius: 5px;
}

/* ================= ExploreRealEstate End ======================== */

/* ================= SimplePricing Start ======================== */

.container .pricing-head {
  color: #091e42;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
}

/* ================= SimplePricing End ======================== */

/* ================= PGCollections Start ======================== */

.curated-pg {
  padding-top: 10px;
  margin-top: 48px;
}

.curated-pg-swiper {
  width: 100%;
  margin-top: 28px;
  margin-left: 48px;
}

.curated-pg .pg-head h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
  margin-left: 48px;
}

.curated-pg .pg-head p {
  color: #878c9f;
  margin-top: 5px;
  margin-left: 48px;
}

.curated-pg-item {
  width: 400px;
  height: 300px;
}

.curated-pg-img1 {
  width: 200px;
}

.curated-pg-img1 img {
  width: 100%;
  border-radius: 15px;
}

.curated-pg-item .curated-pg-img1 img:hover {
  transform: scale(1.05);
  transition: all 0.4s ease-in-out;
}

.curated-pg-item .curated-pg-content {
  position: absolute;
  text-align: left;
  left: 0;
  right: 0;
  padding-left: 40px;
  bottom: 200px;
  z-index: 10;
}

.curated-pg-item .curated-pg-content h3 a {
  color: #091e42;
  text-align: left;
  font-weight: 700;
  font-size: 25px;
}

.curated-pg-item .curated-pg-content p {
  color: #878c9f;
  text-align: left;
}

/* ================= PGCollections End ======================== */

/* ================= HomesByFurnishing Start ======================== */

.homeby-pg {
  height: 64vh;
}

.homeby-head,
.homeby-body {
  margin-top: 48px;
  margin-left: 48px;
}

.homeby-pg .homeby-head h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
}

.homeby-pg .homeby-head p {
  color: #878c9f;
  margin-top: 5px;
}

.homeby-pg .homeby-body {
  width: 100%;
  display: flex;
  align-items: center;
}

.homeby-item .homeby-img {
  margin-left: -30px;
}

.homeby-item .homeby-img img {
  width: 80%;
  height: 80%;
  border-radius: 5px;
}

.homeby-item .homeby-img-name {
  color: #041533;
  margin-top: 10px;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

/* ================= HomesByFurnishing End ======================== */

/* ==================== SwiperRecommendedProperty Start ======================= */

.gray-bg {
  background-color: #fff;
}

/* .swiper-recom-prpty-left {
  margin: 20px;
} */

.swiper-recom-prpty-left-images {
  display: flex;
  height: 40vh;
}

.swiper-recom-prpty-left-images .swiper-recom-prpty-left-images-inner1 {
  width: 60%;
  padding: 3px;
}

.swiper-recom-prpty-left-images .swiper-recom-prpty-left-images-inner1 img {
  display: flex;
  justify-content: left;
  align-items: start;
  width: 100%;
  height: 39vh;
  border-radius: 5px;
}

.swiper-recom-prpty-left-images .swiper-recom-prpty-left-images-inner2 {
  width: 40%;
  padding: 3px;
}

.swiper-recom-prpty-left-images .swiper-recom-prpty-left-images-inner2 img {
  display: flex;
  justify-content: right;
  align-items: end;
  width: 100%;
  height: 19vh;
  margin-bottom: 5px;
  border-radius: 5px;
}

.swiper-recom-prpty-left-content p {
  margin-top: 5px;
  font-size: 12px;
}

.swiper-recom-prpty-left-content .recom-prpty-left-content-head {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.swiper-recom-prpty-left-content
  .recom-prpty-left-content-head
  .recom-prpty-left-content-head-img-inner {
  display: flex;
}

.swiper-recom-prpty-left-content
  .recom-prpty-left-content-head
  .recom-prpty-left-content-head-img-inner
  .recom-prpty-left-content-head-img {
  width: 120px;
  height: 50px;
  border: 1px solid black;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 5px;
}

.swiper-recom-prpty-left-content
  .recom-prpty-left-content-head
  .recom-prpty-left-content-head-img-inner
  .recom-prpty-left-content-head-img
  img {
  width: 100%;
}

.recom-prpty-left-content-head-img-inner .recom-prpty-left-content-head-text {
  margin-left: 15px;
}

.recom-prpty-left-content-head-img-inner
  .recom-prpty-left-content-head-text
  h1 {
  font-weight: bold;
  color: #000;
  font-size: 20px;
}

.recom-prpty-left-content-head-img-inner .recom-prpty-left-content-head-text p {
  font-size: 13px;
  color: #878c9f;
}

.recom-prpty-left-content-head .recom-prpty-left-content-head-button .sml-btn {
  background: #3270fc;
  color: white;
  padding: 10px 30px 10px 30px;
  border: none;
  border-radius: 5px;
}

.swiper-recom-prpty-left .prpty-left-content1 {
  display: flex;
  margin-top: 30px;
}

.swiper-recom-prpty-left .prpty-left-content1 .prpty-left-content1-inner1 p {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #317a36;
  background-color: #d7f2e3;
  border: 1px solid rgba(128, 128, 128, 0.727);
  border-radius: 3px;
  margin-right: 10px;
}

.swiper-recom-prpty-left .prpty-left-content1 .prpty-left-content1-inner2 p {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #317a36;
  color: #757398;
  background-color: #f5f5ff;
  border: 1px solid rgba(128, 128, 128, 0.427);
  border-radius: 3px;
  margin-right: 10px;
}

.swiper-recom-prpty-left .prpty-left-content1 .prpty-left-content1-inner3 p {
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  color: #42526e;
  background-color: #f4f5f7;
  border: 1px solid rgba(128, 128, 128, 0.727);
  border-radius: 3px;
}

.prpty-left-content2 {
  margin-top: 50px;
  background-color: #e5f3fc;
}

.swiper-recom-prpty-left .prpty-left-content2 {
  padding: 10px;
  border-radius: 10px;
}

.prpty-left-content2
  .prpty-left-content2-inner
  .prpty-left-content2-inner-head {
  text-align: left;
  font-size: 10px;
  color: #8993a4;
}

.prpty-left-content2 .prpty-left-content2-inner .prpty-left-content2-inner-sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prpty-left-content2
  .prpty-left-content2-inner
  .prpty-left-content2-inner-sub
  h1 {
  margin-top: 15px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.prpty-left-content2
  .prpty-left-content2-inner
  .prpty-left-content2-inner-sub
  p {
  color: #8993a4;
}

.prpty-left-content2-inner .prpty-left-content2-inner-sub .content2-inner-icon {
  color: #42526e;
  font-weight: bold;
  font-size: larger;
  margin-right: 30px;
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
}

.prpty-left-content6 {
  margin-top: 40px;
}

.topfacilities .topfacilities-head {
  display: inline-flex;
  width: 100%;
}

.topfacilities .topfacilities-head h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.topfacilities .topfacilities-head a {
  color: #0078db;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0 0 16px;
  margin-left: auto;
}

.topfacilities .topfacilities-subhead {
  font-size: 12px;
  line-height: 16px;
  width: 50%;
  color: #8993a4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-bottom: 20px;
}

.topfacilities-item {
  background: #fff;
  display: inline-block;
  height: 112px;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

.topfacilities-item
  .topfacilities-cards
  .topfacilities-subcards
  .topfacilities-card-img {
  width: 97px;
  margin-right: 12px;
}

.topfacilities-item .topfacilities-cards {
  display: flex;
  /* justify-content: left !important;
  align-content: start !important; */
}

.topfacilities-cards .topfacilities-subcards {
  display: inline-flex;
  height: 112px;
  width: 150px;
  overflow: hidden;
  position: relative;
  padding: 12px 8px;
  vertical-align: top;
}

.topfacilities-subcards
  .topfacilities-card-img
  .topfacilities-card-facilities
  img {
  height: 52px;
  width: 52px;
  background-size: 52px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 80px;
}

.topfacilities-card-img .bg-different {
  position: absolute;
  bottom: 0;
  width: 80%;
  left: 0;
  height: 80px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  background: #fffbf3;
  border-radius: 10px;
}

.topfacilities-card-img .bg-different1 {
  position: absolute;
  bottom: 0;
  width: 80%;
  left: 0;
  height: 80px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  background: #f0f9ff;
  border-radius: 10px;
}

.topfacilities-card-img .topfacilities-card-facilities {
  font-size: 12px;
  color: #8993a4;
  position: relative;
  z-index: 1;
  white-space: normal;
}

.topfacilities-card-img .topfacilities-card-facilities div {
  font-size: 12px;
  color: #8993a4;
  position: relative;
  z-index: 1;
  white-space: normal;
  text-align: left;
}

.topfacilities .topfacilities-bottomtext {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-top: 20px;
}

.topfacilities .topfacilities-bottomtext span {
  vertical-align: middle;
}

.topfacilities .topfacilities-bottomtext i {
  vertical-align: middle;
  margin-left: 7px;
  margin-top: 3px;
}

.prpty-left-content8 {
  margin-top: 40px;
}

.prpty-left-content8 .left-content8-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 10px;
}

.prpty-left-content8 .left-content8-body {
  width: 700px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.prpty-left-content8 .left-content8-body span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.left-content8-footer .left-content8-footer-caption {
  margin-bottom: 10px;
}

.left-content8-footer .left-content8-footer-head {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-top: 20px;
}

.left-content8-footer .left-content8-footer-head i {
  margin-left: 5px;
  vertical-align: middle;
}

.left-content8-footer .left-content8-footer-caption {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.prpty-left-content9 {
  margin-top: 40px;
}

.prpty-left-content9 .brochure-content {
  display: flex;
  justify-content: space-between;
}

.brochure-content .brochure-head h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.brochure-content .brochure-head p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.brochure-content i {
  color: #0078db;
  font-size: 24px;
  font-weight: 600;
  padding: 4px 0 0;
  margin-left: auto;
  cursor: pointer;
}

.priceinsights {
  margin-top: 40px;
}

.priceinsights .priceinsights-head {
  margin-bottom: 20px;
}

.priceinsights .priceinsights-head h2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 10px;
}

.priceinsights .priceinsights-head p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 30px;
}

.priceinsights .priceinsights-wrap {
  border: 1px solid rgb(235, 236, 240);
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
}

.priceinsights .priceinsights-wrap .priceinsights-wrap-in {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 32px;
  cursor: pointer;
}

.priceinsights-wrap-img {
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
  margin-right: 32px;
}

.priceinsights-wrap-img {
  height: 40px;
  width: 40px;
}

.priceinsights-wrap-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 32px 0px 20px;
  border-bottom: 1px solid rgb(235, 236, 240);
}

.priceinsights-wrap-text h2 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.priceinsights-wrap-text a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.prpty-left-content11 {
  margin-top: 40px;
}

.prpty-left-content11 .checkproperty-banner {
  padding: 32px;
  width: 100%;
  margin-right: 0px !important;
  display: flex;
  align-items: center;
  background-color: rgb(244, 245, 247);
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid rgb(244, 245, 247);
  cursor: pointer;
}

.checkproperty-banner img {
  width: 156px;
  height: 102px;
  object-fit: cover;
}

.checkproperty-banner div {
  width: 40%;
  color: rgb(9, 30, 66);
  font-size: 20px;
  font-weight: bold;
}

.checkproperty-banner button {
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: #0078db;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  margin: 0;
  vertical-align: middle;
  cursor: pointer;
  border: 0;
  min-height: auto;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  text-align: center;
}

.checkproperty-banner-report {
  width: 100%;
  position: relative;
  padding: 12px 0 0;
}

.checkproperty-banner-report i {
  width: 24px;
  height: 24px;
  background-size: 408px 287px;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;
}

.checkproperty-banner-report span {
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
}

.checkproperty-banner-report .banner-report-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  text-align: left;
}

.checkproperty-banner-report .banner-report-text-here {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #0078db;
  text-align: left;
}

.prpty-left-content12 {
  margin-top: 40px;
}

.explore-gachibowli .gachibowli-head div {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.explore-gachibowli .gachibowli-head a {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.gachibowli-rating {
  margin-top: 10px;
}

.gachibowli-rating .gachibowli-rating-in {
  display: inline-flex !important;
  align-items: center;
  white-space: nowrap;
}

.gachibowli-rating .gachibowli-rating-in .h-badge1 {
  height: 24px !important;
  background-color: #f4f5f7;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  border: 1px solid #ebecf0;
  color: #42526e;
  margin-right: 10px;
}

.gachibowli-rating .gachibowli-rating-in .h-badge2 {
  height: 24px !important;
  background-color: #f4f5f7;
  padding: 4px 8px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  font-weight: 600;
  border-radius: 2px;
  border: 1px solid #ebecf0;
  color: #42526e;
}

.gachibowli-rating .gachibowli-rating-in .h-badge2 i {
  color: green;
  display: inline-block;
  font-style: normal;
  align-items: center;
}

.explore-gachibowli .gachibowli-table {
  width: 100%;
  border: 1px solid #ebecf0;
  display: flex;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.explore-gachibowli .gachibowli-table .gachibowli-table-col {
  width: calc(50% - 6px);
  border-radius: 4px;
  padding: 20px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-right: 12px;
  position: relative;
}

.explore-gachibowli .gachibowli-table .gachibowli-table-col:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 106, 194, 0.2);
  background-color: #fff;
  transform: scale(1.01);
}

.explore-gachibowli .gachibowli-table .gachibowli-table-col::before {
  content: "";
  width: 4px;
  position: absolute;
  height: 90%;
  margin: auto;
  left: -4px;
  border-right: 1px solid #ebecf0;
  top: 0;
  bottom: 0;
}

.explore-gachibowli
  .gachibowli-table
  .gachibowli-table-col
  .gachibowli-table-col-head {
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
}

.explore-gachibowli
  .gachibowli-table
  .gachibowli-table-col
  .gachibowli-table-col-subhead {
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
}

.gachibowli-table-col-head .table-col-headicon {
  margin-right: 16px;
}

.gachibowli-table-col-subhead .table-col-headicon {
  margin-right: 16px;
}

.gachibowli-table-col-head .table-col-headicon i {
  width: 20px;
  height: 20px;
  background-size: 400px 200px;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;
}

.gachibowli-table-col-head .table-col-headicon .ai-like {
  font-size: 20px;
  color: green;
}

.gachibowli-table-col-head .table-col-headicon .ai-dislike {
  font-size: 20px;
  color: red;
}

.gachibowli-table-col-head .table-col-head {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #041533;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.gachibowli-table-col-subhead .table-col-subhead {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.table-col-subhead span {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #0078db;
  text-align: left;
}

.locality-reviews .locality-reviews-head h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.locality-reviews .locality-reviews-head a {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-top: 10px;
}

.locality-reviews .reviews-wrap {
  display: flex;
  margin: 20px 0;
  border: 1px solid #ebecf0;
  border-radius: 8px;
  padding: 20px 0;
}

.locality-reviews .reviews-wrap .reviews-left-wrap {
  width: 315px;
  padding: 0px 30px;
  border-right: 1px solid #ebecf0;
}

.locality-reviews .reviews-wrap .reviews-right-wrap {
  padding: 0px 20px 0px 30px;
  margin: auto 0 !important;
}

.reviews-right-wrap .reviews-wrap-comp {
  flex-direction: column;
  align-items: center;
  margin-top: 36px;
  /* display: flex; */
}

.reviews-ratingarea {
  margin-right: inherit;
  margin-bottom: 24px;
  text-align: center;
}

.reviews-ratingarea .inner-ratingarea {
  display: flex;
  align-items: baseline;
  letter-spacing: 4px;
  justify-content: center;
  margin-bottom: 10px;
}

.reviews-ratingarea .inner-ratingarea .inner-bold {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.reviews-ratingarea .inner-ratingarea .inner-small {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.inner-ratingarea .star-ratings {
  font-size: 16px;
  width: 100px;
}

.star-ratings .star-ratings-inner {
  display: flex;
  justify-content: center;
  color: #2eba37;
}

.star-ratings .star-ratings-inner .star-in {
  width: 20px;
  height: 20px;
  margin: 0 2px;
}

.star-ratings-average {
  text-align: center;
  line-height: 16px;
  font-weight: 600;
  color: #091e42;
  display: block;
  vertical-align: middle;
  margin-top: 10px;
}

.star-ratings-count {
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  vertical-align: middle;
}

.reviews-row-wrap-text {
  display: flex;
}

.reviews-ratingwrap {
  width: 260px;
}

.reviews-ratingwrap div {
  margin-bottom: 10px;
  line-height: 0;
  cursor: pointer;
}

.reviews-ratingwrap .ratingwrap-component .ratingwrap-component-inner {
  display: flex;
}

.rating-level-wrap {
  width: 100%;
  align-items: center;
}

.ratingwrap-component .reviews-row-wrap {
  background: #ebecf0;
  height: 8px;
  width: calc(100% - 40px);
  margin-right: 12px;
  position: relative;
  border-radius: 2px;
}

.ratingwrap-component .reviews-row-wrap div {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  height: 8px;
  border-radius: 2px;
  background-color: #2b79d4;
}

.reviews-row-wrap {
  display: flex;
  width: 100%;
  align-items: center;
}

.reviews-row-wrap div {
  display: inline-block;
}

.reviews-row-wrap-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8993a4;
  text-align: left;
  vertical-align: middle;
}

.reviews-row-wrap-text .row-wrap-number {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8993a4;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}

.reviews-row-wrap-text .row-wrap-star {
  margin-left: 5px;
  color: #42526e;
  vertical-align: middle;
  font-size: 18px;
}

.reviews-ratingfooter {
  margin-left: auto;
  padding-right: 13px;
  margin-right: auto;
  font-style: normal;
  font-weight: 600;
  color: #0078db;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.reviews-right-wrap {
  padding: 0px 20px 0px 30px;
  margin: auto 0 !important;
}

.reviews-right-wrap div .right-wrap-head {
  color: #041533 !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
}

.rating-features-circle {
  display: flex;
  align-items: center;
  margin-right: 24px;
  float: left;
  margin-top: 24px;
}

.rating-features-circle .features-circle-in i {
  display: inline-block;
  font-size: 24px;
}

.rating-features-circle .features-circle-in {
  width: 40px;
  margin: auto 16px auto 0;
  display: flex;
}

.rating-features-circle .rating-features-text {
  width: 100%;
}

.rating-features-circle .rating-features-text .rating-features-text-head {
  color: #041533 !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
}

.rating-features-circle .rating-features-text .rating-features-text-subhead {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #041533 !important;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.rating-features-container {
  display: flex;
  overflow: hidden;
  margin-bottom: 30px;
}

.list-down-head {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #041533 !important;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.popular-mentions {
  display: flex;
  width: 100%;
  align-items: center;
}

/* .popular-mentions .postive-mentions .popular-mentions-in {
  display: inline-flex;
  border-radius: 0;
  width: 560px;
  height: 16px;
} */

.popular-mentions .postive-mentions .postive-mentions {
  background-color: #ffdede;
  position: relative;
  height: 16px;
}

.popular-mentions .postive-mentions .pink-bar-mentions {
  background-color: #ffdede;
  position: relative;
  height: 16px;
  position: relative;
  background-color: #d7f2e3;
  border-right: 4px solid #fff;
  height: 16px;
}

.popular-mentions-text {
  white-space: nowrap;
  vertical-align: text-top;
  width: 24px;
  margin-top: 10px;
}

.popular-mentions-text div {
  display: inline-block;
}

.popular-mentions-headtext {
  color: #2e7b32 !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 20px;
}

.popular-mentions-review-count {
  display: flex;
  align-items: start;
  margin-top: 20px;
  background-color: transparent;
  font-weight: 600;
  color: #42526e;
}

.popular-mentions-review-count .popular-mentions-openion {
  white-space: nowrap;
  width: 90px;
  flex: 0 0 auto;
  margin-right: 10px;
}

.popular-mentions-review-count > div:first-child {
  background-color: transparent;
  font-weight: 600;
  color: #42526e;
  white-space: nowrap;
}

.popular-mentions-review-count .popular-mentions-list > li > div:last-child {
  /* position: absolute;
  width: auto;
  left: 25%;
  bottom: -40px;
  display: none; */
}

.popular-mentions-list li {
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  color: #041533;
  cursor: pointer;
  display: inline-block;
  position: relative;
  background-color: #f4f5f7;
  justify-content: left !important;
}

.prpty-left-content14 {
  margin-top: 40px;
}

.reviewsby-residents-head {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.reviewsby-residents-head div h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.reviewsby-residents-pills {
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
}

.reviewsby-residents-pills div {
  margin-right: 10px;
}

.reviewsby-residents-pills .active-pill h3 {
  border: 1px solid #a3daff;
  color: #091e42;
  font-weight: 600;
  background: #f0f9ff;
  width: auto;
  max-width: unset;
}

.reviewsby-residents .reviewsby-residents-pills div h3 {
  border: 1px solid rgba(128, 128, 128, 0.445);
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
  width: 150px;
}

/* ==================== SwiperRecommendedProperty End ======================= */

/* ============================== ReviewsByResidents swiper Start ======================= */

.reviewsby-residents .floorplans-head-pills {
  display: flex;
  margin-top: 15px;
}

.reviewsby-residents .floorplans-head-pills div {
  margin-right: 10px;
}

.reviewsby-residents .floorplans-head-pills .active-pill h3 {
  border: 1px solid #a3daff;
  color: #091e42;
  font-weight: 600;
  background: #f0f9ff;
  width: auto;
  max-width: unset;
}

.reviewsby-residents .floorplans-head-pills div h3 {
  border: 1px solid rgba(128, 128, 128, 0.445);
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.reviewsby-residents h1 {
  font-weight: bold;
  color: #144273;
  font-size: 20px;
  text-align: left;
  font-weight: 600;
}

.reviewsby-residents-swiper .reviewsby-residents-swiper-subtext {
  font-size: 14px;
  margin-left: 30px;
  margin-top: 20px;
  vertical-align: middle;
}

.reviewsby-residents-swiper-card {
  border: 1px solid #ebecf0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 16px 20px 16px;
  height: 100% !important;
  position: relative;
  width: 322px;
}

.reviewsby-residents-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.linebar-head {
  display: flex;
  justify-content: space-between;
  color: #091e42;
  font-weight: 600;
  font-size: 15px;
  margin-top: 20px;
}

.linebar-head p {
  display: flex;
  justify-content: space-between;
  color: #878c9f;
  font-weight: 400;
  font-size: 15px;
}

.reviewsby-residents-card-text-top-linebar h2 {
  border-bottom-style: 3px dashed #878c9f;
}

.reviewsby-residents-swiper-card .reviewsby-residents-swiper-card-text-top {
  text-align: left;
}

.reviewsby-residents-swiper-card-text-top
  .reviewsby-residents-swiper-card-text-top-in {
  margin-bottom: 9px;
  padding: 0%;
  width: 50px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  background-color: #2e7b32;
}

.reviewsby-residents-swiper-card-text-top
  .reviewsby-residents-swiper-card-text-top-in1 {
  display: inline-flex;
}

.reviewsby-residents-swiper-card-text-top
  .reviewsby-residents-swiper-card-text-top-in1
  h2 {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #8993a4 !important;
  text-transform: uppercase;
  margin-top: 12px;
}

.reviewsby-residents-swiper-card-text-top
  .reviewsby-residents-swiper-card-text-top-in-text
  p {
  font-size: 14px;
  color: rgb(4, 21, 51);
}

.reviewsby-residents-swiper-card-text-top
  .reviewsby-residents-swiper-card-text-top-in-text
  p
  span {
  color: #0078db;
}

.reviewsby-residents-swiper-card-text-top
  .reviewsby-residents-swiper-card-text-top-in1
  div
  p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.reviewsby-residents-swiper-card-text-top
  .reviewsby-residents-swiper-card-text-top-in
  h2 {
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  width: 50px;
  text-align: center;
}

.reviewsby-residents-footer {
  margin-top: 30px;
  white-space: normal;
  display: inline-flex;
}

.reviewsby-residents-footer .reviewsby-residents-foot-img {
  background: #ffe6bc;
  color: #844b00;

  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  text-align: center;
  line-height: 32px;
  overflow: hidden;
}

.reviewsby-residents-foot-name .foot-name-bold {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.reviewsby-residents-foot-name .foot-name-semibold {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.reviewsby-residents-swiper-card4 {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 16px 40px 16px;
  position: relative;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f0f9ff;
  width: 322px !important;
  height: 380px;
  cursor: pointer;
  border: 1px solid #ebecf0;
}

.reviewsby-residents-swiper-card-text-top-in4 i {
  font-size: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.reviewsby-residents-swiper-card-text-top-in4 h2 {
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: center;
  vertical-align: middle;
}

.reviewsby-residents-swiper-card-text-top-in4 p {
  /* margin-top: 10px; */
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: center;
  vertical-align: middle;
}

.reviews-feedback {
  margin-top: 20px;
  display: flex;
}

.reviews-feedback-left {
  background: rgb(255, 255, 255);
  text-align: left;
  padding: 0px 8px 0px 4px;
  margin-top: 0px;
}

.reviews-feedback-left label {
  margin-right: 13px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8993a4;
  text-align: left;
  vertical-align: middle;
}

.reviews-feedback-left span {
  margin-right: 16px;
  margin-left: -5px;
  cursor: pointer;
  opacity: 0.6;
}

.reviews-feedback-left span i {
  background-repeat: no-repeat;
  background-size: 272px 192px;
  display: inline-block;
  font-style: normal;
  height: 16px;
  vertical-align: middle;
  width: 16px;
  margin-left: 5px;
}

.reviews-feedback-button {
  margin-left: 20%;
  width: 280px;
}

.reviews-feedback-button button {
  width: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: #0078db;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  margin: 0;
  cursor: pointer;
  border: 0;
  min-height: auto;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.reviews-banner {
  margin-top: 40px;
}

.reviews-banner .reviews-banner-body:hover {
  background-color: #e7e8ecb0;
  border-radius: 16px;
}

.reviews-banner .reviews-banner-body {
  background-color: #f4f5f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  border-radius: 16px;
  border: 1px solid #f4f5f7;
  cursor: pointer;
}

.reviews-banner-body div {
  width: 50%;
}

.reviews-banner-body img {
  height: 150px;
}

.reviews-banner-body div .ng500 {
  color: #42526e !important;
}

.reviews-banner-body div .body-large {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #041533;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.reviews-banner-body .reviews-banner-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  width: 300px;
}

.reviews-banner-body .reviews-banner-button {
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: #0078db;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  margin: 0;
  cursor: pointer;
  border: 0;
  min-height: auto;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.reviews-banner-body .reviews-banner-button i {
  background-size: 408px 287px;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;
  margin-left: 3px;
  font-size: 24px;
  font-weight: bold;
}

.prpty-left-content16 {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;
}

.aliens-developers .aliens-developers-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.aliens-developers-subtitle {
  margin-top: 30px;
}

.aliens-developers-subtitle div {
  display: inline-block;
  vertical-align: top;
  width: 90%;
}

.aliens-developers-subtitle .aliens-developers-img {
  width: 72px;
  height: 40px;
  border: 1px solid #f4f5f7;
  margin-right: 8px;
}

.aliens-developers-subtitle .aliens-developers-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.aliens-first-child {
  width: 80%;
}

.aliens-first-child a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.aliens-second-child {
  display: inline-block;
  vertical-align: top;
}

.aliens-second-child button {
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: rgba(0, 0, 0, 0);
  /* border-radius: 4px; */
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  margin: 0;
  /* vertical-align: middle; */
  cursor: pointer;
  min-height: 40px;
  border: 1px #0078db solid;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #0078db;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.aliens-second-child button:hover {
  background-color: #d1eefc9b;
}

.aliens-developers-content {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;

  padding-bottom: 20px;
  border-bottom: 1px solid #ebecf0;
}

.frequently-aq-head .frequently-aq-head-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.frequently-aq-head .frequently-aq-head-title h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.frequently-aq .faq-wrap {
  width: 742px;
  border: 1px solid #f4f5f7;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.faq-wrap .faq-question-block {
  width: 100%;
  border-bottom: 1px solid #f4f5f7;
  padding: 16px 12px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.faq-wrap .faq-question-block div {
  display: inline-block;
  vertical-align: top;
  padding-top: 4px;
}

.faq-question-block .faq-question-logo {
  width: 36px;
  height: 36px;
  background: #f4f5f7;
  -webkit-border-radius: 50%;
  margin-right: 12px;
  padding: 8px 12px;
}

.faq-question-block .faq-question-logo .caption-faq {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.faq-question-block div.faq-question-bold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.faq-question-block div .faq-subquestion-bold {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

/* ============================== ReviewsByResidents swiper End ======================= */

/* ============================== InterestingReads swiper Start ======================= */

.interesting-reads {
  margin-bottom: 40px;
}

.interesting-reads-head {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.interesting-reads .interesting-reads-head h1 {
  font-weight: bold;
  color: #144273;
  font-size: 26px;
  text-align: left;
  font-weight: 600;
}

.interesting-reads .interesting-reads-head a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #0078db;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.interesting-reads .homeby-body {
  width: 100%;
  display: flex;
  align-items: center;
}

.interesting-reads .interesting-reads-img {
  text-align: left;
}

.interesting-reads .interesting-reads-img img {
  width: 80%;
  height: 80%;
  border-radius: 5px;
}

.interesting-reads .interesting-reads-img-name h3 {
  margin-top: 10px;
  margin-bottom: 4px;
  white-space: normal;
  font-size: 14px;
  color: #041533 !important;
  line-height: 20px;
  font-weight: 600;
  display: block;
  text-align: left;
  vertical-align: middle;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.interesting-reads .interesting-reads-img-name a {
  margin-top: 10px;
  margin-bottom: 4px;
  white-space: normal;
  font-size: 14px;
  color: #8993a4 !important;
  line-height: 20px;
  font-weight: 400 !important;
  display: block;
  text-align: left;
  vertical-align: middle;
  font-size: 12px;
  line-height: 16px;
}

/* ============================== InterestingReads swiper End ======================= */

/* ============================== ProjectByAliens swiper start ======================= */

.prpty-left-content17 {
  margin-top: 40px;
}

.projects-by-aliens-head {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.projects-by-aliens-head h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.projects-by-aliens-pills {
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
}

.projects-by-aliens-pills div {
  margin-right: 10px;
}

.projects-by-aliens-pills .active-pill h3 {
  border: 1px solid #a3daff;
  color: #091e42;
  font-weight: 600;
  background: #f0f9ff;
  width: auto;
  max-width: unset;
}

.projects-by-aliens .projects-by-aliens-pills div h3 {
  border: 1px solid rgba(128, 128, 128, 0.445);
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
  width: 100px;
}

.projectsbyaliens-swiper-card .projectsbyaliens-swiper-card-text-top {
  text-align: left;
}

.projectsbyaliens-swiper-card-text-top
  .projectsbyaliens-swiper-card-text-top-in {
  height: 160px;
  position: relative;
}

.projectsbyaliens-swiper-card-text-top
  .projectsbyaliens-swiper-card-text-top-in
  img {
  width: 100%;
  height: 185px;
  object-fit: cover;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.card-img-tags .card-img-tags-holder {
  position: absolute;
  left: 0;
  top: 4px;
}

.card-img-tags div {
  display: inline-block;
  vertical-align: middle;
}

.card-img-tags .img-tick-icon {
  border-radius: 3px;
  padding: 0px 5px 0px 5px;
  background: #06afb0;
  border-color: #06afb0;
}

.card-img-tags .img-tick-icon .img-tick-icon1 {
  width: 9px;
  height: 5px;
  border: 1px solid #fff;
  border-color: transparent transparent #fff #fff;
  display: inline-block;
  transform: rotate(-45deg);
  margin: 0px 4px 0 0;
  margin-top: -5px;
}

.card-img-tags .img-tick-icon .img-tick-icon-text {
  color: #fffefe;
  font-size: 10px;
  font-weight: bold;
  margin-top: -5px;
}

.card-img-text {
  position: absolute;
  bottom: -20px;
  color: #fff;
  width: 100%;
  z-index: 1;
  padding: 0 12px;
  font-size: 10px;
}

.card-text-top-in-img i {
  width: 24px;
  height: 24px;
  background-size: 408px 287px;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;

  top: 12px;
  right: 12px;
  position: absolute;
  color: #fff;
}

.projectsbyaliens-swiper-card-text-top
  .projectsbyaliens-swiper-card-text-top-in1 {
  display: inline-flex;
  margin-top: 30px;
}

.projectsbyaliens-swiper-card-text-top
  .projectsbyaliens-swiper-card-text-top-in1
  a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #091e42;
  text-align: left;
  vertical-align: middle;
}

.projectsbyaliens-swiper-card-text-top
  .projectsbyaliens-swiper-card-text-top-in1
  p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.projectsbyaliens-swiper-card-text-top
  .projectsbyaliens-swiper-card-text-top-in1
  div {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-top: 10px;
}

.projectsbyaliens-swiper-card {
  border: 1px solid #ebecf0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 16px 20px 16px;
  height: 100% !important;
  position: relative;
  width: 322px;
}

.projectsbyaliens-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* ============================== ProjectByAliens swiper End ======================= */

/* ==================== LocationAdventages Start ======================= */

.locationadventage {
  padding-top: 10px;
  margin-top: 48px;
}

.locationadventage .location-head .location-head-inner {
  display: inline-flex;
  width: 100%;
}

.locationadventage .location-head .location-head-inner h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.locationadventage .location-head .location-head-inner a {
  color: #0078db;
  font-size: 14px;
  font-weight: 600;
  padding: 4px 0 0 16px;
  margin-left: auto;
}

.location-subhead {
  font-size: 12px;
  line-height: 16px;
  width: 50%;
  color: #8993a4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.location-swiper {
}

.location-swiper-card {
  width: 100%;
  height: 60px;
  display: inline-flex;
  border: 1px solid rgba(128, 128, 128, 0.389);
  border-radius: 5px;
  margin-top: 30px;
}

.location-swiper-card img {
  width: 60px;
  height: 60px;
  padding: 10px;
  vertical-align: middle;
}

.location-swiper-card-text {
  margin-left: 5px;
  margin: 10px;
  text-align: left;
}

.location-swiper-card-text h2 {
  width: 150px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.location-swiper-card-text p {
  width: 50px;
  height: 20px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

/* ==================== LocationAdventages End ======================= */

/* ==================== RecomPropertySwiper Start ======================= */

.recom-ppt {
  height: 60vh;
  margin-top: 50px;
}

.recom-ppt .recom-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recom-ppt .recom-subhead {
  text-align: left;
  font-weight: 600;
  color: #42526e;
  margin: 14px 0 10px 0;
  font-size: 16px;
}

.recom-ppt .recom-head h1 {
  font-weight: bold;
  color: #144273;
  font-size: 24px;
  text-align: left;
  font-weight: 600;
}

.recom-ppt .recom-head p {
  color: #878c9f;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}

.recom-ppt .recom-head h1 span {
  color: #2d8aee;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}

.recom-ppt .recom-head button {
  padding: 10px 25px 10px 25px;
  color: #2d8aee;
  font-weight: bold;
  border: 1px solid #2d8aee;
  background-color: #fff;
  border-radius: 5px;
}

.recom-ppt .recom-swiper {
  width: 100%;
  display: flex;
  align-items: center;
}

.recom-item {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  width: 350px;
  height: 150px;
  border: 1px solid rgba(128, 128, 128, 0.367);
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 30px;
}

.recom-item .recom-item-head {
  background: #f5f5ff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  height: 6vh;
}

.recom-item .recom-item-head h1 {
  font-size: 16px;
  text-align: left;
  justify-content: center;
  vertical-align: middle;
  margin-left: 5px;
  color: #878c9f;
}

.recom-item .recom-item-head h1 span {
  font-weight: bold;
  color: #144273;
}

.recom-item .recom-body {
  text-align: left;
  padding: 10px;
}

.recom-item .recom-body p {
  color: #42526e;
  font-size: 12px;
  margin-top: 10px;
}

.recom-item .recom-body h3 {
  color: #144273;
  font-size: 14px;
  height: 30px;
  font-weight: 600;
}

.recom-item .recom-body h3 span {
  color: #878c9f;
  font-size: 12px;
  margin-left: 0%;
  padding-left: 0%;
  text-align: left;
}

.recom-item .recom-body h2 {
  color: black;
  font-weight: 600;
  font-size: 16px;
  height: 30px;
  margin-top: 10px;
}

.recom-item .recom-body h2 span {
  color: #2d8aee;
  font-size: 12px;
  margin-left: 0%;
  padding-left: 0%;
  text-align: left;
}

.aliens-space-station-head h1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.aliens-space-station div {
  display: flex;
  padding: 12px 0;
}

.aliens-space-station .aliens-space-head-pills div {
  margin-right: 10px;
}

.aliens-space-station .aliens-space-head-pills div h3 {
  font-size: 14px;
  border: 1px solid rgba(128, 128, 128, 0.445);
  padding: 4px 10px 4px 10px;
  border-radius: 30px;
}

.text-only {
  box-shadow: "";
  background: #fff;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  outline: none;
  cursor: pointer;
  border: 1px solid #ebecf0;
  padding: 4px 12px 2px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #42526e;
  display: inline-block;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.prpty-left-content21 {
  margin-top: 60px;
}

.prpty-left-footer-content p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #42526e;
  text-align: left;
}

.prpty-left-footer-content p span {
  color: #007bff;
}

.prpty-left-footer-content div p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #42526e;
  text-align: left;
}

.profile-widget-in .recom-prpty-right-swiper-card {
  background-color: #fff;
  border: 1px solid #ebecf0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 16px 20px 16px;
  height: 100% !important;
  position: relative;
  width: 380px;
}

.profile-widget-in
  .recom-prpty-right-swiper-card
  .recom-prpty-right-swiper-card-text-top {
  text-align: left;
}

.profile-widget-in
  .recom-prpty-right-swiper-card-text-top
  .recom-prpty-right-swiper-card-text-top-in {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.profile-widget-in
  .recom-prpty-right-swiper-card-text-top
  .recom-prpty-right-swiper-card-text-top-in-img
  img {
  width: 40px;
  height: 30px;
}

.recom-prpty-right-swiper-card-text-top-in h2 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-left: 10px;
}

.right-swiper-card-text-top-in-text-in {
  display: inline-flex;
}

.recom-prpty-right-swiper-card-text {
  padding: 16px 16px 20px 16px;
  height: 100% !important;
  position: relative;
  width: 360px;
}

.recom-prpty-right-swiper-card-text li {
  margin-bottom: 12px;
  list-style-type: disc;
  color: #007bff;
}

.right-swiper-card-text-top-in-text p {
  margin-left: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.recom-prpty-right-swiper-card-text .right-viewmore-link h2 {
  color: #0078db;
  padding: 7px 0px 8px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.recom-prpty-right-swiper-card-text .right-viewmore-link h2 i {
  color: #0078db;
  padding: 7px 0px 8px 16px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  vertical-align: middle;
  margin-left: -10px;
}

.recom-prpty-right-foot-text {
  display: inline-flex;
  justify-content: space-between;
}

.recom-prpty-right-foot-text-in .foot-name-bold {
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.recom-prpty-right-foot-text-in .foot-name-semibold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.recom-prpty-right-foot-img {
  width: 89px;
  height: 50px;
  border: 1px solid #ebecf0;
  margin-top: 0;
  margin-left: 20px;
}

.recom-prpty-right-foot-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.prpty-right-footer-button {
  margin-top: 30px;
}

.prpty-right-footer-button button {
  box-shadow: rgba(0, 106, 194, 0.2) 0px 1px 4px 0px;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  outline: none;
  margin: 0px;
  cursor: pointer;
  min-height: 40px;
  border: 1px solid rgb(0, 120, 219);
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: rgb(0, 120, 219);
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.fixed-element {
  position: absolute;
  top: 0px;
  right: 0%;
  transform: translateX(0%);
  color: #fff;
  padding: 10px;
  display: fixed;
}

.sticky-active {
  position: sticky;
  display: block;
}

/* ==================== RecomPropertySwiper End ======================= */

/* ======================= Related to your search Swiper Start =================== */

.prpty-left-content20 {
  margin-top: 40px;
}

.related-to-search .related-search-head h1 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.related-search-swiper-card {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 16px 20px 16px;
  height: 100% !important;
  position: relative;
  width: 250px;
  margin-top: 40px;
}

.related-search-swiper-card .related-search-swiper-card-text-top {
  text-align: left;
}

.related-search-swiper-card-text-top .related-search-swiper-card-text-top-in {
  margin-bottom: 9px;
  padding: 0%;
  width: 50px;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  background-color: #2e7b32;
}

.related-search-swiper-card-text-top
  .related-search-swiper-card-text-top-in-text
  h3 {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.related-search-swiper-card-text-top
  .related-search-swiper-card-text-top-in-text
  .related-search-textin
  div {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.related-search-swiper-card-text-top
  .related-search-swiper-card-text-top-in-text
  .related-search-textin
  p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.related-search-swiper-card-text-top
  .related-search-swiper-card-text-top-in-text
  .related-search-textin
  p:hover {
  color: #000;
}

.related-search-swiper-card-text-top
  .related-search-swiper-card-text-top-in-text
  a {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-top: 10px;
}

.related-search-swiper-card-text-top
  .related-search-swiper-card-text-top-in-text
  a:hover {
  color: #007bff;
}

/* ======================= Related to your search Swiper End =================== */

/* ==================== FloorPlansSwiper Start ======================= */

.floorplans .floorplans-head-pills {
  display: flex;
  margin-top: 15px;
}

.floorplans .floorplans-head-pills div {
  margin-right: 10px;
}

.floorplans .floorplans-head-pills .active-pill h3 {
  border: 1px solid #a3daff;
  color: #091e42;
  font-weight: 600;
  background: #f0f9ff;
  width: auto;
  max-width: unset;
}

.floorplans .floorplans-head-pills div h3 {
  border: 1px solid rgba(128, 128, 128, 0.445);
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.floorplans h1 {
  font-weight: bold;
  color: #144273;
  font-size: 20px;
  text-align: left;
  font-weight: 600;
}

.floorplans p {
  color: #878c9f;
  margin-top: 5px;
}

.floorplans-swiper {
  background: #f0f9ff;
  margin-top: 13px;
  border-radius: 10px;
}

.floorplans-swiper .floorplans-swiper-subtext {
  font-size: 14px;
  margin-left: 30px;
  margin-top: 20px;
  vertical-align: middle;
  /* padding-top: 30px; */
}

.floorplans .floorplans-top-nav-item {
  margin-right: 15px;
}

.floorplans .active {
  border-bottom: 2px solid #007bff;
}

.floorplans-swiper-card {
  justify-content: center;
  align-content: center;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 287px;
  height: 392px;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
}

.floorplans-swiper-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.linebar-head {
  display: flex;
  justify-content: space-between;
  color: #091e42;
  font-weight: 600;
  font-size: 15px;
  margin-top: 20px;
}

.linebar-head p {
  display: flex;
  justify-content: space-between;
  color: #878c9f;
  font-weight: 400;
  font-size: 15px;
}

.floorplans-swiper-card-text-top-linebar h2 {
  border-bottom-style: 3px dashed #878c9f;
}

.floorplans-swiper-card .floorplans-swiper-card-chart img {
  text-align: center;
  width: 60%;
  padding: 30px;
}

.floorplans-swiper-card .floorplans-swiper-card-text-top {
  text-align: left;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in {
  margin-bottom: 9px;
  padding: 0%;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in1 {
  display: inline-flex;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in1 div h2 {
  font-size: 16px;
  color: #42526e;
  text-align: left;
  vertical-align: middle;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in1 div p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in h2 {
  color: #091e42;
  font-weight: 700;
  font-size: 18px;
}

.floorplans-swiper-card
  .floorplans-swiper-card-text-top
  .floorplans-swiper-card-text-top-icon {
  font-weight: bold;
  color: #878c9f;
  font-size: 25px;
}

.floorplans-swiper-card .floorplans-swiper-card-text-bottom {
  text-align: left;
}

.floorplans-swiper-card .floorplans-bottom-text-blue a h2 {
  color: #2664f4;
  margin-top: 60px;
}

.floorplans-swiper-card-text-bottom .floorplans-bottom-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in-img {
  width: 248px;
  margin: 10px;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in-img img {
  width: 100%;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in-card {
  margin-left: 14px;
  margin-right: 14px !important;
  margin-top: 7px;
  margin-bottom: 7px;
  height: 68px;
  color: #333;
  background-color: rgb(242, 244, 255);
  border-radius: 8px;
  margin-top: 15px;
  margin-left: -16px;
  width: 113%;
  padding-right: 2px;
}

.floorplans-swiper-card-text-top
  .floorplans-swiper-card-text-top-in-card
  .floorplans-swiper-card-text-top-in-card-text
  h2 {
  font-size: 14px;
  line-height: 12px;
  font-weight: 700;
  color: #445578;
  display: block;
  text-align: left;
  padding-top: 10px;
}

.floorplans-swiper-card-text-top
  .floorplans-swiper-card-text-top-in-card
  .floorplans-swiper-card-text-top-in-card-text
  h4 {
  font-size: 12px;
  line-height: 19px;
  font-weight: 500;
  color: #445578;
  margin-top: 2px;
  padding-bottom: 5px;
}

.floorplans-swiper-card-text-top
  .floorplans-swiper-card-text-top-in-card
  .floorplans-swiper-card-text-top-in-card-text {
  padding: 10px;
}

.floorplans-swiper-card-text-top .floorplans-swiper-card-text-top-in-card-end {
  display: flex;
  justify-content: space-between;
}

.floorplans-swiper-card-text-top
  .floorplans-swiper-card-text-top-in-card-end
  div
  h2 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #0078db;
  text-align: left;
  vertical-align: middle;
}

/* ==================== FloorPlansSwiper End ======================= */

/* ==================== SellersMayContact Start ======================= */

.sellermay {
  margin-top: 40px;
}

.sellermay .sellermay-head-pills {
  display: flex;
  margin-top: 15px;
  margin-bottom: 30px;
}

.sellermay .sellermay-head-pills div {
  margin-right: 10px;
}

.sellermay-head {
  display: flex;
  justify-content: space-between;
}

.sellermay h1 {
  font-weight: bold;
  color: #144273;
  font-size: 20px;
  text-align: left;
  font-weight: 600;
}

.sellermay h4 {
  font-weight: bold;
  color: #0078db;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}

.sellermay .sellermay-head-pills .active-pill h3 {
  border: 1px solid #a3daff;
  color: #091e42;
  font-weight: 600;
  background: #f0f9ff;
  width: auto;
  max-width: unset;
}

.sellermay .sellermay-head-pills div h3 {
  border: 1px solid rgba(128, 128, 128, 0.445);
  padding: 5px 10px 5px 10px;
  border-radius: 30px;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.sellermay-swiper-card {
  justify-content: center;
  align-content: center;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 184px;
  height: 136px;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
}

.sellermay-swiper-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.sellermay-swiper-card .sellermay-swiper-card-text div h3 {
  font-size: 12px;
  line-height: 16px;
  margin-top: -25px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.sellermay-swiper-card .sellermay-swiper-card-text div p {
  width: 100%;
  height: 60px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.sellermay-swiper-card .sellermay-swiper-card-bottom-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #0078db;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.sellermay-swiper-card-bottom-text {
  border-radius: 25px;
  border: 1px solid #0078db;
  padding: 4px 12px 4px 12px;
  display: inline-block !important;
  height: 25px;
}

.sellermay-swiper-card .sellermay-swiper-card-text-img {
  width: 60px;
  height: 34px;
  position: relative;
  top: -30px;
  border: 1px solid #f4f5f7;
}

.sellermay-swiper-card-text-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ==================== SellersMayContact End ======================= */

/*================== ProgressBar ============== */

.horizontal-slider {
  width: 95%;
}

.horizontal-slider .example-track {
  top: 14px;
  height: 6px;
}

.example-track.example-track-0 {
  background: rgba(200, 198, 198, 0.853);
}

.example-track.example-track-1 {
  background: #3270fc;
}

.example-track.example-track-2 {
  background: rgba(200, 198, 198, 0.853);
}

.horizontal-slider .example-thumb {
  top: 10px;
  width: 18px;
  height: 14px;
  line-height: 44px;
  border-radius: 50%;
  font-size: 0.5em;
  text-align: center;
  font-weight: 500;
  background-color: #3270fc;
  color: #000;
  cursor: pointer;
  border: 1px solid gray;
  box-sizing: border-box;
}

/* ==================== Updated CSS HBanner Start ====================== */
.banner {
  height: 460px !important;
}

.inPageSearchBox__inPageSearchBox {
  width: max-content;
  margin: 0 auto;
  position: absolute;
  top: 280px;
  left: 0;
  right: 0;
  z-index: 40;
}

.hero-title .hero-title_small1 {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  text-shadow: 1px 1px #0078db87;
}

.hero-title h2 {
  color: #fff;
  top: 50px;
  font-size: 35px;
  line-height: 35px;
  text-align: center;
  font-weight: 600;
  text-shadow: 1px 1px #0078db87;
}

@media screen and (max-width: 420px) and (min-width: 360px) {
  .banner {
    height: 350px !important;
  }

  .inPageSearchBox__inPageSearchBox {
    width: 97%;
    margin: 0 auto;
    position: absolute;
    top: 236px !important;
    left: 0;
    right: 0;
    z-index: 40;
  }

  .hero-title .hero-title_small1 {
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-top: 0px;
  }

  .hero-title h2 {
    color: #fff;
    top: 10px;
    font-size: 20px !important;
    line-height: 25px !important;
    text-align: center;
    font-weight: 600;
  }

  .mainsearchcont .searchcont_box {
    width: 100%;
    display: flex;
    padding: 10px;
  }
  .searchcont_box .searchcont_leftbox {
    width: 25%;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 8px;
  }
  .font-style {
    font-size: 6px;
    font-weight: 600;
    color: #555;
  }
  .heading {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
  }
  .verified-property {
    width: 35px;
  }
  .input-box-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .budget-filter {
    font-size: 6px;
  }
  .slider-box div {
    font-size: 8px;
  }
  .searchcont_rightbox {
    width: 75% !important;
    height: 1960px !important;
    overflow-y: scroll;
    padding: 1%;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 520px) and (min-width: 421px) {
  .banner {
    height: 350px !important;
  }
  .inPageSearchBox__inPageSearchBox {
    width: 97%;
    margin: 0 auto;
    position: absolute;
    top: 239px !important;
    left: 0;
    right: 0;
    z-index: 40;
  }

  .hero-title .hero-title_small1 {
    font-size: 10px;
    font-weight: 600;
    text-align: center;
    color: #fff;
  }

  .hero-title h2 {
    color: #fff;
    top: 10px;
    font-size: 20px !important;
    line-height: 25px !important;
    text-align: center;
    font-weight: 600;
  }
  .mainsearchcont .searchcont_box {
    width: 100%;
    display: flex;
    padding: 10px;
  }
  .searchcont_box .searchcont_leftbox {
    width: 25%;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 12px;
  }
  .font-style {
    font-size: 8px;
    font-weight: 600;
    color: #555;
  }
  .heading {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
  }
  .verified-property {
    width: 35px;
  }
  .input-box-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .searchcont_rightbox {
    width: 75% !important;
    height: 2135px !important;
    overflow-y: scroll;
    padding: 1%;
    display: flex;
    flex-direction: column;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
  .checkbox-text {
    font-size: 6px !important;
  }
  .m-10 {
    margin: 3px;
    font-size: 6px;
  }
  .flex-start {
    display: flex;
    /* justify-content: ; */
    align-items: center;
    margin: 2px;
  }
  .budget-filter {
    font-size: 8px;
  }
  .option-bg-color {
    background-color: #fff;
    width: 80px;
    border: 1px solid #eee;
    font-size: 6px;
    color: #555;
    padding: 2px;
    margin: 2px;
    border-radius: 20px;
  }
  .search_propertybox_cnt .price-quote div {
    border-right: 1px solid #ddd;
    padding-right: 0px;
    font-size: 6px;
    color: "Poppins";
    font-weight: 400;
  }
  .search_propertybox_cnt .price-quote div.text-bold {
    font-weight: 600;
    color: #000;
    font-size: 6px;
  }
}

@media screen and (max-width: 720px) and (min-width: 521px) {
  .banner {
    height: 370px !important;
  }

  .inPageSearchBox__inPageSearchBox {
    width: max-content;
    margin: 0 auto;
    position: absolute;
    top: 248px !important;
    left: 0;
    right: 0;
    z-index: 40;
  }

  .hero-title .hero-title_small1 {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-bottom: 5px;
  }

  .hero-title h2 {
    color: #fff;
    top: 10px;
    font-size: 24px !important;
    line-height: 30px !important;
    text-align: center;
    font-weight: 600;
  }
  .brand-promotion-leftpanel {
    margin-right: 30px;
  }
  .mainsearchcont .searchcont_box {
    width: 100%;
    display: flex;
    padding: 10px;
  }
  .searchcont_box .searchcont_leftbox {
    width: 25%;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 12px;
  }
  .font-style {
    font-size: 8px;
    font-weight: 600;
    color: #555;
  }
  .heading {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0px;
  }
  .verified-property {
    width: 35px;
  }
  .input-box-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .searchcont_rightbox {
    width: 75% !important;
    height: 1960px !important;
    overflow-y: scroll;
    padding: 1%;
    display: flex;
    flex-direction: column;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
  .checkbox-text {
    font-size: 8px !important;
  }
  .m-10 {
    margin: 3px;
    font-size: 8px;
  }
  .flex-start {
    display: flex;
    /* justify-content: ; */
    align-items: center;
    margin: 2px;
  }
  .option-bg-color {
    background-color: #fff;
    width: 80px;
    border: 1px solid #eee;
    font-size: 8px;
    color: #555;
    padding: 4px;
    margin: 2px;
    border-radius: 20px;
  }
}

@media screen and (max-width: 960px) and (min-width: 721px) {
  .banner {
    max-height: 383px !important;
  }

  .inPageSearchBox__inPageSearchBox {
    width: max-content;
    margin: 0 auto;
    position: absolute;
    top: 260px !important;
    left: 0;
    right: 0;
    z-index: 40;
  }

  .hero-title .hero-title_small1 {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
  }

  .hero-title h2 {
    color: #fff;
    top: 10px;
    font-size: 24px !important;
    line-height: 30px !important;
    text-align: center;
    font-weight: 600;
  }
  .postProperty-Review {
    margin: 20px 120px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .mainsearchcont .searchcont_box {
    width: 100%;
    display: flex;
    padding: 1%;
  }
  .searchcont_box .searchcont_leftbox {
    width: 25%;
    padding: 0px;
    background-color: #ffffff;
    border-radius: 10px;
    font-size: 12px;
  }
  .font-style {
    font-size: 10px;
    font-weight: 600;
    color: #555;
  }
  .heading {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
  }
  .verified-property {
    width: 45px;
  }
  .input-box-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  .searchcont_rightbox {
    width: 75% !important;
    height: 2278px !important;
    overflow-y: scroll;
    padding: 1%;
    display: flex;
    flex-direction: column;
  }

  .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
  .checkbox-text {
    font-size: 12px !important;
  }
  .m-10 {
    margin: 8px;
  }
  .flex-start {
    display: flex;
    /* justify-content: ; */
    align-items: center;
    margin: 5px;
  }
  .option-bg-color {
    background-color: #fff;
    width: 120px;
    border: 1px solid #eee;
    font-size: 12px;
    color: #555;
    padding: 4px;
    margin: 2px;
    border-radius: 20px;
  }
}

@media screen and (max-width: 1080px) and (min-width: 961px) {
  .banner {
    height: 462px !important;
  }
  .inPageSearchBox__inPageSearchBox {
    width: max-content;
    margin: 0 auto;
    position: absolute;
    top: 342px;
    left: 0;
    right: 0;
    z-index: 40;
  }

  .hero-title .hero-title_small1 {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-bottom: 30px !important;
    margin-top: 10px;
  }

  .hero-title h2 {
    color: #fff;
    top: 10px;
    font-size: 28px !important;
    line-height: 38px !important;
    text-align: center;
    font-weight: 600;
  }
}

/* ==================== Updated CSS HBanner End ====================== */

/* ==================== RecentelySearched Start ====================== */

.recently-searched-main {
  width: 960px;
  margin: auto;
  position: relative;
}

.recently-searched-main .searched-active-page-tabs .active-page-tab-child {
  padding: 8px 8px 14px 8px;
}

.searched-active-page-tabs .tab_child.active {
  font-weight: 700;
  color: #3270fc;
  cursor: pointer;
  border-bottom: 3px solid #3270fc;
}

.recently-searched-main .searched-active-page-tabs .page-tab-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  justify-content: left;
}

.recently-searched-main .searched-active-page-tabs div {
  display: inline-block;
  margin-right: 24px;
  cursor: pointer;
}

.recently-searched-active-page .searched-active-page-tabs {
  position: relative;
  z-index: 2;
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  background: #fff;
  border-bottom: 1px solid #f4f5f7;
  padding-right: 48%;
}

.searched-active-page-content {
  padding-top: 100px;
  padding-bottom: 50px;
}

.active-page-search-wrap {
  width: 850px;
  margin: auto;
  position: relative;
}

.page-tab-text-bold {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #041533 !important;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 30px;
}

.page-tab-subtext-box {
  width: 100%;
  cursor: pointer;
  padding: 24px 32px 24px 24px;
  background-color: #fff;
  border: 1px solid #ebecf0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  margin-bottom: 15px;
}

.page-tab-subtext-box i {
  width: 24px;
  height: 24px;
  background-size: 408px 287px;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;
  color: #0078db;
  font-size: 20px;
}

.page-tab-subtext-box .page-tab-subtext-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #041533 !important;
  display: block;
  text-align: left;
  vertical-align: middle;

  width: 90%;
  padding-right: 25%;
}

.active-page-search-wrap .spacer {
  height: 10vh;
}

/* ==================== RecentelySearched End ====================== */
/* ==================== PostProperty Start ====================== */

.post-property-main-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 72px;
  margin: auto;
  max-width: 1360px;
  position: relative;
}

.pp-firstpage {
  display: contents;
}

.pp-firstpage-graybox {
  position: absolute;
  width: 100%;
  bottom: -64px;
  background: #fff;
}

.top-in-mainwrapper {
  width: 1265px;
  margin: 32px auto auto;
}

.top-in-first-mainwrapper {
  width: 100%;
  margin-bottom: -25px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-radius: 30px;
}

#mainwrapper_gradiant {
  background-image: linear-gradient(180deg, #fff, #cfe7f7c5);
}

.top-in-first-mainwrapper-1 {
  /* position: fixed; */
  margin-top: 0px;
  max-width: 620px;
  padding: 20px;
}

.top-in-first-mainwrapper-1 div {
  text-align: center;
}

.top-in-first-mainwrapper-1 .pp-inhead {
  margin-bottom: 16px;
}

.top-in-first-mainwrapper-1 div div {
  max-width: 485px;
}

.pp-inhead .pp-inhead-bold {
  font-size: 32px;
  /* line-height: 48px; */
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.pp-blue-text {
  color: #0078db;
}

.pp-in-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.pp-in-container .ppt-green-tick {
  display: inline-block;
  margin: 6px 15px 0 4px;
  /* -webkit-transform: rotate(45deg); */
  transform: rotate(45deg);
  height: 10px;
  width: 5px;
  border-bottom: 1px solid #28ad35;
  border-right: 1px solid #28ad35;
}

.pp-in-container .ppt-container-subtext {
  font-size: 18px;
  line-height: 24px;
  color: #131313;
  font-weight: 600;
}

.pp-in-container .ppt-important {
  color: #0078db;
  margin-top: 2px;
  margin-left: 3px;
}

.top-in-first-mainwrapper-1 img {
  margin-left: -40px;
  margin-top: -72px;
}

.ppt-bg-img {
  width: 850px !important;
  height: 565px;
}

.ppt-btm-text {
  margin-top: -30px;
  position: absolute;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #8993a4;
}

.ppt-dummy-left {
  max-width: 620px;
}

.top-in-first-mainwrapper .top-in-mainwrapper-form-section {
  background-color: #fff !important;
  box-shadow: 0 8px 12px rgba(31, 92, 163, 0.2);
  flex: 424px 0;
  margin-top: 12px;
  border: 1px solid #ebecf0;
  border-radius: 8px;
  padding: 24px;
  margin-right: 30px;
}

.top-in-mainwrapper-form-section div {
  margin-bottom: 20px;
}

.mainwrapper-form-section-bold {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.mainwrapper-form-section-semibold {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.ppt-card-step1 {
  margin-bottom: 20px;
}

.step1-inner-boldtext {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 12px;
}

.ppt-card-step1-component {
  text-align: left;
}

.ppt-card-step1-component-items div {
  display: inline-flex;
}

.ppt-card-step1-component-items {
  display: inline-flex;
  margin-bottom: 12px;
  align-items: start;
}

#ppt {
  order: none;
  background: transparent;
  padding: 6px 16px;
  margin: 6px;
}

#ppt:hover {
  background-color: rgba(211, 211, 211, 0.371);
}

.ppt-card-step1-component-items .page-component-ppt {
  border: 1px solid #85868655;
  border-radius: 20px;
  color: #091e42;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
  outline: none;
}

.ppt-card-step1-component-items .page-component-ppt.active {
  border: 1px solid #a3daff;
  color: #091e42;
  font-weight: 600;
  background: #f0f9ff;
  border-radius: 20px;
}

.ppt-card-step2-component .super-property-boldtext {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

#ppt-input {
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  bottom: 2px;
}

.super-property-component-in div {
  display: inline;
  margin-right: 20px;
}

.super-property-component-in div input {
  margin-right: 10px;
}

.super-property-component-in {
  display: flex;
}

[design="system"] [type="radio"]:not(:checked) {
  position: absolute;
  display: none;
}

.tags-wrapper-ppt {
  display: inline-block;
}

.tags-wrapper-ppt div {
  margin-right: 8px;
  margin-bottom: 8px;
  display: inline-flex;
  align-items: center;
}

.multiple-input {
  margin-bottom: 12px;
}

.super-property-component-mainwrap {
  text-align: left;
}

.radio-input-text-only {
  box-shadow: "";
  background: #fff;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  outline: none;
  margin: 0;
  cursor: pointer;
  border: 1px solid #ebecf0;
  padding: 4px 12px 2px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: #42526e;
  text-align: center;
  vertical-align: middle;
}

.radio-input-text-only:hover {
  background-color: rgba(224, 222, 222, 0.586);
}

.hyperlinks-medium-ppt {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #0078db;
  /* display: block; */
  text-align: left;
  vertical-align: middle;
}

.hyperlinks-medium-ppt i {
  width: 16px;
  height: 16px;
  background-size: 259.33px 126.66px;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;
}

.ppt-button-button .button-ppt {
  width: calc(100% - 26px);
  margin: auto;
  display: block;
  text-align: center;
}

.button-ppt .button-semi-1 {
  font-weight: 600;
  width: 100%;

  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: #0078db;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  cursor: pointer;
  border: 0;
  padding: 16px 16px;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  vertical-align: middle;
}

.details-knowmore {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 64px;
  cursor: pointer;
}

.details-knowmore-inner {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  padding: 9px 9px 9px 18px;
}

.caption-strong-large {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.postPropSteps_ppfSteps {
  margin-bottom: 64px;
}

.postPropSteps_stepsTitle {
  width: 500px;
  margin: auto;
}

.postPropSteps_stepsTitle div {
  text-align: center;
}

.input_label_inactive {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #8993a4;
  display: block;
  vertical-align: middle;
  margin-bottom: 10px;
}

.display_xxl_bold {
  font-size: 40px;
  line-height: 56px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.postPropSteps_stepsCol {
  display: flex;
}

.postPropSteps_stepsCol div {
  width: 100%;
  padding: 32px;
  text-align: left;
}

.postPropSteps_stepsCol div img {
  width: 48px;
  height: 48px;
  object-fit: contain;
}

.title_semiBold {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.postPropSteps_stepsCol div span {
  display: inline;
}

.postPropSteps_blueText {
  color: #0078db;
}

.caption_strong_large {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.postPropSteps_btnCont {
  text-align: center;
}

.buttons_primaryRegular {
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: #0078db;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  margin: 0;
  cursor: pointer;
  border: 0;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.visitorsBanner_visitorBanner {
  width: 100%;
  padding: 40px;
  margin-bottom: 20px;
}

.visitorsBanner_visitorBanner .visitorsBanner_visBannerImg img {
  width: 100%;
  height: 345px;
}

.visitorsBanner_visitorBanner .visitorsBanner_bannerCont {
  width: 995px;
  padding: 75px 95px;
  background: #fff;
  margin: -200px auto auto;
  position: relative;
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
}

.visitorsBanner_bannerCont .display_l_bold {
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.display_l_bold span {
  color: #0078db;
}

.visitorsBanner_bannerCont .banner-spacer {
  height: 10vh;
}

.visitorsBanner_bannerCont .visitorsBanner_bannerContCol {
  display: flex;
}

.visitorsBanner_bannerContCol div {
  width: 33.33%;
}

.input_label_inactive {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.additionalBenefits_addBenPartition div h2 {
  margin-bottom: 20px;
}

.display_xxl_semiBold {
  font-size: 40px;
  line-height: 56px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.caption_strong_large {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.additionalBenefits_addBenefits {
  padding: 40px;
  margin-bottom: 10px;
}

.additionalBenefits_addBenPartition {
  width: 100%;
}

.additionalBenefits_listings .caption_strong_large {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 20px;
}

.caption-spacer {
  height: 5vh;
}

.buttons_additionalBenefits {
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: #0078db;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  outline: none;
  margin: 0;
  cursor: pointer;
  border: 0;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  align-items: start;
  text-align: center;
  vertical-align: middle;
  margin-left: -980px;
}

.accordion_acco_wrapper {
  width: 100%;
  text-align: left;
}

.accordion_content_accord_container {
  border-bottom: 1px solid #ebecf0;
}

.accordion_content_accord_cont {
  display: flex;
  justify-content: space-between;
}

.accordion_content_accord_cont i {
  font-size: 20px;
  color: #0078db;
}

.accordion_content_accord_head {
  padding: 16px 0;
  cursor: pointer;
}

.accordion_content_openAccordionContent {
  display: block;
}

.accordion_content_accord_inner {
  padding: 0 0 16px;
}

.accordion_content_accord_inner p {
  color: #000000cf;
}

.owners-dealers {
  margin-bottom: 20px;
}

.ownersanddealers-p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.intresting-reades-ppt {
  padding: 40px;
}

.owners-dealers-ppt {
  padding: 40px;
}

/* ==================== PostProperty End ====================== */

/* ==================== ViewedProjects End ====================== */

.viewed-projects {
  margin-top: 100px;
  margin-left: 48px;
}

.viewed-projects-head {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.viewed-projects-head h1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.viewed-projects-head p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #42526e;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.viewed-projects-swiper-card .viewed-projects-swiper-card-text-top {
  text-align: left;
}

.viewed-projects-swiper-card-text-top .viewed-projects-swiper-card-text-top-in {
  height: 160px;
  position: relative;
}

.viewed-projects-swiper-card-text-top
  .viewed-projects-swiper-card-text-top-in
  img {
  width: 100%;
  height: 185px;
  object-fit: cover;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 4px;
  -webkit-border-radius: 4px;
}

.viewed-projects-card-img-tags .viewed-projects-card-img-tags-holder {
  position: absolute;
  left: 10px;
  top: 150px;
}

.viewed-projects-card-img-tags div {
  display: inline-block;
  vertical-align: middle;
}

.viewed-projects-card-img-tags .img-tick-icon {
  border-radius: 3px;
  padding: 0px 10px 0px 10px;
}

.viewed-projects-card-img-tags .img-tick-icon .img-tick-icon-text {
  color: #000;
  font-size: 14px;
  font-weight: bold;
  margin-top: -5px;
}

.img-tick-icon-star {
  position: absolute;
  left: 10px;
  top: 150px;
}

.img-tick-icon {
  background: #fff;
}

.card-img-text {
  position: absolute;
  bottom: -20px;
  color: #fff;
  width: 100%;
  z-index: 1;
  padding: 0 12px;
  font-size: 10px;
}

.viewed-projects-card-text-top-in-img i {
  width: 24px;
  height: 24px;
  background-size: 408px 287px;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;

  top: 12px;
  right: 12px;
  position: absolute;
  color: #fff;
}

.viewed-projects-swiper-card-text-top
  .viewed-projects-swiper-card-text-top-in1 {
  display: inline-flex;
  margin-top: 30px;
}

.viewed-projects-swiper-card-text-top
  .viewed-projects-swiper-card-text-top-in1
  a {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #091e42;
  text-align: left;
  vertical-align: middle;
}

.viewed-projects-swiper-card-text-top
  .viewed-projects-swiper-card-text-top-in1
  p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.viewed-projects-swiper-card-text-top
  .viewed-projects-swiper-card-text-top-in1
  div {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
  margin-top: 10px;
}

.viewed-projects-swiper-card {
  /* border: 1px solid #EBECF0; */
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 16px 20px 16px;
  height: 100% !important;
  position: relative;
  width: 322px;
}

.viewed-projects-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

/* ==================== ViewedProjects End ====================== */

/* ==================== ShortListed Start ====================== */

.shortlisted-outer {
  margin: 20px;
}

.shortlisted-spacer {
  height: 20vh;
}

.shortlisted-image {
  text-align: center !important;
  margin-bottom: 30px;
}

.shortlisted-image img {
  width: 228px;
  height: 228px;
  object-fit: contain;
}

.shortlisted-text {
  color: #010c1f !important;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  display: block;
  vertical-align: middle;
}

.shortlisted-subtext {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #010c1f !important;
  display: block;
  text-align: center;
  vertical-align: middle;
}

/* ==================== ShortListed End ====================== */

/* ==================== Contacted Start ====================== */

.contacted-outer {
  margin: 20px;
}

.contacted-spacer {
  height: 20vh;
}

.contacted-image {
  text-align: center !important;
  margin-bottom: 30px;
}

.contacted-image img {
  width: 228px;
  height: 228px;
  object-fit: contain;
}

.contacted-text {
  color: #010c1f !important;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  display: block;
  vertical-align: middle;
}

.contacted-subtext {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #010c1f !important;
  display: block;
  text-align: center;
  vertical-align: middle;
}

/* ==================== Contacted End ====================== */

/* =========MODEL POPUP START ========== */
.modalmain {
  background: rgba(152, 151, 151, 0.226) !important;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  scroll-behavior: smooth;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalmainnew {
  background: rgba(152, 151, 151, 0.226) !important;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  scroll-behavior: smooth;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalmainPopUp {
  background: rgba(152, 151, 151, 0.226) !important;
  opacity: 1;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  scroll-behavior: smooth;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalmain .model-wrapper {
  background: #fff;
  width: 1000px;
  height: 500px;
  top: 90px;
  left: 150px;
  right: 150px;
  position: absolute;
  border-radius: 5px;
  padding: 20px;
}

.modalmainnew .model-wrappernew {
  background: #fff;
  width: 1000px;
  height: 500px;
  top: 90px;
  left: 150px;
  right: 150px;
  position: absolute;
  border-radius: 5px;
  padding: 20px;
}
.modalmainPopUp .model-wrapperPopUp {
  background: #fff;
  /* width: 1000px;
  height: 500px; */
  top: 30px;
  left: 150px;
  right: 150px;
  position: absolute;
  border-radius: 5px;
  /* padding: 20px; */
}

.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-header button {
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
  background: transparent;
  color: #000;
  border: none;
}

.modal-header button:after {
  display: inline-block;
  content: "🗙";
  /*  \00d7    */
  /* This will render the 'X' */
  font-size: 25px;
  color: #000;
  /* z-index: 999999; */
}

.model-container {
  padding: 20px !important;
}

.head-one {
  margin-bottom: 5px;
}

.head-one h4 {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  text-align: left !important;
}

.head-two {
  margin-bottom: 5px;
}

.head-two h4 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 600;
  color: #091e42;
  text-align: left !important;
}

.model-container-main {
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px;
}

.main-top {
  margin-right: 200px;
}

.main-bottom {
  margin-right: 70px;
  text-align: left;
}

.model-container-inner .main-head-posted {
  font-size: 12px;
  color: black;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left !important;
}

.model-container-inner-one h3 {
  font-size: 14px;
  color: black;
  margin-bottom: 10px;
  text-align: left;
}

.model-container-inner1 h4 {
  font-size: 14px;
  color: #3e4249e0;
  text-align: left;
  margin-bottom: 5px;
}

.model-container-inner1 p {
  font-size: 12px;
  color: #3e4249e0;
  text-align: left;
}

.model-container-main-one {
  display: flex;
  justify-content: space-between;
}

.model-container-inner-one .main-head-posted {
  font-size: 12px;
  color: black;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

.model-container-inner2 h4 {
  font-size: 10px;
  color: #282c34;
  text-align: left;
}

.model-container-inner2 p {
  font-size: 10px;
  color: #282c34b2;
  text-align: left;
}

.modal-header button {
  width: 30px;
  height: 30px;
  color: #61dafb;
}

.inner2-1 {
  margin-bottom: 10px;
}

.inner2-1 label {
  margin-right: 30px;
}

.inner2-1 span {
  margin-right: 10px;
  font-size: 14px;
  color: #3e4249e0;
  text-align: left;
}

.inner2-1 span input {
  margin-right: 5px;
}

.inner2-2 {
  margin-bottom: 10px;
}

.inner2-2 label {
  margin-right: 30px;
}

.inner2-2 span {
  margin-right: 10px;
  font-size: 14px;
  color: #3e4249e0;
  text-align: left;
}

.inner2-2 span input {
  margin-right: 5px;
}

.inner2-3 div {
  padding: 10px;
  margin-bottom: 20px;
}

.inner2-3 .form-inputs {
  border-bottom: 1px solid rgba(128, 128, 128, 0.49);
}

.form-inputs a {
  margin-right: 30px;
}

.form-inputs a select {
  border: none;
}

.inner2-3 .form-inputs input {
  font-size: 14px;
  width: 80%;
  border: none;
}

.model-container-inner21 {
  margin-bottom: 20px;
}

.model-container-inner21 .form-radios {
  margin-top: 5px;
}

.model-container-inner21 .form-radios span {
  margin-right: 25px;
  font-size: 14px;
  color: #3e4249e0;
  text-align: left;
}

.model-container-inner21 .form-radios span input {
  margin-right: 5px;
}

.model-container-inner22 div {
  margin-bottom: 15px;
  font-size: 14px;
  color: #3e4249e0;
}

.model-container-inner22 div input {
  margin-right: 5px;
}

.inner25-button {
  width: 100%;
  height: 40px;
  font-size: 14px;
  max-width: 260px;
  margin-top: 10px;
  background-color: #3498db;
  border-color: #3498db;
  color: #fff;
  border-radius: 3px;
}

/* ===== Media Queryes for Model =====*/

@media screen and (max-width: 420px) and (min-width: 360px) {
  .modalmain {
    background: rgba(126, 126, 126, 0.6) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalmain .model-wrapper {
    background: #fff;
    width: 300px;
    height: 700px;
    top: 100px;
    right: 25px;
    left: 25px;
    position: absolute;
    border-radius: 5px;
    padding: 10px;
    display: block;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    color: #000;
    border: none;
  }

  .modal-header button:after {
    display: inline-block;
    content: "\00d7";
    /* This will render the 'X' */
    font-size: 30px;
    color: #000;
  }

  /* .model-container{
    padding: 20px !important;
  } */

  .head-one {
    margin-top: 30px;
    margin-bottom: 3px;
  }

  .head-one h4 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .head-two {
    margin-bottom: 3px;
  }

  .head-two h4 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .model-container-main {
    display: flex !important;
    justify-content: space-between !important;
    padding: 5px;
  }

  .main-top {
    margin-right: -30px;
  }

  .main-bottom {
    margin-right: 0px;
    text-align: left;
  }

  .model-container-inner .main-head-posted {
    font-size: 10px;
    color: black;
    margin-bottom: 3px;
    font-weight: bold;
    text-align: left !important;
  }

  .model-container-inner-one h3 {
    font-size: 12px;
    color: black;
    margin-bottom: 10px;
    text-align: left;
  }

  .model-container-inner1 h4 {
    font-size: 12px;
    color: #3e4249e0;
    text-align: left;
    margin-bottom: 3px;
  }

  .model-container-inner1 p {
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-main-one {
    display: flex;
    justify-content: space-between;
  }

  .model-container-inner-one .main-head-posted {
    font-size: 10px;
    color: black;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
  }

  .model-container-inner2 h4 {
    font-size: 12px;
    color: #282c34;
    text-align: left;
  }

  .model-container-inner2 p {
    font-size: 10px;
    color: #282c34b2;
    text-align: left;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    color: #61dafb;
  }

  .inner2-1 {
    margin-bottom: 10px;
  }

  .inner2-1 label {
    margin-right: 10px;
    font-size: 12px;
  }

  .inner2-1 span {
    margin-right: 10px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-1 span input {
    margin-right: 3px;
  }

  .inner2-2 {
    margin-bottom: 10px;
  }

  .inner2-2 label {
    margin-right: 15px;
    font-size: 12px;
  }

  .inner2-2 span {
    margin-right: 10px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-2 span input {
    margin-right: 5px;
  }

  .inner2-3 div {
    padding: 10px;
    margin-bottom: 20px;
  }

  .inner2-3 .form-inputs {
    border-bottom: 1px solid rgba(128, 128, 128, 0.49);
    width: 100px;
  }

  .form-inputs a {
    margin-right: 20px;
  }

  .form-inputs a select {
    border: none;
  }

  .inner2-3 .form-inputs input {
    font-size: 12px;
    width: 80%;
    border: none;
  }

  .model-container-inner21 {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .model-container-inner21 .form-radios {
    margin-top: 3px;
  }

  .model-container-inner21 .form-radios span {
    margin-right: 25px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-inner21 .form-radios span input {
    margin-right: 3px;
  }

  .model-container-inner22 div {
    margin-bottom: 15px;
    font-size: 12px;
    color: #3e4249e0;
  }

  .model-container-inner22 div input {
    margin-right: 5px;
  }

  .inner25-button {
    width: 100%;
    height: 40px;
    font-size: 10px;
    max-width: 260px;
    margin-top: 10px;
    background-color: #3498db;
    border-color: #3498db;
    color: #fff;
    border-radius: 3px;
  }

  .scrollable-content {
    overflow-y: auto;
  }
  .postProperty-Review {
    margin: 8px 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .postProperty-Review button {
    width: 80px;
  }
}

@media screen and (max-width: 520px) and (min-width: 420px) {
  .modalmain {
    background: rgba(126, 126, 126, 0.766) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalmain .model-wrapper {
    background: #fff;
    width: 300px;
    height: 750px;
    top: 100px;
    left: 50px;
    right: 30px;
    position: absolute;
    border-radius: 5px;
    padding: 20px;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    color: #000;
    border: none;
  }

  .modal-header button:after {
    display: inline-block;
    content: "\00d7";
    /* This will render the 'X' */
    font-size: 30px;
    color: #000;
  }

  .head-one {
    margin-top: 20px;
    margin-bottom: 3px;
  }

  .head-one h4 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .head-two {
    margin-bottom: 3px;
  }

  .head-two h4 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .model-container-main {
    display: flex !important;
    justify-content: space-between !important;
    padding: 5px;
  }

  .main-top {
    margin-right: -30px;
  }

  .main-bottom {
    margin-right: 0px;
    text-align: left;
  }

  .model-container-inner .main-head-posted {
    font-size: 10px;
    color: black;
    margin-bottom: 3px;
    font-weight: bold;
    text-align: left !important;
  }

  .model-container-inner-one h3 {
    font-size: 12px;
    color: black;
    margin-bottom: 10px;
    text-align: left;
  }

  .model-container-inner1 h4 {
    font-size: 12px;
    color: #3e4249e0;
    text-align: left;
    margin-bottom: 3px;
  }

  .model-container-inner1 p {
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-main-one {
    display: flex;
    justify-content: space-between;
  }

  .model-container-inner-one .main-head-posted {
    font-size: 10px;
    color: black;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
  }

  .model-container-inner2 h4 {
    font-size: 12px;
    color: #282c34;
    text-align: left;
  }

  .model-container-inner2 p {
    font-size: 10px;
    color: #282c34b2;
    text-align: left;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    color: #61dafb;
  }

  .inner2-1 {
    margin-bottom: 10px;
  }

  .inner2-1 label {
    margin-right: 10px;
    font-size: 12px;
  }

  .inner2-1 span {
    margin-right: 10px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-1 span input {
    margin-right: 3px;
  }

  .inner2-2 {
    margin-bottom: 10px;
  }

  .inner2-2 label {
    margin-right: 15px;
    font-size: 12px;
  }

  .inner2-2 span {
    margin-right: 10px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-2 span input {
    margin-right: 5px;
  }

  .inner2-3 div {
    padding: 10px;
    margin-bottom: 20px;
  }

  .inner2-3 .form-inputs {
    border-bottom: 1px solid rgba(128, 128, 128, 0.49);
    width: 100px;
  }

  .form-inputs a {
    margin-right: 20px;
  }

  .form-inputs a select {
    border: none;
    font-size: 12px;
  }

  .inner2-3 .form-inputs input {
    font-size: 12px;
    width: 80%;
    border: none;
  }

  .model-container-inner21 {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .model-container-inner21 .form-radios {
    margin-top: 3px;
  }

  .model-container-inner21 .form-radios span {
    margin-right: 25px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-inner21 .form-radios span input {
    margin-right: 3px;
  }

  .model-container-inner22 div {
    margin-bottom: 15px;
    font-size: 12px;
    color: #3e4249e0;
  }

  .model-container-inner22 div input {
    margin-right: 5px;
  }

  .inner25-button {
    width: 100%;
    height: 40px;
    font-size: 10px;
    max-width: 260px;
    margin-top: 10px;
    background-color: #3498db;
    border-color: #3498db;
    color: #fff;
    border-radius: 3px;
  }

  .scrollable-content {
    overflow-y: auto;
  }
  .postProperty-Review {
    margin: 10px 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .postProperty-Review button {
    width: 100px;
  }
}

@media screen and (max-width: 720px) and (min-width: 520px) {
  .modalmain {
    background: rgba(126, 126, 126, 0.766) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalmain .model-wrapper {
    background: #fff;
    width: 400px;
    height: 600px;
    top: 100px;
    left: 60px;
    right: 60px;
    position: absolute;
    border-radius: 5px;
    padding: 20px;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    color: #000;
    border: none;
  }

  .modal-header button:after {
    display: inline-block;
    content: "\00d7";
    /* This will render the 'X' */
    font-size: 30px;
    color: #000;
  }

  .head-one {
    margin-top: 20px;
    margin-bottom: 3px;
  }

  .head-one h4 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .head-two {
    margin-bottom: 3px;
  }

  .head-two h4 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .model-container-main {
    display: flex !important;
    justify-content: space-between !important;
    padding: 5px;
  }

  .main-top {
    margin-right: -20px;
  }

  .main-bottom {
    margin-right: 0px;
    text-align: left;
  }

  .model-container-inner .main-head-posted {
    font-size: 10px;
    color: black;
    margin-bottom: 3px;
    font-weight: bold;
    text-align: left !important;
  }

  .model-container-inner-one h3 {
    font-size: 12px;
    color: black;
    margin-bottom: 10px;
    text-align: left;
  }

  .model-container-inner1 h4 {
    font-size: 12px;
    color: #3e4249e0;
    text-align: left;
    margin-bottom: 3px;
  }

  .model-container-inner1 p {
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-main-one {
    display: flex;
    justify-content: space-between;
  }

  .model-container-inner-one .main-head-posted {
    font-size: 10px;
    color: black;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
  }

  .model-container-inner2 h4 {
    font-size: 12px;
    color: #282c34;
    text-align: left;
  }

  .model-container-inner2 p {
    font-size: 10px;
    color: #282c34b2;
    text-align: left;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    color: #61dafb;
  }

  .inner2-1 {
    margin-bottom: 10px;
  }

  .inner2-1 label {
    margin-right: 10px;
    font-size: 12px;
  }

  .inner2-1 span {
    margin-right: 10px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-1 span input {
    margin-right: 3px;
  }

  .inner2-2 {
    margin-bottom: 10px;
  }

  .inner2-2 label {
    margin-right: 15px;
    font-size: 12px;
  }

  .inner2-2 span {
    margin-right: 10px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-2 span input {
    margin-right: 5px;
  }

  .inner2-3 div {
    padding: 10px;
    margin-bottom: 20px;
  }

  .inner2-3 .form-inputs {
    border-bottom: 1px solid rgba(128, 128, 128, 0.49);
    width: 100px;
  }

  .form-inputs a {
    margin-right: 20px;
  }

  .form-inputs a select {
    border: none;
    font-size: 12px;
  }

  .inner2-3 .form-inputs input {
    font-size: 12px;
    width: 80%;
    border: none;
  }

  .model-container-inner21 {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .model-container-inner21 .form-radios {
    margin-top: 3px;
  }

  .model-container-inner21 .form-radios span {
    margin-right: 25px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-inner21 .form-radios span input {
    margin-right: 3px;
  }

  .model-container-inner22 div {
    margin-bottom: 15px;
    font-size: 12px;
    color: #3e4249e0;
  }

  .model-container-inner22 div input {
    margin-right: 5px;
  }

  .inner25-button {
    width: 100%;
    height: 40px;
    font-size: 12px;
    max-width: 260px;
    margin-top: 10px;
    background-color: #3498db;
    border-color: #3498db;
    color: #fff;
    border-radius: 3px;
  }
  .postProperty-Review {
    margin: 15px 20px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .postProperty-Review button {
    width: 120px;
  }
}

@media screen and (max-width: 960px) and (min-width: 720px) {
  .modalmain {
    background: rgba(126, 126, 126, 0.766) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalmain .model-wrapper {
    background: #fff;
    width: 600px;
    height: 500px;
    top: 100px;
    left: 60px;
    right: 60px;
    position: absolute;
    border-radius: 5px;
    padding: 20px;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    color: #000;
    border: none;
  }

  .modal-header button:after {
    display: inline-block;
    content: "\00d7";
    /* This will render the 'X' */
    font-size: 30px;
    color: #000;
  }

  .head-one {
    /* margin-top: 20px; */
    margin-bottom: 3px;
  }

  .head-one h4 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .head-two {
    margin-bottom: 3px;
  }

  .head-two h4 {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .model-container-main {
    display: flex !important;
    justify-content: space-between !important;
    padding: 5px;
  }

  .main-top {
    margin-right: 80px;
  }

  .main-bottom {
    margin-right: 20px;
    text-align: left;
  }

  .model-container-inner .main-head-posted {
    font-size: 10px;
    color: black;
    margin-bottom: 3px;
    font-weight: bold;
    text-align: left !important;
  }

  .model-container-inner-one h3 {
    font-size: 12px;
    color: black;
    margin-bottom: 10px;
    text-align: left;
  }

  .model-container-inner1 h4 {
    font-size: 12px;
    color: #3e4249e0;
    text-align: left;
    margin-bottom: 3px;
  }

  .model-container-inner1 p {
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-main-one {
    display: flex;
    justify-content: space-between;
  }

  .model-container-inner-one .main-head-posted {
    font-size: 10px;
    color: black;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
  }

  .model-container-inner2 h4 {
    font-size: 12px;
    color: #282c34;
    text-align: left;
  }

  .model-container-inner2 p {
    font-size: 10px;
    color: #282c34b2;
    text-align: left;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    color: #61dafb;
  }

  .inner2-1 {
    margin-bottom: 10px;
  }

  .inner2-1 label {
    margin-right: 10px;
    font-size: 12px;
  }

  .inner2-1 span {
    margin-right: 10px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-1 span input {
    margin-right: 3px;
  }

  .inner2-2 {
    margin-bottom: 10px;
  }

  .inner2-2 label {
    margin-right: 15px;
    font-size: 12px;
  }

  .inner2-2 span {
    margin-right: 10px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-2 span input {
    margin-right: 5px;
  }

  .inner2-3 div {
    padding: 10px;
    margin-bottom: 20px;
  }

  .inner2-3 .form-inputs {
    border-bottom: 1px solid rgba(128, 128, 128, 0.49);
    width: 100px;
  }

  .form-inputs a {
    margin-right: 20px;
  }

  .form-inputs a select {
    border: none;
    font-size: 12px;
  }

  .inner2-3 .form-inputs input {
    font-size: 12px;
    width: 80%;
    border: none;
  }

  .model-container-inner21 {
    margin-bottom: 10px;
    font-size: 12px;
  }

  .model-container-inner21 .form-radios {
    margin-top: 3px;
  }

  .model-container-inner21 .form-radios span {
    margin-right: 25px;
    font-size: 10px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-inner21 .form-radios span input {
    margin-right: 3px;
  }

  .model-container-inner22 div {
    margin-bottom: 15px;
    font-size: 12px;
    color: #3e4249e0;
  }

  .model-container-inner22 div input {
    margin-right: 5px;
  }

  .inner25-button {
    width: 100%;
    height: 40px;
    font-size: 12px;
    max-width: 260px;
    margin-top: 10px;
    background-color: #3498db;
    border-color: #3498db;
    color: #fff;
    border-radius: 3px;
  }
  .postProperty-Review {
    margin: 10px 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .postProperty-Review button {
    width: 150px;
  }
}

@media screen and (max-width: 1080px) and (min-width: 960px) {
  .modalmain {
    background: rgba(126, 126, 126, 0.766) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalmain .model-wrapper {
    background: #fff;
    width: 800px;
    height: 550px;
    top: 100px;
    left: 120px;
    right: 120px;
    position: absolute;
    border-radius: 5px;
    padding: 20px;
  }

  .custom-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 10px;
    background: transparent;
    color: #000;
    border: none;
  }

  .modal-header button:after {
    display: inline-block;
    content: "\00d7";
    /* This will render the 'X' */
    font-size: 30px;
    color: #000;
  }

  .model-container {
    padding: 20px !important;
  }

  .head-one {
    margin-bottom: 5px;
  }

  .head-one h4 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .head-two {
    margin-bottom: 5px;
  }

  .head-two h4 {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    color: #091e42;
    text-align: left !important;
  }

  .model-container-main {
    display: flex !important;
    justify-content: space-between !important;
    padding: 10px;
  }

  .main-top {
    margin-right: 200px;
  }

  .main-bottom {
    margin-right: 70px;
    text-align: left;
  }

  .model-container-inner .main-head-posted {
    font-size: 12px;
    color: black;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left !important;
  }

  .model-container-inner-one h3 {
    font-size: 14px;
    color: black;
    margin-bottom: 10px;
    text-align: left;
  }

  .model-container-inner1 h4 {
    font-size: 14px;
    color: #3e4249e0;
    text-align: left;
    margin-bottom: 5px;
  }

  .model-container-inner1 p {
    font-size: 12px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-main-one {
    display: flex;
    justify-content: space-between;
  }

  .model-container-inner-one .main-head-posted {
    font-size: 12px;
    color: black;
    margin-bottom: 5px;
    font-weight: bold;
    text-align: left;
  }

  .model-container-inner2 h4 {
    font-size: 10px;
    color: #282c34;
    text-align: left;
  }

  .model-container-inner2 p {
    font-size: 10px;
    color: #282c34b2;
    text-align: left;
  }

  .modal-header button {
    width: 30px;
    height: 30px;
    color: #61dafb;
  }

  .inner2-1 {
    margin-bottom: 10px;
  }

  .inner2-1 label {
    margin-right: 30px;
  }

  .inner2-1 span {
    margin-right: 10px;
    font-size: 14px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-1 span input {
    margin-right: 5px;
  }

  .inner2-2 {
    margin-bottom: 10px;
  }

  .inner2-2 label {
    margin-right: 30px;
  }

  .inner2-2 span {
    margin-right: 10px;
    font-size: 14px;
    color: #3e4249e0;
    text-align: left;
  }

  .inner2-2 span input {
    margin-right: 5px;
  }

  .inner2-3 div {
    padding: 10px;
    margin-bottom: 20px;
  }

  .inner2-3 .form-inputs {
    border-bottom: 1px solid rgba(128, 128, 128, 0.49);
  }

  .form-inputs a {
    margin-right: 30px;
  }

  .form-inputs a select {
    border: none;
  }

  .inner2-3 .form-inputs input {
    font-size: 14px;
    width: 80%;
    border: none;
  }

  .model-container-inner21 {
    margin-bottom: 20px;
  }

  .model-container-inner21 .form-radios {
    margin-top: 5px;
  }

  .model-container-inner21 .form-radios span {
    margin-right: 25px;
    font-size: 14px;
    color: #3e4249e0;
    text-align: left;
  }

  .model-container-inner21 .form-radios span input {
    margin-right: 5px;
  }

  .model-container-inner22 div {
    margin-bottom: 15px;
    font-size: 14px;
    color: #3e4249e0;
  }

  .model-container-inner22 div input {
    margin-right: 5px;
  }

  .inner25-button {
    width: 100%;
    height: 40px;
    font-size: 14px;
    max-width: 260px;
    margin-top: 10px;
    background-color: #3498db;
    border-color: #3498db;
    color: #fff;
    border-radius: 3px;
  }
}

/* =========MODEL POPUP END ========== */

/*................. new model start............ */

.newmodel-container-main {
  display: flex !important;
  justify-content: space-between !important;
  padding: 10px;
}

.i_inner01 {
  border-bottom: 1px solid gray;
  width: 54%;
  padding: 10px;
}

.newmain-top {
  margin-right: 200px;
}

.newhead-one {
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.newmodalmain {
  background: rgba(145, 145, 145, 0.388) !important;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9;
  scroll-behavior: smooth;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newmodalmain .newmodel-wrapper {
  background: #fff;
  width: 1000px;
  height: 40%;
  top: 25%;
  left: 150px;
  right: 150px;
  position: absolute;
  border-radius: 5px;
  padding: 20px;
  padding-top: 20px;
}

.approval_icon {
  font-size: 28px;
  float: left;
  position: relative;
  margin-right: 5px;
  vertical-align: bottom;
}

.newmain-head-posted {
  color: #333;
  position: relative;
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
}

.newmodel-container-inner1 h4 {
  color: #27ae61;
  font-size: 14px;
  text-align: left;
  font-weight: 600;
}

.newmodel-container-inner1 p {
  font-size: 10px;
  color: #666;
  text-transform: uppercase;
}

.newmodel-container-inner2 h4 {
  font-size: 14px;
  font-weight: 600;
  color: #666;
  margin-top: 10px;
}

.newmodel-container-inner2 p {
  font-size: 10px;
  color: #666;
  text-transform: uppercase;
}

.usermodel-containermain_bottom {
  text-align: left;
  padding-top: 10px;
}

.usermodel-containermain_bottom h4 {
  display: inline-block;
  font-size: 14px;
  margin: 0 0 0 7px;
  color: #333;
}

.usermodel-containermain_bottom ul {
  display: inline-block;
  vertical-align: middle;
}

.usermodel-containermain_bottom ul li {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #666;
  color: #666;
  padding: 1px 6px;
  font-size: 12px;
  margin-left: 7px;
}

.usermodel-containermain_bottom .sms_icon {
  font-size: 25px;
  float: left;
  position: relative;
  margin-right: 3px;
  vertical-align: bottom;
}

/* =============== Media queryes for new model start =============== */
/* nishitha media queryes */

@media screen and (max-width: 680px) {
  .banner {
    background-image: url("../public/assets/images/mobileversion.png");
    background-position: center;
  }
}
@media screen and (min-width: 220px) and (max-width: 350px) {
  .banner {
    max-height: 420px !important;
  }
  .containerofinpagesearchbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 9;
    position: absolute;
    top: 19rem;
    height: 6rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_container_of_inpage {
    height: 40%;
    width: 100%;
  }
  .subtwo_container_of_inpage {
    height: 60%;
    width: 100%;
    background-color: white !important;
    border-radius: 41px 41px 41px 41px;
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    line-height: 30px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 9px;
    font-weight: 400;
  }
  #buttonsearchsub {
    background-color: #36c991;
    width: 5rem;
    margin-right: 0.5rem;
    height: 2.2rem;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
    border-radius: 3rem;
  }
}

@media only screen and (min-width: 421px) and (max-width: 500px) {
  .containerofinpagesearchbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 99;
    position: absolute;
    top: 14.6rem;
    height: 6rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_container_of_inpage {
    height: 40%;
    width: 100%;
  }
  .subtwo_container_of_inpage {
    height: 60%;
    width: 100%;
    background-color: white !important;
    border-radius: 41px 41px 41px 41px;
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 25px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    justify-content: space-evenly;
    gap: 0px;
  }
  #buttonsearchsub {
    background-color: #36c991;
    width: 6rem;
    margin-right: 1rem;
    height: 2.5rem;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    border-radius: 3rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 9;
    position: absolute;
    top: 18.5rem;
    height: 6rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
}
@media only screen and (min-width: 350px) and (max-width: 420px) {
  .containerofinpagesearchbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 9;
    position: absolute;
    top: 15.5rem;
    height: 4rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_container_of_inpage {
    height: 40%;
    width: 100%;
  }
  .subtwo_container_of_inpage {
    height: 60%;
    width: 100%;

    background-color: white !important;
    border-radius: 41px 41px 41px 41px;
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    line-height: 25px;
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 10px;
    font-weight: 400;
  }
  #buttonsearchsub {
    background-color: #36c991;
    width: 5rem;
    margin-right: 0.5rem;
    height: 2.2rem;
    font-size: 0.8rem;
    letter-spacing: 0.05rem;
    border-radius: 3rem;
  }
}

@media only screen and (min-width: 501px) and (max-width: 710px) {
  .containerofinpagesearchbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 99;
    position: absolute;
    /* left:15%; */
    top: 14.5rem;
    height: 7rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_container_of_inpage {
    height: 40%;
    width: 100%;
  }
  .subtwo_container_of_inpage {
    height: 60%;
    width: 100%;
    background-color: white !important;
    border-radius: 41px 41px 41px 41px;
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 30px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-evenly;
    gap: 0px;
  }
  #buttonsearchsub {
    background-color: #36c991;
    width: 7rem;
    margin-right: 1rem;
    height: 3rem;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    border-radius: 3rem;
  }
}
@media only screen and (min-width: 711px) and (max-width: 920px) {
  .containerofinpagesearchbox {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tab__tabsContent {
    width: 100%;
    background-color: white !important;
    border-radius: 41px 41px 41px 41px !important;
  }
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 99;
    position: absolute;
    top: 16rem;
    height: 7rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 35px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 11px;
    font-weight: 500;
    justify-content: space-evenly;
    gap: 0px;
  }
}

@media only screen and (min-width: 960px) and (max-width: 980px) {
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 9;
    position: absolute;
    top: 18rem;
    height: 7rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 35px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-evenly;
    gap: 0px;
  }
}

@media only screen and (min-width: 950px) and (max-width: 960px) {
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 9;
    position: absolute;
    top: 15.2rem;
    height: 7rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 35px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-evenly;
    gap: 0px;
  }
}

@media only screen and (min-width: 921px) and (max-width: 950px) {
  .innercontainerofinpagesearchbox {
    width: 95%;
    z-index: 9;
    position: absolute;
    top: 16.2rem;
    height: 7rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 35px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-evenly;
    gap: 0px;
  }
}

@media only screen and (min-width: 980px) and (max-width: 1100px) {
  .innercontainerofinpagesearchbox {
    width: 65%;
    z-index: 9;
    position: absolute;
    top: 20rem;
    height: 7rem;
    border-radius: 15px 15px 41px 41px;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
  }
  .sub_inner_container {
    width: 100%;
    display: flex;
    align-items: center;
    line-height: 35px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    justify-content: space-evenly;
    gap: 0px;
  }
  #buttonsearchsub {
    background-color: #36c991;
    width: 7rem;
    margin-right: 1rem;
    height: 3rem;
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    border-radius: 3rem;
  }
}
/* ---end--- */

@media only screen and (max-width: 300px) {
  .newmodalmain {
    background: rgba(145, 145, 145, 0.388) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newmodalmain .newmodel-wrapper {
    background: #fff;
    width: 300px !important;
    height: 40%;
    top: 25%;
    left: 0;
    right: 0;
    position: absolute;
    border-radius: 5px;
    padding: 30px;
  }

  .newmodel-container-main {
    display: block !important;
    padding: 10px;
  }

  .i_inner01 {
    border-bottom: 1px solid gray;
    width: 296px;
    padding: 10px 0px 10px 0px;
  }

  .newmodelinner-sub {
    width: 296px;
    margin-top: 20px;
  }

  .usermodel-containermain_bottom ul {
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 420px) {
  .walcon {
    display: flex;
    flex-direction: column !important;
  }

  .walsubcon {
    width: 100% !important;
  }

  .newmodalmain {
    background: rgba(145, 145, 145, 0.388) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newmodalmain .newmodel-wrapper {
    background: #fff;
    width: 350px;
    height: 40%;
    top: 25% !;
    left: 0px;
    right: 0px;
    position: absolute;
    border-radius: 5px;
    padding: 30px;
  }

  .newmodel-container-main {
    display: block !important;
    justify-content: space-between !important;
    padding: 10px;
  }

  .i_inner01 {
    border-bottom: 1px solid gray;
    width: 296px;
    padding: 10px 0px 10px 0px;
  }

  .newmodelinner-sub {
    width: 296px;
    margin-top: 20px;
  }

  .usermodel-containermain_bottom ul {
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 520px) {
  .newmodalmain {
    background: rgba(145, 145, 145, 0.388) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newmodalmain .newmodel-wrapper {
    background: #fff;
    width: 400px !important;
    height: 40% !important;
    top: 25%;
    left: 0px !important;
    right: 0px !important;
    position: absolute;
    border-radius: 5px;
    padding: 30px;
  }

  .newmodel-container-main {
    display: block !important;
    justify-content: space-between !important;
    padding: 10px;
  }

  .i_inner01 {
    border-bottom: 1px solid gray;
    width: 296px !important;
    padding: 10px 0px 10px 0px;
  }

  .newmodelinner-sub {
    width: 296px !important;
    margin-top: 20px;
  }

  .usermodel-containermain_bottom ul {
    display: inline-block;
    vertical-align: middle;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 720px) {
  .newmodalmain {
    background: rgba(145, 145, 145, 0.388) !important;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newmodalmain .newmodel-wrapper {
    background: #fff;
    width: 400px;
    height: 40%;
    top: 33%;
    margin-left: 50px;
    position: absolute;
    border-radius: 5px;
    padding: 30px;
  }

  .newmodel-container-main {
    display: block !important;
    justify-content: space-between !important;
    padding: 10px;
  }

  .i_inner01 {
    border-bottom: 1px solid gray;
    width: 296px !important;
    padding: 10px 0px 10px 0px;
  }

  .newmodelinner-sub {
    width: 296px !important;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 960px) {
  .newmodalmain {
    background: rgba(145, 145, 145, 0.388);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newmodalmain .newmodel-wrapper {
    background: #fff;
    width: 650px;
    height: 35%;
    top: 30%;
    left: 150px;
    position: absolute;
    border-radius: 5px;
    padding: 30px;
    margin-left: 0;
  }

  .newmodel-container-main {
    display: block;
    justify-content: space-between;
    padding: 10px;
  }

  .i_inner01 {
    border-bottom: 1px solid gray;
    width: 296px;
    padding: 10px 0px 10px 0px;
  }

  .newmodelinner-sub {
    width: 296px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1080px) {
  .newmodalmain {
    background: rgba(145, 145, 145, 0.388);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    z-index: 9;
    scroll-behavior: smooth;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .newmodalmain .newmodel-wrapper {
    background: #fff;
    width: 90%;
    height: 35%;
    top: 30%;
    left: 50px;
    right: 50px;
    position: absolute;
    border-radius: 5px;
    padding: 30px;
  }

  .newmodel-container-main {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .i_inner01 {
    border-bottom: 1px solid gray;
    width: 45%;
  }

  .newmodelinner-sub {
    margin-top: 20px;
  }
}

/* =============== Media queryes for new model end =============== */

/*................. new model End............ */

/*========= Footer Componenets Start======== */

.css-1dbjc4n {
  -webkit-box-align: stretch;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  align-items: stretch;
  border: 0 solid #000;
  box-sizing: border-box;
  display: flex;
  /* flex-basis: auto; */
  flex-direction: column;
  /* flex-shrink: 0; */
  margin: 0;
  min-height: 0;
  min-width: 0;
  padding: 0;
  position: relative;
  z-index: 0;
}

.r-bijgke {
  padding-top: 50px;
}

.r-xovx4i {
  background-color: #f0f9ff;
}

.r-1awozwy {
  -webkit-box-align: center;
  align-items: center;
}

.r-6koalj {
  display: flex;
}

.r-1kz6sp {
  width: 52px;
}

.r-64el8z {
  min-width: 52px;
}

.r-145lgeb {
  min-height: 37px;
}

.r-1uopjct {
  height: 37px;
}

.r-417010 {
  z-index: 0;
}

.r-1mlwlqe {
  flex-basis: auto;
}

.r-1kz6sp {
  width: 52px;
}

.r-64el8z {
  min-width: 52px;
}

.r-145lgeb {
  min-height: 37px;
}

.r-1uopjct {
  height: 65px;
}

.r-1udh08x {
  overflow: hidden;
}

.r-1wyyakw {
  z-index: -1;
}

.r-13qz1uu {
  width: 100%;
}

.r-ipm5af {
  top: 0;
}

.r-zchlnj {
  right: 0;
}

.r-u8s1d {
  position: absolute;
}

.r-1d2f490 {
  left: 0;
}

.r-1pi2tsx {
  height: 100%;
}

.r-1p0dtai {
  bottom: 0;
}

.r-4gszlv {
  background-size: cover;
}

.r-u6sd8q {
  background-repeat: no-repeat;
}

.r-vvn4in {
  background-position: center center;
}

.r-1niwhzg {
  background-color: transparent;
}

.css-9pa8cd {
  inset: 0;
  height: 100%;
  opacity: 1;
  position: absolute;
  width: 100%;
  z-index: 5;
}

.r-7sv4c2 {
  margin-bottom: 19px;
}

.r-145ew9a {
  padding-right: 17px;
}

.r-b5h31w {
  padding-left: 20px;
}

.r-y47klf {
  border-radius: 20px;
}

.r-db3y9k {
  padding-top: 46px;
}

.r-waer2z {
  line-height: 25px;
}

.r-16dba41 {
  font-weight: 400;
}

.r-um3v3g {
  color: #253858;
}

.r-13uqrnb {
  font-style: normal;
}

.css-901oao {
  text-align: left;
}

.r-eu3ka {
  height: 40px;
}

.r-117bsoe {
  margin-bottom: 20px;
}

.r-18u37iz {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.r-13uqrnb {
  font-style: normal;
}

.r-98loyc {
  font-family: "Open Sans";
}

.r-1awozwy {
  -webkit-box-align: center;
  align-items: center;
}

.r-xovx4i {
  background-color: #f0f9ff;
}

.r-1oqcu8e {
  padding: 15px;
}

.r-6koalj {
  display: flex;
}

.r-1dzdj1l {
  border-radius: 10px;
}

.r-uahozn {
  width: 47px;
}

.r-1w1cp9x {
  min-width: 47px;
}

.r-h10h58 {
  min-height: 60px;
}

.r-1mf7evn {
  margin-right: 20px;
}

.r-uvuy5l {
  height: 60px;
}

.r-uahozn {
  width: 47px;
}

.r-1w1cp9x {
  min-width: 47px;
}

.r-h10h58 {
  min-height: 60px;
}

.r-1mf7evn {
  margin-right: 20px;
}

.r-uvuy5l {
  height: 60px;
}

.r-417010 {
  z-index: 0;
}

.r-1mlwlqe {
  flex-basis: auto;
}

.r-1udh08x {
  overflow: hidden;
}

.css-901oao {
  border: 0 solid #000;
  box-sizing: border-box;
  color: #000;
  display: inline;
  font: 14px -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.r-18u37iz {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.r-6koalj {
  display: flex;
}

.r-15d164r {
  margin-bottom: 10px;
}

.r-1wy1o97 {
  color: #8993a4;
}

.r-1kfrs79 {
  font-weight: 600;
}

.r-18u37iz {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.r-6koalj {
  display: flex;
}

.r-1acpoxo {
  width: 36px;
}

.r-1qi8awa {
  min-width: 36px;
}

.r-2yi16 {
  min-height: 36px;
}

.r-1b7u577 {
  margin-right: 12px;
}

.r-1r8g8re {
  height: 36px;
}

.r-1acpoxo {
  width: 36px;
}

.r-1qi8awa {
  min-width: 36px;
}

.r-2yi16 {
  min-height: 36px;
}

.r-1b7u577 {
  margin-right: 12px;
}

.r-1r8g8re {
  height: 36px;
}

.r-417010 {
  z-index: 0;
}

.r-1mlwlqe {
  flex-basis: auto;
}

.r-1udh08x {
  overflow: hidden;
}

.r-1wyyakw {
  z-index: -1;
}

.r-13qz1uu {
  width: 100%;
}

.r-ipm5af {
  top: 0;
}

.r-zchlnj {
  right: 0;
}

.r-u8s1d {
  position: absolute;
}

.r-1d2f490 {
  left: 0;
}

.r-1pi2tsx {
  height: 100%;
}

.r-1p0dtai {
  bottom: 0;
}

.r-4gszlv {
  background-size: cover;
}

.r-u6sd8q {
  background-repeat: no-repeat;
}

.r-vvn4in {
  background-position: center center;
}

.r-1niwhzg {
  background-color: transparent;
}

.css-9pa8cd {
  inset: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.r-2o02ov {
  margin-top: 40px;
}

.r-15d164r {
  margin-bottom: 10px;
}

.r-1acpoxo {
  width: 36px;
}

.r-le9fof {
  margin-right: 19px;
}

.r-mlyl9t {
  margin-left: 21px;
}

.r-13uqrnb {
  font-style: normal;
}

.r-rs99b7 {
  border-width: 1px;
}

.r-r3u836 {
  border-color: rgb(235, 236, 240);
}

.r-1xfd6ze {
  border-radius: 8px;
}

.r-18u37iz {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.r-6koalj {
  display: flex;
}

.r-135wba7 {
  line-height: 24px;
}

.r-ubezar {
  font-size: 16px;
}

.r-1kfrs79 {
  font-weight: 600;
}

.r-13uqrnb {
  font-style: normal;
}

.r-kze2mb {
  color: rgb(4, 21, 51);
}

.r-11c0sde {
  margin-top: 24px;
}

.r-1kidu6m {
  margin-top: 47px;
}

.r-1777fci {
  -webkit-box-pack: center;
  justify-content: center;
}

.r-18u37iz {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.r-6koalj {
  display: flex;
}

.r-1awozwy {
  -webkit-box-align: center;
  align-items: center;
}

.r-6koalj {
  display: flex;
}

.r-1b3ntt7 {
  padding-top: 11px;
}

.r-18f9sac {
  padding-right: 22px;
}

.r-1aminmm {
  padding-left: 22px;
}

.r-1t2hasf {
  padding-bottom: 18px;
}

.r-1rw7m1n {
  margin-right: 28px;
}

.r-rs99b7 {
  border-width: 1px;
}

.r-r3u836 {
  border-color: rgb(235, 236, 240);
}

.r-1xfd6ze {
  border-radius: 8px;
}

.r-waer2z {
  line-height: 25px;
}

.r-1wy1o97 {
  color: rgb(137, 147, 164);
}

.r-1b43r93 {
  font-size: 14px;
}

.r-b88u0q {
  font-weight: 700;
}

.r-13uqrnb {
  font-style: normal;
}

.r-gi2tka {
  color: rgb(43, 121, 212);
}

.r-waer2z {
  line-height: 25px;
}

.r-1wy1o97 {
  color: rgb(137, 147, 164);
}

.r-16dba41 {
  font-weight: 400;
}

.r-1b43r93 {
  font-size: 14px;
}

.r-waer2z {
  line-height: 25px;
}

.r-ubezar {
  font-size: 16px;
}

.r-1kfrs79 {
  font-weight: 600;
}

.r-13uqrnb {
  font-style: normal;
}

.r-12ym1je {
  width: 18px;
}

.r-1hjwoze {
  height: 18px;
}

.r-417010 {
  z-index: 0;
}

.r-1mlwlqe {
  flex-basis: auto;
}

.r-ktdv3k {
  min-width: 18px;
}

.r-hudz2g {
  min-height: 18px;
}

.r-19qrga8 {
  margin-top: 3px;
}

.r-18u37iz {
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  flex-direction: row;
}

.r-1awozwy {
  -webkit-box-align: center;
  align-items: center;
}

.r-waer2z {
  line-height: 25px;
}

.r-1wy1o97 {
  color: rgb(137, 147, 164);
}

.r-1b43r93 {
  font-size: 14px;
}

.r-b88u0q {
  font-weight: 700;
}

.styles__table {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  border: 1px solid #ebecf0;
  border-collapse: separate;
  border-radius: 5px !important;
}

.styles__table tr:first-child {
  border-top: 0;
}

.styles__table th {
  border: 1px solid #ebecf0;
}

.styles__table th {
  text-align: left;
}

.styles__table th {
  border: 1px solid #ebecf0;
  padding: 12px;
}

.styles__tableHeading {
  font-weight: 600;
}

.styles__hyperText {
  font-size: 14px;
  font-weight: 600;
  color: #0078db;
}

.styles__table td:last-child {
  border-right: 0;
}

.styles__table td {
  width: 25%;
  text-align: left;
}

.styles__table td,
.styles__table th {
  border: 1px solid #ebecf0;
  padding: 12px;
}

.styles__getInTouchText {
  font-size: 12px;
  color: #42526e;
  margin-bottom: 35px;
}

.styles__contactBox {
  border: 1px solid #ebecf0;
  border-radius: 8px !important;
  padding: 29px 36px;
  display: flex;
  flex-direction: row;
  width: 78%;
  margin-left: 18%;
  margin-bottom: 79px;
  justify-content: space-between;
}

.styles__hyperText a {
  font-size: 14px;
  font-weight: 600;
  color: #0078db;
}

.styles__Text {
  font-size: 12px;
  color: #8993a4;
}

.csp20 {
  height: 20px;
  overflow: hidden;
  clear: both;
}

.sp20 {
  width: 65%;
  line-height: 20px;
}

#container_griv {
  width: 99.8%;
  margin: 0px auto;
  color: #333;
  border: 0px solid gray;
  line-height: 130%;
}

#content_newgriv {
  border-left: 0px solid gray;
  margin-right: 110px;
  margin-left: 10px;
  border: 0px solid gray;
  padding: 0em;
}

.rf {
  float: right;
}

.rf b {
  font-weight: bold;
}

.hd3 {
  font: normal 22px arial, verdana;
  padding-bottom: 10px;
  color: #cc6600;
}

.compaintForm {
  width: 762px;
}

.secbx1 {
  margin-left: 0px;
  background: #fff;
  width: 700px;
  border: 0px solid;
}

.lf {
  float: left;
}

.bxl {
  padding: 0px 0px 17px 0px;
  border: 0px solid;
  background-repeat: repeat-x;
  background-position: bottom;
}

div.row4 {
  clear: both;
  padding-top: 15px;
  font: normal 12px verdana, arial;
  color: #000;
}

div.row4 p {
  clear: both;
  margin-left: 120px;
}

p {
  margin: 0 0 0.5em 0;
}

div.row4 label {
  margin-left: 120px;
  padding-bottom: 5px;
  display: block;
  text-align: left;
}

select {
  font: normal 11px Verdana, Arial;
  color: #000;
}

option {
  font-weight: normal;
  display: block;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  min-height: 1.2em;
  padding: 0px 2px 1px;
}

.rc-anchor-light.rc-anchor-normal,
.rc-anchor-light.rc-anchor-compact {
  border: 1px solid #d3d3d3;
}

.rc-anchor-light {
  background: #f9f9f9;
  color: #000;
}

.rc-anchor-normal {
  height: 74px;
  width: 300px;
}

.rc-anchor-light {
  background: #f9f9f9;
  color: #000;
}

.rc-anchor-normal.rc-anchor-error .rc-anchor-error-msg-container {
  width: 240px;
}

.rc-anchor-error-msg-container {
  color: #f00;
  font-family: Roboto, helvetica, arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  line-height: 14px;
  margin: 2px;
  position: absolute;
  top: 0;
}

.r-1vxqurs {
  margin-bottom: 29px;
}

.r-fd4yh7 {
  padding-top: 32px;
}

.r-85oauj {
  padding-right: 32px;
}

.r-gu0qjt {
  padding-left: 32px;
}

.r-11rk87y {
  padding-bottom: 32px;
}

.r-1wtj0ep {
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.r-byiv6 {
  background-color: #f4f5f7;
}

.r-1mcorv5 {
  width: 14px;
}

.r-s5r7i3 {
  top: 4px;
}

.r-zj9f58 {
  min-width: 14px;
}

.r-1ijha98 {
  min-height: 8px;
}

.r-3da1kt {
  height: 8px;
}

.r-fov093 {
  color: #42526e;
}

.r-85oauj {
  padding-right: 32px;
}

.r-13qz1uu {
  width: 100%;
}

.r-85oauj {
  padding-right: 32px;
}

.r-byiv6 {
  background-color: #f4f5f7;
}

.r-13qz1uu {
  width: 100%;
}

.r-ttdzmv {
  padding-top: 12px;
}

.r-z7j6nc {
  padding-left: 36px;
}

.r-kzbkwu {
  padding-bottom: 12px;
}

.r-qklmqi {
  border-bottom-width: 1px;
}

.r-hpenpt {
  border-bottom-color: rgb(235, 236, 240);
}

.r-85oauj {
  padding-right: 32px;
}

.r-byiv6 {
  background-color: #f4f5f7;
}

.r-z7j6nc {
  padding-left: 36px;
}

.r-ubezar {
  font-size: 16px;
}

.r-16dba41 {
  font-weight: 400;
}

.r-gi2tka {
  color: #2b79d4;
}

.r-1loqt21 {
  cursor: pointer;
}

.r-ubezar {
  font-size: 16px;
}

.r-16dba41 {
  font-weight: 400;
}

.r-17iqy7s {
  color: #0078db;
}

.r-1ah4tor {
  padding-right: 20px;
}

.r-f727ji {
  padding-left: 12px;
}

.r-6zzn7w {
  height: 25px;
}

.r-2o02ov {
  margin-top: 40px;
}

.r-5oul0u {
  margin-bottom: 8px;
}

.r-xd6kpl {
  padding-bottom: 8px;
}

.r-adyw6z {
  font-size: 20px;
}

.styles__form {
  width: 50%;
  margin-left: 26%;
  text-align: left;
}

.styles__intro {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-top: 46px;
  margin-bottom: 8px;
}

.inputField__inputContainer {
  position: relative;
}

.inputField__inputContainer input {
  border: 1px solid #ebecf0;
  border-radius: 2px;
  width: 100%;
  min-height: 52px;
  position: relative;
  z-index: 1;
  padding: 19px 12px 12px !important;
  background: transparent;
  font-size: 14px !important;
  color: #42526e;
  -webkit-tap-highlight-color: transparent;
}

.styles__form input {
  margin-bottom: 16px;
}

.inputField__inputContainer .inputField__placeholder {
  position: absolute;
  left: 12px;
  z-index: 0;
  top: 15px;
}

.caption_subdued_large {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #8993a4;
  display: block;
  text-align: left;
  vertical-align: middle;
}

.styles__starHeading {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #041533;
}

.styles__content {
  display: flex;
  flex-direction: row;
}

.styles__starimg {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  margin-top: 16px;
  margin-bottom: 20px;
}

.styles__cityInputStyle > div {
  margin-bottom: 0 !important;
}

.selectBox__selectBox {
  position: relative;
  pointer-events: auto;
}

.inputField__inputContainer {
  position: relative;
}

.selectBox__selectBox i.icon_downArrow {
  opacity: 0.7;
}

.iconS_Common_24.icon_downArrow {
  background-position: -288px 0;
}

.selectBox__selectList.selectBox__hide {
  display: none;
}

.selectBox__selectList {
  position: absolute;
  top: 100%;
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 12px 0;
  z-index: 5;
  max-height: 212px;
  overflow-y: auto;
  font-size: 14px;
  color: #42526e;
}

.selectBox__selectList > div {
  position: relative;
  margin-bottom: 8px;
  padding: 8px 12px;
  display: flex;
  cursor: pointer;
}

.styles__form input {
  margin-bottom: 16px;
  cursor: pointer;
}

.inputField__inputContainer input {
  border: 1px solid #ebecf0;
  border-radius: 2px;
  width: 100%;
  min-height: 52px;
  position: relative;
  z-index: 1;
  padding: 19px 12px 12px !important;
  background: transparent;
  font-size: 14px !important;
  color: #42526e;
  -webkit-tap-highlight-color: transparent;
}

.selectBox__selectBox i.icon_downArrow {
  opacity: 0.7;
}

.selectBox__selectBox i {
  position: absolute;
  top: 15px;
  margin: auto;
  right: 10px;
  font-size: 22px;
}

.iconS_Common_24 {
  width: 24px;
  height: 24px;
  background-size: 408px 287px;
  display: inline-block;
  vertical-align: middle;
  font-style: normal;
  background-repeat: no-repeat;
}

.styles__submitButton {
  margin-bottom: 57px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-radius: 4px !important;
  width: 100%;
}

.buttons__primaryRegular {
  box-shadow: 0 1px 4px 0 rgba(0, 106, 194, 0.2);
  background: #0078db;
  outline: none;
  margin: 0;
  cursor: pointer;
  border: 0;
  min-height: auto;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

.SnackBar__snackbarcontainer {
  position: fixed;
  left: 0;
  bottom: -100%;
  width: 100%;
  overflow: hidden;
  z-index: 200;
  transition: all 0.3s ease-in-out;
  -webkit-tap-highlight-color: transparent;
}

.feedback_select {
  border: 1px solid #ebecf0;
  padding: 20px;
  /* background: #EBECF0; */
}

.feedback_select div {
  margin-bottom: 5px;
}

/* ================================Responive footer start =================== */

.about_uscontent {
  overflow-x: hidden;
  padding-left: 23%;
  padding-right: 23%;
}

.contact_ustable {
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 40px;
  display: block;
}

.contact_usbottom {
  overflow-x: hidden;
  padding-left: 23%;
  padding-right: 23%;
}

.contact_usimg {
  width: 200px;
  margin-left: 40%;
  text-align: center;
}

.contact_usimg img {
  width: 40%;
  height: 40%;
  margin-bottom: "12px";
  justify-content: "center";
}

.contact_usimg h4 {
  text-align: left;
  margin-top: 5px;
  margin-left: -20px;
}

.contact_usimgtext {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 20px;
}

.styles_boxin {
  display: flex;
}

.styles_boxin img {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  margin-top: 7px;
}

.terms_conditions {
  padding-left: 23%;
  padding-right: 23%;
  overflow-x: hidden;
}

.privacy_ploicy {
  overflow-x: hidden;
  padding-left: 23%;
  padding-right: 23%;
}

.summons_notice {
  overflow-x: hidden;
  padding-left: 23%;
  padding-right: 23%;
  margin-top: 50px;
}

.grivance {
  overflow-x: hidden;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 50px;
}

.safety_guide {
  overflow-x: hidden;
  padding-left: 3%;
  padding-right: 3%;
}

@media screen and (max-width: 420px) and (min-width: 360px) {
  .about_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .awards_recognition {
    display: flex;
  }

  .contact_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_ustable {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 20px;
    margin-bottom: 89px;
    display: block;
  }

  .contact_usbottom {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_usbottom {
    width: 200px;
    margin-left: 12%;
  }

  .contact_usimg {
    width: 200px;
    text-align: center;
  }

  .contact_usimg img {
    width: 30%;
    height: 30%;
    margin-bottom: "12px";
    justify-content: "center";
  }

  .contact_usimg h4 {
    text-align: left;
    margin-top: 5px;
    margin-left: -20px;
  }

  .contact_usimgtext {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
  }

  .styles__hyperText a {
    font-size: 12px;
    font-weight: 600;
    color: #0078db;
  }

  .styles__getInTouchText {
    margin-left: 20px;
    width: 100%;
  }

  .styles__contactBox {
    display: block;
  }

  .styles_boxin {
    display: inline-flex;
  }

  .styles_boxincontact {
    margin-left: -79px;
  }

  .styles__contactBox {
    border: 1px solid #ebecf0;
    border-radius: 8px !important;
    padding: 5px 36px;
    flex-direction: row;
    width: 78%;
    margin-left: 11%;
    margin-bottom: 10px;
  }

  .terms_conditions {
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
  }

  .terms_conditionbottom {
    display: block;
  }

  .terms_conditionbottom_main div {
    text-align: left;
    margin-bottom: 5px;
  }

  .terms_conditionbottom_main {
    display: block;
    margin-left: 20px;
  }

  .terms_conditions_mainin1 {
    margin-top: 20px;
    width: 93%;
    padding-left: 60px;
  }

  .r-1aminmm {
    padding-left: 70px;
  }

  .terms_conditionbottom_maininner {
    display: block;
  }

  .r-hudz2g {
    min-height: 52px;
    margin-right: 20px;
  }

  .terms_conditionbottom_maininner div {
    margin-bottom: 10px;
  }

  .privacy_ploicy {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .summons_notice {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .secbx1 {
    margin-left: 0px;
    background: #fff;
    width: 200px;
    border: 0px solid;
  }

  .grivance {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .safety_guide {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media screen and (max-width: 520px) and (min-width: 420px) {
  .about_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .contact_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_ustable {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 20px;
    margin-bottom: 89px;
    display: block;
  }

  .contact_usbottom {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_usbottom {
    width: 200px;
    margin-left: 18%;
  }

  .contact_usimg {
    width: 200px;
    text-align: center;
  }

  .contact_usimg img {
    width: 30%;
    height: 30%;
    margin-bottom: "12px";
    justify-content: "center";
  }

  .contact_usimg h4 {
    text-align: left;
    margin-top: 5px;
    margin-left: -20px;
  }

  .contact_usimgtext {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
  }

  .styles__getInTouchText {
    margin-left: 20px;
    width: 100%;
  }

  .styles__contactBox {
    display: block;
  }

  .styles_boxincontact {
    margin-left: 20px;
  }

  .styles__contactBox {
    border: 1px solid #ebecf0;
    border-radius: 8px !important;
    padding: 5px 36px;
    flex-direction: row;
    width: 78%;
    margin-left: 11%;
    margin-bottom: 10px;
  }

  .boxin_group {
    text-align: left;
  }

  .boxin_group .boxnin2_styles {
    margin-left: 20px;
  }

  .boxin_group .boxnin3_styles {
    margin-left: 20px;
  }

  .boxnin3_styles {
    margin-left: 20px;
  }

  .terms_conditions {
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
  }

  .terms_conditionbottom {
    display: block;
  }

  .terms_conditionbottom_main div {
    text-align: left;
    margin-bottom: 5px;
  }

  .terms_conditionbottom_main {
    display: block;
    margin-left: 20px;
  }

  .terms_conditions_mainin1 {
    margin-top: 20px;
    width: 93%;
  }

  .terms_conditionbottom_maininner {
    display: block;
  }

  .r-hudz2g {
    min-height: 52px;
    margin-right: 20px;
  }

  .terms_conditionbottom_maininner div {
    margin-bottom: 10px;
  }

  .privacy_ploicy {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .summons_notice {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .secbx1 {
    padding-left: 3%;
    padding-right: 3%;
    margin-left: 0px;
    background: #fff;
    width: 200px;
    border: 0px solid;
  }

  .grivance {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .safety_guide {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media screen and (max-width: 720px) and (min-width: 520px) {
  .about_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .contact_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_ustable {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 20px;
    margin-bottom: 89px;
    display: block;
  }

  .contact_usbottom {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
    text-align: left;
  }

  .contact_usbottom {
    width: 200px;
    margin-left: 30%;
  }

  .contact_usimg {
    width: 200px;
    text-align: center;
  }

  .contact_usimg img {
    width: 30%;
    height: 30%;
    margin-bottom: "12px";
    justify-content: "center";
  }

  .contact_usimg h4 {
    text-align: left;
    margin-top: 5px;
    margin-left: -20px;
  }

  .contact_usimgtext {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
  }

  .styles__getInTouchText {
    margin-left: 20px;
    width: 100%;
  }

  .styles_boxincontact {
    display: block;
    margin-left: 0px;
  }

  .styles_boxincontact {
    display: flex;
  }

  .styles__contactBox {
    border: 1px solid #ebecf0;
    border-radius: 8px !important;
    padding: 5px 36px;
    flex-direction: row;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 10px;
  }

  .terms_conditions {
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
  }

  .terms_conditionbottom {
    display: block;
  }

  .terms_conditionbottom_main div {
    text-align: left;
    margin-bottom: 5px;
  }

  .terms_conditionbottom_main {
    display: flex;
    margin-left: 20px;
  }

  .terms_conditions_mainin1 {
    width: 50%;
    padding-left: 20px;
  }

  .terms_conditionbottom_maininner {
    display: block;
  }

  .r-hudz2g {
    min-height: 52px;
    margin-right: 20px;
  }

  .terms_conditionbottom_maininner div {
    margin-bottom: 10px;
  }

  .privacy_ploicy {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .summons_notice {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .secbx1 {
    padding-left: 5%;
    padding-right: 5%;
    margin-left: 0px;
    background: #fff;
    width: 600px;
    border: 0px solid;
  }

  .grivance {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .safety_guide {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media screen and (max-width: 960px) and (min-width: 720px) {
  .about_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .contact_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_ustable {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 20px;
    margin-bottom: 89px;
    display: block;
  }

  .contact_usbottom {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_usbottom {
    width: 200px;
    margin-left: 30%;
  }

  .contact_usimg {
    width: 200px;
    text-align: center;
  }

  .contact_usimg img {
    width: 30%;
    height: 30%;
    margin-bottom: "12px";
    justify-content: "center";
  }

  .contact_usimg h4 {
    text-align: left;
    margin-top: 5px;
    margin-left: -20px;
  }

  .contact_usimgtext {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
  }

  .styles__getInTouchText {
    margin-left: 20px;
    width: 100%;
  }

  .styles__contactBox {
    display: flex;
  }

  .styles_boxin {
    display: inline-flex;
  }

  .boxnin2_styles {
    margin-left: -40px;
  }

  .styles_boxincontact {
    margin-left: 10px;
    width: 70%;
  }

  .styles__contactBox {
    border: 1px solid #ebecf0;
    border-radius: 8px !important;
    padding: 5px 30px;
    flex-direction: row;
    width: 80%;
    margin-left: 11%;
    margin-bottom: 10px;
  }

  .styles__hyperText a {
    font-size: 12px;
    font-weight: 600;
    color: #0078db;
  }

  .terms_conditions {
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
  }

  .terms_conditionbottom {
    display: block;
  }

  .terms_conditionbottom_main div {
    text-align: left;
    margin-bottom: 5px;
  }

  .terms_conditionbottom_main {
    display: flex;
    margin-left: 20px;
  }

  .terms_conditions_mainin1 {
    width: 30%;
    padding-left: 20px;
  }

  .terms_conditionbottom_maininner {
    display: block;
  }

  .r-hudz2g {
    min-height: 52px;
    margin-right: 20px;
  }

  .terms_conditionbottom_maininner div {
    margin-bottom: 10px;
  }

  .privacy_ploicy {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .summons_notice {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .secbx1 {
    padding-left: 8%;
    padding-right: 8%;
    margin-left: 0px;
    background: #fff;
    width: 700px;
    border: 0px solid;
  }

  .grivance {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .safety_guide {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }
}
.textalign {
  text-align: left;
}

@media screen and (max-width: 1080px) and (min-width: 960px) {
  .about_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .contact_uscontent {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_ustable {
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 20px;
    margin-bottom: 89px;
    display: block;
  }

  .contact_usbottom {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    display: block;
  }

  .contact_usbottom {
    width: 200px;
    margin-left: 35%;
  }

  .contact_usimg {
    width: 200px;
    text-align: center;
  }

  .contact_usimg img {
    width: 30%;
    height: 30%;
    margin-bottom: "12px";
    justify-content: "center";
  }

  .contact_usimg h4 {
    text-align: left;
    margin-top: 5px;
    margin-left: -20px;
  }

  .contact_usimgtext {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 20px;
  }

  .styles__getInTouchText {
    margin-left: 20px;
    width: 100%;
  }

  .styles__contactBox {
    display: flex;
  }

  .styles_boxin {
    display: inline-flex;
  }

  .boxnin2_styles {
    margin-left: -40px;
  }

  .styles_boxincontact {
    margin-left: 5px;
    width: 33%;
  }

  .styles__contactBox {
    border: 1px solid #ebecf0;
    border-radius: 8px !important;
    padding: 5px 30px;
    flex-direction: row;
    width: 61%;
    margin-left: 18%;
    margin-bottom: 10px;
  }
  .styles__contactBox .boxin_group {
    display: flex;
  }

  .boxnin2_styles {
    margin-right: 30px;
  }

  .styles__hyperText a {
    font-size: 12px;
    font-weight: 600;
    color: #0078db;
  }

  .terms_conditions {
    padding-left: 5%;
    padding-right: 5%;
    overflow-x: hidden;
  }

  .terms_conditionbottom {
    display: block;
  }

  .privacy_ploicy {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }

  .summons_notice {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .secbx1 {
    padding-left: 10%;
    padding-right: 10%;
    margin-left: 0px;
    background: #fff;
    width: 1000px;
    border: 0px solid;
  }

  .grivance {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 50px;
  }

  .safety_guide {
    overflow-x: hidden;
    padding-left: 3%;
    padding-right: 3%;
  }
}

/* ================================Responive footer end=================== */

/*========= Footer Componenets End======== */

/*=================== property images start================*/

.component__localityGallery ul li img {
  width: 200px;
  height: 150px;
}

.component__localityGallery ul li.component__moreImages {
  width: 150px;
  overflow: hidden;
}

.component__localityGallery ul li.component__moreImages div {
  position: absolute;
  top: -60px;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  left: -75px;
  height: 150px;
}

.component__localityGallery ul li.component__moreImages div span {
  position: absolute;
  top: 0 !important;
  color: #fff;
  font-weight: 600;
  bottom: 0;
  left: 20px;
  right: 0;
  margin: auto;
  /* padding: 0 30px; */
  height: 46px;
}

@media (min-width: 761px) {
  .pd__pdBlock {
    color: #666;
    font-size: 14px;
    padding: 5px 0;
  }

  .pd__pdBlock h2 {
    padding-bottom: 0px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }

  .component__pdDescAdd {
    margin-bottom: 8px;
  }

  .component__pdDescAdd i {
    font-style: normal;
  }
}

@media (min-width: 320px) and (max-width: 760px) {
  .pd__pdBlock {
    background-color: #fff;
    padding: 0.625rem;
    margin: 0 0 20px;
    box-shadow: 0 32px 32px 0 rgba(48, 48, 77, 0.05),
      0 16px 16px 0 rgba(48, 48, 77, 0.05), 0 8px 8px 0 rgba(48, 48, 77, 0.05),
      0 4px 4px 0 rgba(48, 48, 77, 0.05), 0 2px 2px 0 rgba(48, 48, 77, 0.05);
  }

  .pd__pdBlock h2 {
    font-size: 0.9rem;
    padding: 0.625rem 0.3125rem 0.3125rem 0;
    color: #999;
    font-weight: 600;
  }
}

.component__pdDescAdd span {
  font-weight: 600;
}

/*=================== property images end================*/

.modal-image {
  background-color: #000;
  height: 100vh;
  color: gray;
}

.modal-image .modal-image-header {
  border-bottom: 1px solid gray;
  display: flex;
  padding: 0px 10px;
  line-height: 35px;
}

.modal-image .card-footer .card-footer-leftpanel {
  flex: 50%;
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-image .card-footer .card-footer-rightpanel {
  flex: 50%;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  gap: 50px;
  align-items: center;
  padding: 0 15px;
}

.modal-image .card-footer .card-footer-rightpanel button {
  background-color: unset;
  /* font-size: 35px; */
  color: #fff;
  outline: none;
  border: none;
}

.right-panel-border {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  padding: 0px 20px;
  color: #0078db;
  background-color: #333333;
  align-items: center;
  display: flex;
  justify-content: center;
}

.right-panel-border-2 {
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  padding: 0px 20px 0px 40px;
  color: #0078db;
  background-color: #333333;
}

.modal-image .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image .card-body .image-panel {
  width: 70%;
  height: 68vh;
  position: relative;
}

.image-operator {
  position: absolute;
  color: white;
  top: 40%;
  /* left: 45%; */
  height: 50px;
  z-index: 100;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #0078db; */
}

.footer-image {
  display: flex;
  flex-direction: column;
  /* border: 2px solid gray; */
  height: 22vh;

  /* align-items: center; */
}

.footer-image .footer-img-scroll {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.footer-img-scroll > div {
  /* height: 15vh; */
  /* width: 120px; */
  gap: 8px;
  /* border: 1px solid gray; */
  /* background-color: #0078db; */
  margin: 15px;
}

.footer-image img {
  height: 80px;
  width: 80px;
  opacity: 0.8;
  display: none;
  object-fit: cover;
  display: block;
  border: 2px solid gray;
}

.footer-image img:hover {
  opacity: 1;
  border: 2px solid #0078db;
}

.payment-fail-box {
  padding: 20px 100px;
}
.payment-fail-img-box {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-fail-img-box img {
  object-fit: cover;
  height: 100%;
}
.payment-fail-text-box {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.payment-fail-text-box p {
  font-size: 16px;
  font-weight: 600;
  color: #555;
}
.payment-fail-text-box button {
  padding: 8px 12px;
  border: 1px solid #555;
  background-color: #0076d6;
  border-radius: 2px;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
}
.payment-fail-text-box button:hover {
  background-color: #fff;
  color: #0076d6;
  border: 1px solid #0076d6;
}

@media screen and (max-width: 1200px) and (min-width: 998px) {
  .payment-fail-box {
    padding: 50px 10px;
  }
  .payment-fail-img-box {
    margin-top: 10vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .payment-fail-text-box p {
    font-size: 28px;
    text-align: center;
    font-weight: 600;
    color: #555;
    margin: 0px;
    height: 62px;
  }
  .payment-fail-text-box button {
    padding: 15px 29px;
    margin-top: 10px;
    font-size: 29px;
  }
}
@media screen and (max-width: 998px) and (min-width: 768px) {
  .payment-fail-box {
    padding: 50px 10px;
  }
  .payment-fail-img-box {
    margin-top: 7vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .payment-fail-text-box p {
    font-size: 23px;
    padding: 0px 5px;
    text-align: center;
    font-weight: 600;
    color: #555;
    margin: 0px;
    height: 50px;
  }
  .payment-fail-text-box button {
    padding: 11px 19px;
    margin-top: 10px;
    font-size: 23px;
  }
}
@media screen and (max-width: 768px) and (min-width: 520px) {
  .payment-fail-box {
    padding: 50px 10px;
  }
  .payment-fail-img-box {
    margin-top: 11vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .payment-fail-img-box img {
    height: 100%;
    object-fit: cover;
  }
  .payment-fail-text-box p {
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    color: #555;
    margin: 0px;
    height: 50px;
  }
  .payment-fail-text-box button {
    padding: 10px 15px;
    margin-top: 10px;
    font-size: 24px;
  }
}
@media screen and (max-width: 520px) and (min-width: 390px) {
  .payment-fail-box {
    padding: 50px 10px;
  }
  .payment-fail-img-box {
    margin-top: 11vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .payment-fail-img-box img {
    height: 100%;
    object-fit: cover;
  }
  .payment-fail-text-box p {
    font-size: 19px;
    text-align: center;
    font-weight: 600;
    color: #555;
    margin: 0px;
    height: 50px;
  }
  .payment-fail-text-box button {
    padding: 8px 13px;
    margin-top: 10px;
    font-size: 23px;
  }
}
@media screen and (max-width: 390px) and (min-width: 320px) {
  .payment-fail-box {
    padding: 50px 10px;
  }
  .payment-fail-img-box {
    margin-top: 11vh;
    height: 45vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .payment-fail-img-box img {
    height: 100%;
    object-fit: cover;
  }
  .payment-fail-text-box p {
    font-size: 13px;
    text-align: center;
    font-weight: 600;
    color: #555;
    margin: 0px;
    height: 50px;
  }
  .payment-fail-text-box button {
    padding: 6px 13px;
    margin-top: 10px;
    font-size: 18px;
  }
}
/*======================= Search Properties mobile version styles =========================*/

@media screen and (max-width: 820px) and (min-width: 360px) {
  .searchcont_box .searchcont_leftbox {
    /* background-color: red; */
    display: none;
  }

  .searchcont_box .searchcont_rightbox {
    /* background-color: red; */
    width: 100% !important ;
  }

  .search_propertybox .search_propertyboxTop {
    width: 100%;
    display: grid;
  }

  .search_propertyboxTop .search_pro_imageBox {
    width: 100%;
    height: 100%;
    /* background-color: pink; */
  }
  .search_pro_imageBox img {
  }

  .search_propertyboxTop .search_pro_Detail_box {
    width: 100%;
  }

  .squre_box_top h1 {
    font-size: 19px;
    /* font-weight: 600;
  font-family: "Poppins";
  color: #000; */
  }
  .squre_box_top h1 span {
    font-size: 13px;
    /* font-weight: 400;
  font-family: "Poppins";
  color: #000; */
  }

  .price_squre_box {
    justify-content: space-between;
  }
  .squre_box_one {
    width: 33.3% !important;
  }
  .squre_box_two {
    width: 33.3% !important;
  }
  .squre_box_three {
    width: 33.3% !important;
  }
  .search_propertybox_Bottom .propertybox_Bottom_left {
    width: 100% !important;
  }
  .search_propertybox_Bottom .propertybox_Bottom_right {
    width: 100% !important;
  }
  .search_propertybox .search_propertybox_Bottom {
    display: grid;
  }

  .vianumber_button {
    font-size: 14px !important;
  }
  .contact_button {
    font-size: 14px !important;
    border-radius: 3px;
  }

  .Bottom_left_bottom h1 {
    margin-top: 3px;
  }

  .search_propertybox .search_propertybox_Bottom {
    height: 14vh;
  }

  .searchcont_rightbox h2 {
    font-size: 15px !important;
    text-align: center;
  }

  .filterdisplayflex {
    display: flex;
    margin-top: 10px;
    justify-content: space-around;
  }

  .filtermain {
    border: 1px solid rgb(163, 218, 255);
    color: rgb(9, 30, 66);
    font-weight: 600;
    background: rgb(240, 249, 255);
    width: 100px;
    padding: 7px 10px 7px 10px;
    margin: 2px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filtertext {
    color: #000;
    margin-left: 5px;
    margin: 0;
  }
}

@media screen and (max-width: 360px) and (min-width: 300px) {
  .searchcont_box .searchcont_leftbox {
    /* background-color: red; */
    display: none;
  }

  .searchcont_box .searchcont_rightbox {
    /* background-color: red; */
    width: 100% !important ;
  }

  .search_propertybox .search_propertyboxTop {
    width: 100%;
    display: grid;
  }

  .search_propertyboxTop .search_pro_imageBox {
    width: 100%;
    height: 100%;
    /* background-color: pink; */
  }
  .search_pro_imageBox img {
  }

  .search_propertyboxTop .search_pro_Detail_box {
    width: 100%;
  }

  .squre_box_top h1 {
    font-size: 16px;
  }
  .squre_box_top h1 span {
    font-size: 10px;
  }

  .price_squre_box {
    justify-content: space-between;
  }
  .squre_box_one {
    width: 33.3% !important;
  }
  .squre_box_two {
    width: 33.3% !important;
  }
  .squre_box_three {
    width: 33.3% !important;
  }
  .search_propertybox_Bottom .propertybox_Bottom_left {
    width: 100% !important;
  }
  .search_propertybox_Bottom .propertybox_Bottom_right {
    width: 100% !important;
  }
  .search_propertybox .search_propertybox_Bottom {
    display: grid;
  }

  .vianumber_button {
    font-size: 10px !important;
  }
  .contact_button {
    font-size: 10px !important;
    border-radius: 3px;
  }

  .Bottom_left_bottom h1 {
    margin-top: 3px;
    font-size: 16px !important;
  }

  .search_propertybox .search_propertybox_Bottom {
    height: 14vh;
  }

  .searchcont_rightbox h2 {
    font-size: 13px !important;
    text-align: center;
    margin-top: 5px;
  }

  .filterdisplayflex {
    display: flex;
    margin-top: 10px;
  }

  .filtermain {
    border: 1px solid rgb(163, 218, 255);
    color: rgb(9, 30, 66);
    font-weight: 600;
    background: rgb(240, 249, 255);
    width: 100px;
    padding: 7px 10px 7px 10px;
    margin: 2px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filtertext {
    color: #000;
    margin-left: 5px;
    margin: 0;
  }

  .search_pro_Detail_box .name_appartment {
    font-size: 14px !important;
  }
  .proprty_detail_blog h6 {
    font-size: 12px !important;
  }
  .nearby_box .nearby_boxone {
    font-size: 11px !important;
  }
  .property_sale_detail .sale_detail_button {
    font-size: 9px !important;
  }

  .inPageSearchBox__inPageSearchBox .InPageTabs {
  }
}
/* .filterdisplayflex{
  display: none;
} */

/* =============search property rbsheet==============  */
