* {
  margin: 0;
  padding: 0;
}
.mainsection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* align-items: center; */
  background-color: #fff;
}
.second {
  background-color: #ffffff;
  height: 48px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 12px;
  min-width: 50%;
  max-width: 100%;
}
.third {
  width: 32%;
  margin-left: auto;
  margin-right: auto;
}
.namecontnainer {
  width: 12%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}
.mob-search-head {
  display: none;
}
#headersearchdesk {
  display: block;
}
.dropdown {
  border-right: 1px solid silver;
  /* box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px; */
  /* margin-top: 20px; */
}
 :where(.css-dev-only-do-not-override-98ntnt).ant-dropdown,
:where(.css-dev-only-do-not-override-98ntnt).ant-dropdown-menu-submenu {
  margin-top: 100px !important;
  margin-right: 20px !important;
}
.madha {
  background-color: #f0f9ff;
  height: 26px;
  width: 100%;
  border: 1px solid #a3daff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

}
.madha2 {
  background-color: #f0f9ff;
  height: 26px;
  min-width: 81px;
  border: 1px solid #a3daff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.addmoresearch {
  height: 30px;
  width: 256px;
  border: unset;
}
.clearall {
  background-color: #fff;
  width: 848px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  padding: 14px;
  border-radius: 10px;
}
.property {
  height: 30px;
  background-color: #f0f9ff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a3daff;
  padding: 10px;
}

.budget {
  border: 1px solid #ebecf0;
  width: auto;
  padding: 10px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.propertytypecontainer {
  display: flex;
  margin-top: 10px;
  gap: 2vh;
  align-items: center;
}
.apart {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  flex-wrap: wrap;
}
.btncontainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px;
  margin-top: 20px;
}
.searchbtn {
  background-color: #0078db;
  width: 78px;
  height: 34px;
  border-radius: 8px;
  color: #fff;
  align-items: center;
  justify-content: center;
  padding: 5px;
}
.cancelbtn {
  color: #0078db;
}

.namecontainer {
  margin-top: 15px;
}

.suggestion{
  background-color: #ffffff;
  max-height: 200px;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}
#opn2{
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  #headersearchdesk {
    display: none;
  }
  .mob-search-head {
    display: block;
  }
  .mob-search {
    margin-top: 76px;
    width: 90%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    padding-left: 3px;
    padding-right: 3px;
    height: 30px;
    justify-content: space-between;
    padding: 7px;
  }

  .addmoresearch {
    width: auto;
  }

  .clearall {
    width: auto;
    margin-top: 120px;
  }

  .property {
    font-size: 10px;
  }

  .budget {
    font-size: 10px;
  }

  .propertytypecontainer {
    flex-wrap: wrap;
  }

  .apart {
    font-size: 10px;
  }
}
