.leadsearch_main h4,
.leadsearch_main ul li {
  text-align: left;
}
.leadsearch_main img {
  align-self: flex-start;
}

.leadsearch_main{
  margin-top: 13vh;
}

.leadsearchheading {
  color: #000;
  font-size: 22px;
  font-weight: 600;
  vertical-align: middle;
}

.text {
  color: #000;
  font-size: 16px;
  font-weight: 500;
}

.lead-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

.lead-navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

.navigation-main {
  display: flex;
  flex-direction: row;
  margin: 17px 0 2px 2px;
}
.navigation-main li {
  background: #ececec;
  border: 1px solid #ddd;
  padding: 5px 10px 5px 10px;
  border-radius: 1px;
  margin-right: 10px;
  color: gray;
  font-size: 15px;
}

.backtext {
  color: #005ca8;
  font-size: 13px;
  font-weight: 400;
}

.leadsearched {
  display: flex;
  flex-direction: row;
  margin: 17px 0 2px 2px;
  justify-content: space-between;
}

.leadsearched-text {
  /* color: #005ca8; */
  font-size: 14px;
  font-weight: 500;
}
.leadsearched-text b {
  color: #000;
}

.modify-button {
  background: #646c77;
  border: none;
  padding: 10px 30px 10px 30px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.sort-text {
  color: #005ca8;
  font-size: 14px;
  font-weight: 500;
}

.mainBoxes {
  display: flex;
  flex-direction: row;
}
.firstbox {
  width: 15%;
  /* background: gray; */
}

.refine-search {
  color: #000;
  font-size: 15px;
  font-weight: 400;
}

.transaction {
  background: #eaeaea;
  margin-right: 15px;
  padding: 5px;
  align-items: center;
  /* justify-content: center; */
  display: flex;
}
.transaction p {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.checkboxtext {
  color: #000;
  font-size: 14px;
}

.secondbox {
  width: 85%;
  /* background: #eee;
  border: 2px solid #ddd;
  border-radius: 3px; */
  padding: 10px;
  height: 350px;
}

.secondbox-mainbox {
  background: #eee;
  border: 2px solid #ddd;
  border-radius: 3px;
    padding: 10px;

}

.second_one {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.deepstars {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}
.receivedbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;
}

.star {
  color: gray;
  font-size: 16px;
  font-weight: 500;
}
.deepmala {
  color: gray;
  font-size: 15px;
  font-weight: 400;
}
.deepmala-main {
  color: gray;
  font-size: 18px;
  font-weight: 600;
}
.received {
  color: gray;
  font-size: 14px;
  font-weight: 400;
}

.datetext{
     color: gray;
  font-size: 14px;
  font-weight: 500;
}

.image {
  width: 80px;
}

.second_two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.second_two b {
  color: #000;
}

.second_three {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
  margin-top: 10px;
}
.boxes p {
  color: gray;
  font-size: 15px;
  font-weight: 500;
}
.boxes p b {
  color: #000;
  font-size: 15px;
  font-weight: 500;
}
.boxes {
  width: 25%;
}

.second_four p {
  color: gray;
  font-size: 15px;
  font-weight: 500;
}
.second_five {
  display: flex;
  justify-content: flex-end;
}
.second_five_box {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.second_five_box p {
  color: gray;
  font-size: 15px;
  font-weight: 500;
}

.viewcontact-button {
  background: #0078db;
  border: none;
  padding: 10px 30px 10px 30px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

@media only screen and (max-width: 1202px) and (min-width: 768px) {
  .firstbox {
    width: 15% !important;
  }
  .secondbox {
    width: 85% !important;
  }
}

@media only screen and (max-width: 600px) and (min-width: 360px) {
  /* .firstbox{
        width: 100% !important;
    }
    .secondbox{
        width: 100% !important;
    } */
}


/* new modal  */
.modalopen {
 position: absolute;
    background: white;
    right: 0;
    margin-top: 42px;
    z-index: 1;
    /* padding: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 6px;
}