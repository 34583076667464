.BossContainer {
  background-color: #0068bf;
  height: 55vh;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-top: 10px;
}

.headerparttwo {
  display: none;
}

.header h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}

.header p {
  color: #96c5eb;
  font-size: 1rem;
  text-align: center;
}
.headerparttwo h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}

.headerparttwo p {
  color: #96c5eb;
  font-size: 1rem;
  text-align: center;
}

.BossPlanBoxes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BossPlanBoxone {
  background-color: #fff;
  /* height: 15vh; */
  width: 30%;
  border-radius: 5px;
  /* padding: 10px; */
  margin: 10px;
  box-shadow: 3px 3px 10px gray;
}

.BossPlanBoxtwo {
  background-color: #fff;
  /* height: 42vh; */
  width: 80%;
  border-radius: 5px;
  margin: 10px;
  box-shadow: 3px 3px 10px gray;
}

.bosstwentyfive {
  color: #939aa9;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
}

.contactspermonthstwentyfive {
  color: #000;
  font-size: 18px !important;
  font-weight: 700;
  margin: 0;
}
.monthtext {
  color: #939aa9;
  font-size: 17px;
  font-weight: 500;
}
.starterplansaccess {
  color: #a6adb7;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.BossplanTopBox {
  background-color: #f4f9ff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 5px;
  padding-left: 15px;
}

.BossPlanSelectPlans {
  display: flex;
  justify-content: space-evenly;
}
.BossPlanmonthly {
  padding: 6px;
  border: 1px solid #eee;
  border-radius: 2px;
  margin-top: 10px;
}

.monthsnumtext {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.access25contacts {
  color: #797b81;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.packagetexthighlight {
  color: #005faf;
}

.pricingtextmain {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.pricingtextsub {
  color: #797b81;
  font-size: 13px;
  font-weight: 500;
}

.supertag {
  font-size: 12px;
  vertical-align: top;
  font-weight: 700;
}

.subtag {
  font-size: 10px;
  vertical-align: top;
  font-weight: 500;
}

.savepertext {
  color: #45824a;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
}

.access25contacts {
}

.Bossplanactive {
  background-color: #f0f9ff;
  border-left: 3px solid #005faf;
}
.poularclasspink {
  background-color: darkmagenta;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  margin: 0;
  border: none;
  border-radius: 4px;
  width: 65px;
  height: 20px;
  text-align: center;
  margin-left: 5px;
}

.buybutton {
  padding: 0px 10px 15px 10px;
}
.mainbutton {
  border: 2px solid #0068bf;
  color: #0068bf;
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  /* padding: 7px; */
  height: 6vh;
}
.buybutton2 {
  padding: 0px 10px 15px 10px;
  margin-top: 10px;
}
.mainbutton2 {
  border: 2px solid #0068bf;
  color: #fff;
  width: 100%;
  background-color: #0068bf;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  /* padding: 7px; */
  height: 6vh;
}

.introducttext {
  color: gray;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  margin-right: 21%;
}
.knowbenifits {
  color: #0068bf;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
}

.BossPlanMainseconddiv {
}
.keybenfitsheading {
  color: #797b81;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin: 0;
}
.howbossheading {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}

.mainbossdescdiv {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.descriptiondivboss {
}
.descbossimg {
  display: flex;
  justify-content: center;
}
.bossplansimage {
  width: 400px;
}
.headertext {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.numbering {
  color: #0068bf;
  font-size: 16px;
  font-weight: 700;
}
.paragraphboss {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  width: 70%;
}
.imagesubbossplan {
  width: 30px;
}

.subplansimg {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.mainboximgcontent {
  display: flex;
  margin-top: 15px;
}


.payment-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.payment-success-content {
  text-align: center;
  max-width: 400px;
}

.order-details {
  margin-top: 20px;
}

.order-details p {
  margin-bottom: 5px;
}

.order-details span {
  font-weight: bold;
}

.continue-shopping-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.continue-shopping-btn:hover {
  background-color: #45a049;
}


@media only screen and (max-width: 1020px) and (min-width: 767px) {
  .mainbossdescdiv {
    display: grid;
  }
  .descriptiondivboss {
    /* background-color: red; */
  }
  .mainboximgcontent {
  }
  .descbossimg {
    /* background-color: red; */
    /* width: 60%; */
  }

  .BossPlanBoxes {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* background-color:red; */
    align-items: center;
  }

  .BossPlanBoxone {
    width: 700px;
  }

  .BossContainer {
    height: 70vh;
  }
  .misleanous {
    display: none;
  }
  .BossPlanSelectPlans {
    display: flex;
    flex-wrap: wrap;
  }
  .BossPlanmonthly {
    width: 250px;
  }
  .BossPlanBoxtwo {
    background-color: #fff;
    /* height: 64vh; */
    width: 83%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 660px) {
  .mainbossdescdiv {
    display: grid;
  }

  .BossPlanBoxes {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* background-color:red; */
    align-items: center;
  }

  .BossPlanBoxone {
    width: 350px;
  }
  /* .BossPlanBoxtwo {
    width: 350px;
  } */
  .BossContainer {
    height: 70vh;
  }
  .misleanous {
    display: none;
  }
  .header {
    display: none;
  }
  .headerparttwo {
    display: block;
  }

  .BossPlanSelectPlans {
    display: flex;
    flex-wrap: wrap;
  }
  .BossPlanmonthly {
    width: 250px;
  }
  .BossPlanBoxtwo {
    background-color: #fff;
    /* height: 64vh; */
    width: 90%;
  }
}

@media only screen and (max-width: 660px) and (min-width: 500px) {
  .mainbossdescdiv {
    display: grid;
  }

  .BossPlanBoxes {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* background-color:red; */
    align-items: center;
  }

  .BossPlanBoxone {
    width: 350px;
  }
  /* .BossPlanBoxtwo {
    width: 350px;
  } */
  .BossContainer {
    height: 100vh;
  }
  .misleanous {
    display: none;
  }
  .header {
    display: none;
  }
  .headerparttwo {
    display: block;
  }

  .BossPlanSelectPlans {
    display: flex;
    flex-wrap: wrap;
  }
  .BossPlanmonthly {
    width: 250px;
  }
  .BossPlanBoxtwo {
    background-color: #fff;
    /* height: 64vh; */
    width: 60%;
  }
}

@media only screen and (max-width: 500px) and (min-width: 360px) {
  .mainbossdescdiv {
    display: grid;
    /* width: 100%; */
    /* justify-content: center; */
    /* display: flex; */
    /* align-items: center; */
    /* margin-left: 50px; */
  }
  .BossPlanMainseconddiv {
    margin-bottom: 100px;
  }

  .descbossimg {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    /* background-color: red; */
  }

  .bossplansimage {
    width: 250px;
  }
  .BossPlanBoxes {
    /* width: 100%; */
    display: grid;
    justify-content: center;
    /* background-color:red; */
    align-items: center;
  }

  .BossPlanBoxone {
    width: 320px;
  }
  /* .BossPlanBoxtwo {
    width: 320px;
  } */
  .BossContainer {
    height: 115vh;
  }
  .misleanous {
    display: none;
  }
  .header {
    display: none;
  }
  .headerparttwo {
    display: block;
  }

  .BossPlanBoxes {
    background-color: #fff;
    margin-left: -15px !important;
    margin-right: -15px !important;
    padding: 0 !important;
    border-radius: 10px;
    margin-top: 20px !important;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .mobileview {
    display: block !important;
  }
  .bossimagemisleanous {
    display: block !important;
  }

  .BossPlanSelectPlans {
    display: flex;
    flex-wrap: wrap;
  }
  
  .BossPlanmonthly {
    width: 250px;
  }
  .BossPlanBoxtwo {
    width: 95%;
  }
}
.bossimagenoew {
  width: 300px;
  margin-bottom: -15px !important;
}

.mobileview {
  display: none;
}
.bossimagemisleanous {
  display: none;
}

.mobileview h3 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigate {
  background-color: #f4f5f7;
  padding: 5px;
  display: flex;
  border-radius: 3px;
}
.itemone {
  padding: 5px;
  width: 100%;
  margin-right: 5px;
  border-radius: 3px;
}
.itemone p {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.bossactive {
  background-color: #fff;
  padding: 5px;
  border-radius: 3px;
}
.itemtwo {
  padding: 5px;
  width: 100%;
  margin-left: 5px;
}
.itemtwo p {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.misleanous {
  margin-top: 10px;
}


.selected{
  border: 1px solid #ddd;
  background-color: #E5EFF8;
}
/* saqlaian */

.firstdiv {
  background-color: #f0f9ff;
  height: 112px;
  width: 1170px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5vh;
  border-radius: 7px;
  justify-content: center;
  margin-top: 20px;
}
.img-container {
  display: flex;
  justify-content: flex-start;
}
.img-container img {
  height: 30px;
}
.seconddiv {
  background-color: #fff;
  height: 84px;
  width: 946px;
  border-radius: 7px;
}
.checkcontainer {
  display: flex;
  align-items: center;
  gap: 1vh;
}
.cardcontainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
}

.margin-top {
  margin-top: 10px;
}

.heading {
  font-weight: 700;
  font-family: sans-serif;
  font-size: 16px;
}

.text-color {
  color: #000;
}



.checkcontainer {
  display: flex;
  align-items: center;
}

.checkcontainer .icon {
  font-size: 20px;
  margin-top: 5px;
}



@media screen and (max-width: 768px) {
  .firstdiv {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 1vh;
  }
  .seconddiv {
    width: auto;
    height: auto;
    padding: 5px;
  }
  .cardcontainer {
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 20px;
    gap: 2vh;
  }
  .target {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 2vh;
    align-items: center;
    padding-left: 15px;
  }
  
  .margin-top {
    margin-top: 10px;
  }
  
  .heading {
    font-weight: 800;
    font-family: sans-serif;
    font-size: 16px;
  }
  
  
  
  .checkcontainer {
    display: flex;
    align-items: center;
  }
  
  .checkcontainer .icon {
    font-size: 20px;
    margin-top: 5px;
  }
  
 
}