.BossContainer {
  background-color: #0068bf;
  height: 55vh;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding-top: 10px;
}

.headerparttwo {
  display: none;
}

.header h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}

.header p {
  color: #96c5eb;
  font-size: 1rem;
  text-align: center;
}
.headerparttwo h2 {
  color: #fff;
  font-size: 2rem;
  font-weight: 700;
}

.headerparttwo p {
  color: #96c5eb;
  font-size: 1rem;
  text-align: center;
}

.BossPlanBoxes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BossPlanBoxone {
  background-color: #fff;
  /* height: 15vh; */
  width: 30%;
  border-radius: 5px;
  /* padding: 10px; */
  margin: 10px;
  box-shadow: 3px 3px 10px gray;
}

.BossPlanBoxtwo {
  background-color: #fff;
  /* height: 42vh; */
  width: 80%;
  border-radius: 5px;
  margin: 10px;
  box-shadow: 3px 3px 10px gray;
}

.bosstwentyfive {
  color: #939aa9;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
}

.contactspermonthstwentyfive {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.contactspermonthspost {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  margin: 0;
}
.monthtext {
  color: #939aa9;
  font-size: 17px;
  font-weight: 500;
}
.starterplansaccess {
  color: #a6adb7;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.BossplanTopBox {
  background-color: #f4f9ff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 5px;
  padding-left: 15px;
}

.BossPlanSelectPlans {
  display: flex;
  justify-content: space-evenly;
}
.BossPlanmonthly {
  padding: 6px;
  border: 1px solid #eee;
  border-radius: 2px;
  margin-top: 10px;
}

.monthsnumtext {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.access25contacts {
  color: #797b81;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.packagetexthighlight {
  color: #005faf;
}

.pricingtextmain {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.pricingtextsub {
  color: #797b81;
  font-size: 13px;
  font-weight: 500;
}

.supertag {
  font-size: 12px;
  vertical-align: top;
  font-weight: 700;
}

.subtag {
  font-size: 10px;
  vertical-align: top;
  font-weight: 500;
}

.savepertext {
  color: #45824a;
  font-size: 13px;
  font-weight: 700;
  margin: 0;
}

.access25contacts {
}

.Bossplanactive {
  background-color: #f0f9ff;
  border-left: 3px solid #005faf;
}
.poularclasspink {
  background-color: darkmagenta;
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  margin: 0;
  border: none;
  border-radius: 4px;
  width: 65px;
  height: 20px;
  text-align: center;
  margin-left: 5px;
}

.buybutton {
  padding: 0px 10px 15px 10px;
}
.mainbutton {
  border: 2px solid #0068bf;
  color: #0068bf;
  width: 100%;
  background-color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  /* padding: 7px; */
  height: 6vh;
}
.buybutton2 {
  padding: 0px 10px 15px 10px;
  margin-top: 10px;
}
.mainbutton2 {
  border: 2px solid #0068bf;
  color: #fff;
  width: 100%;
  background-color: #0068bf;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
  /* padding: 7px; */
  height: 6vh;
}

.introducttext {
  color: gray;
  font-size: 13px;
  font-weight: 500;
  text-align: right;
  margin-right: 21%;
}
.knowbenifits {
  color: #0068bf;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
}

.BossPlanMainseconddiv {
}
.keybenfitsheading {
  color: #797b81;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin: 0;
}
.howbossheading {
  color: #000;
  font-size: 22px;
  font-weight: 700;
  text-align: left;
}

.mainbossdescdiv {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.descriptiondivboss {
}
.descbossimg {
  display: flex;
  justify-content: center;
}
.bossplansimage {
  width: 400px;
}
.headertext {
  color: #000;
  font-size: 16px;
  font-weight: 700;
}
.numbering {
  color: #0068bf;
  font-size: 16px;
  font-weight: 700;
}
.paragraphboss {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  width: 70%;
}
.imagesubbossplan {
  width: 30px;
}

.subplansimg {
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.mainboximgcontent {
  display: flex;
  margin-top: 15px;
}

@media only screen and (max-width: 1020px) and (min-width: 767px) {
  .mainbossdescdiv {
    display: grid;
  }
  .descriptiondivboss {
    /* background-color: red; */
  }
  .mainboximgcontent {
  }
  .descbossimg {
    /* background-color: red; */
    /* width: 60%; */
  }

  .BossPlanBoxes {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* background-color:red; */
    align-items: center;
  }

  .BossPlanBoxone {
    width: 700px;
  }

  .BossContainer {
    height: 70vh;
  }
  .misleanous {
    display: none;
  }
  .BossPlanSelectPlans {
    display: flex;
    flex-wrap: wrap;
  }
  .BossPlanmonthly {
    width: 250px;
  }
  .BossPlanBoxtwo {
    background-color: #fff;
    /* height: 64vh; */
    width: 83%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 660px) {
  .mainbossdescdiv {
    display: grid;
  }

  .BossPlanBoxes {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* background-color:red; */
    align-items: center;
  }

  .BossPlanBoxone {
    width: 350px;
  }
  /* .BossPlanBoxtwo {
    width: 350px;
  } */
  .BossContainer {
    height: 70vh;
  }
  .misleanous {
    display: none;
  }
  .header {
    display: none;
  }
  .headerparttwo {
    display: block;
  }

  .BossPlanSelectPlans {
    display: flex;
    flex-wrap: wrap;
  }
  .BossPlanmonthly {
    width: 250px;
  }
  .BossPlanBoxtwo {
    background-color: #fff;
    /* height: 64vh; */
    width: 90%;
  }
}

@media only screen and (max-width: 660px) and (min-width: 500px) {
  .mainbossdescdiv {
    display: grid;
  }

  .BossPlanBoxes {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    /* background-color:red; */
    align-items: center;
  }

  .BossPlanBoxone {
    width: 350px;
  }
  /* .BossPlanBoxtwo {
    width: 350px;
  } */
  .BossContainer {
    height: 100vh;
  }
  .misleanous {
    display: none;
  }
  .header {
    display: none;
  }
  .headerparttwo {
    display: block;
  }

  .BossPlanSelectPlans {
    display: flex;
    flex-wrap: wrap;
  }
  .BossPlanmonthly {
    width: 250px;
  }
  .BossPlanBoxtwo {
    background-color: #fff;
    /* height: 64vh; */
    width: 60%;
  }
}

@media only screen and (max-width: 500px) and (min-width: 360px) {
  .mainbossdescdiv {
    display: grid;
    /* width: 100%; */
    /* justify-content: center; */
    /* display: flex; */
    /* align-items: center; */
    /* margin-left: 50px; */
  }
  .BossPlanMainseconddiv {
    margin-bottom: 100px;
  }

  .descbossimg {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    /* background-color: red; */
  }

  .bossplansimage {
    width: 250px;
  }
  .BossPlanBoxes {
    /* width: 100%; */
    display: grid;
    justify-content: center;
    /* background-color:red; */
    align-items: center;
  }

  .BossPlanBoxone {
    width: 320px;
  }
  /* .BossPlanBoxtwo {
    width: 320px;
  } */
  .BossContainer {
    height: 115vh;
  }
  .misleanous {
    display: none;
  }
  .header {
    display: none;
  }
  .headerparttwo {
    display: block;
  }

  .BossPlanBoxes {
    background-color: #fff;
    margin-left: -15px !important;
    margin-right: -15px !important;
    padding: 0 !important;
    border-radius: 10px;
    margin-top: 20px !important;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .mobileview {
    display: block !important;
  }
  .bossimagemisleanous {
    display: block !important;
  }

  .BossPlanSelectPlans {
    display: flex;
    flex-wrap: wrap;
  }
  .BossPlanmonthly {
    width: 250px;
  }
  .BossPlanBoxtwo {
    width: 95%;
  }
}
.bossimagenoew {
  width: 300px;
  margin-bottom: -15px !important;
}

.mobileview {
  display: none;
}
.bossimagemisleanous {
  display: none;
}

.mobileview h3 {
  color: #000;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}

.navigate {
  background-color: #f4f5f7;
  padding: 5px;
  display: flex;
  border-radius: 3px;
}
.itemone {
  padding: 5px;
  width: 100%;
  margin-right: 5px;
  border-radius: 3px;
}
.itemone p {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.bossactive {
  background-color: #fff;
  padding: 5px;
  border-radius: 3px;
}
.itemtwo {
  padding: 5px;
  width: 100%;
  margin-left: 5px;
}
.itemtwo p {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.misleanous {
  margin-top: 10px;
}

.selected {
  border: 1px solid #ddd;
  background-color: #e5eff8;
}

/*--------------------------------------------*/
.sub-varify {
  height: 20px;
  width: 100px;
  padding: 5px;
  background-color: #15c05d;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.status {
  height: 20px;
  width: 100%;
  padding: 5px;
  background-color: #16a085;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.menu-bar {
  padding-top: 26px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #666666;
}
.nbtn {
  color: #96c5eb;
  font-size: 15px;
  margin-bottom: 15px;
}
.icon i {
  font-size: 23px;
  padding-right: 5px;
}
.banner2 {
  display: flex;
}
.img-round {
  border-radius: 12px;
}
.banner2 p {
  color: #a6adb7;
}
.banner2-div {
  margin-top: 12px;
  height: 100px;
}
.places {
  border: 0.2px solid #96c5eb;
  width: 190px;
  margin-left: 4px;
  padding: 2px;
  border-radius: 8px;
}
.icon {
  display: flex;
  margin: 10px;
  filter: none;
  -webkit-filter: grayscale(0%);
}
.abcd {
  -webkit-filter: grayscale(0%);
  filter: none;
}
.iconcolorRemove {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 18px;
  padding: 12px;
  border: none;
  outline: none;
  color: #fff;
  font-weight: 700;
  background-color: #666e79;
  cursor: pointer;
}
.contact-btn {
  background-color: #005faf;
  color: #fff;
  margin-top: 10px;
}
button:hover {
  border-radius: 5px;
  background-color: #666;
}

.society {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}
.button-download {
  margin-top: 30px;
  color: #0078db;
  background-color: #fff;
  border: 1px solid #0078db;
  margin-bottom: 20px;
}
.button-download i {
  font-size: 20px;
  padding-right: 5px;
}
.button-download h2 {
  font-size: 16px;
}
.slider-border {
  width: 280px;
  height: 290px;
  padding: 6px;
  padding-top: 30px;
}
.slider-img {
  width: 270px;
  overflow: hidden;
  height: 190px;
  transition: 2s;
}
.slider-img img:hover {
  transform: scale(1.07);
  /* opacity: 0.8; */
  transition: 1s;
  cursor: pointer;
}
.lastarticle {
  background-color: #f5f7fa;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
}
.name {
  padding-bottom: 10px;
  padding-top: 30px;
  padding-left: 28px;
}
.ul {
  margin-bottom: 7px;
  font-size: medium;
}
.ul .a {
  color: #42526e;
}
.ul .a:hover {
  color: #3399cc;
}
.arrow {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #3399cc;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.imgs {
  width: 13rem;
  height: 9rem;
}
.cards {
  border: 0.5px solid rgb(231, 227, 227);
  width: 13rem;
  height: 17rem;
}
.custom-btn {
  border-top: 0.3px solid rgb(234, 231, 231);
  padding: 5px;
}
.custom-btn h2 a {
  color: #15c05d;
  font-weight: 700;
  padding: 5px;
}
.custom-body {
  padding: 5px;
}
/* .custom-body h5 {
  padding: 5px;
} */
.custom-body h5 {
  color: #005faf;
  font-weight: 700;
}
.img-body {
  position: relative;
}
.img-body .img-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #666;
  color: #fff;
  font-size: 9px;
  padding: 2px;
}
.recommend-box {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.registry-btn {
  background-color: #fff;
  color: #3399cc;
  border: 1px solid #3399cc;
  font-size: 17px;
}
.transactionp {
  font-size: 25px;
  font-weight: 700;
  padding-bottom: 10px;
}
.recent {
  padding: 20px;
  background-color: rgb(246, 233, 153);
  border-radius: 8px;
}
.recent-icon {
  font-size: 30px;
  color: gold;
  margin-right: 18px;
  font-weight: 800;
}
.recent-icon1 {
  font-size: 30px;
  color: gold;
  margin-left: 18px;
  font-weight: 800;
}
.recent-body h2 {
  font-size: 18px;
  color: #041533;
  padding-top: 10px;
}
.recent-body p {
  color: #42526e;
}
.avg {
  padding: 20px;
  background-color: #91d3f5;
  border-radius: 8px;
}
.avg-icon {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background-color: #005faf;
  color: #fff;
  font-size: 30px;
  margin-right: 18px;
  font-weight: 800;
}
.avg-icon1 {
  font-size: 30px;
  color: #005faf;
  margin-left: 18px;
  font-weight: 800;
}
.avg-body h2 {
  font-size: 18px;
  color: #041533;
  padding-top: 10px;
}
.avg-body p {
  color: #42526e;
}
.transationpr {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.last-mainbtn {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.last-btn {
  padding: 10px 60px;
  border: 1px solid #0068bf;
  background-color: #fff;
  color: #0068bf;
  font-size: 15px;
  border-radius: 7px;
}
.dealer-btn {
  background-color: #0078db;
  color: #fff;
  margin-top: 20px;
  /* margin-left: -15px; */
  font-size: medium;
  margin: 12px;
}
.dealer-last {
  border-top: 0.3px solid rgb(235, 232, 232);
  padding: 20px 0px;
  width: 100%;
}
.dealer-last h2 {
  font-size: medium;
}
.dealer-last p {
  font-size: small;
}
.enquiry-btn {
  padding: 15px;
  background-color: #005faf;
  color: #fff;
  font-size: medium;
  font-weight: 600;
}
.check-box {
  display: flex;
  padding-top: 16px;
}
.check-box input {
  height: 15px;
  width: 15px;
}
.check-box p {
  padding: 11px px;
  color: #666e79;
}
.dealer-radio {
  padding: 10px;
}
.main-delarradio {
  margin: 10px 0px;
}
.input-text {
  background-color: #fff;
  margin: 7px 0px;
  width: 230px;
}
.input-text input {
  border: none;
  padding: 7px;
}
.main-table {
  width: 100%;
  overflow-x: scroll;
}
.menu-barslider {
  width: 100%;
  overflow-x: scroll;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 13px;
}

tr:nth-child(even) {
  background-color: #fff;
}
.explo-btn {
  background-color: #fff;
  color: #005faf;
  border: 0.3px solid #3399cc;
  border-radius: 5px;
  font-size: medium;
  padding: 15px;
  margin-top: 20px;
}
.Explore h1 {
  font-size: 25px;
  font-weight: 700;
  margin-top: 20px;
}
.Explore p {
  color: #666;
  font-size: 15px;
}
.explorek {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.sub-explorek {
  background-color: #f4f5f7;
  padding: 4px;
}
.sub-explorek a {
  color: #42526e;
  font-size: 15px;
}
.sub-explorek a i {
  color: #15c05d;
  font-size: 20px;
  font-weight: 700;
  padding-top: 3px;
}
.maingreat-hear {
  margin-top: 30px;
  border: 0.3px solid #f4f5f7;
  padding: 15px;
  transition: 1s;
  cursor: pointer;
}
.maingreat-hear:hover {
  /* transform: scale(1.2); */
  transition: 1s;
  box-shadow: 0px 0px 20px #c5c8cc;
}
.great-hear {
  display: flex;
  padding-bottom: 10px;
}
.great-hear i {
  padding-right: 10px;
  font-size: 20px;
}
.great-hear h2 {
  font-size: 20px;
  font-weight: 600;
}
.great-hear p {
  color: #42526e;
}
.super-greathear {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.locality-btn {
  background-color: #fff;
  color: #005faf;
  border: 0.3px solid #3399cc;
  border-radius: 5px;
  font-size: medium;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.locality h2 {
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}
.locality p {
  color: #666;
  font-size: 15px;
}
.review-btn {
  background-color: #fff;
  color: #005faf;
  border: 0.3px solid #3399cc;
  border-radius: 5px;
  font-size: medium;
  padding: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.review h2 {
  font-size: 25px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}
.residents-btn button {
  padding: 5px;
  border: 1px solid #f4f5f7;
  border-radius: 10px;
  width: 150px;
  color: #42526e;
  background-color: #fff;
  font-size: 15px;
}
.residents {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.crd {
  background-color: rgb(4, 65, 4);
  color: #fff;
  display: flex;
  width: 60px;
}
.crd strong {
  padding-left: 5px;
}
.crd i {
  padding-left: 5px;
  color: #fff;
}
.crd1 p {
  padding: 8px 0px;
}
.crd3 {
  display: flex;
  margin-top: 20px;
}
.positive-subcard {
  margin-top: 20px;
  border: 1px solid #f4f5f7;
  padding: 18px;
  transition: 1s;
}
.positive-subcard:hover {
  box-shadow: 0px 0px 20px #c5c8cc;
  transition: 1s;
  cursor: pointer;
}
.positive-card {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
.review-btn {
  background-color: #0068bf;
  color: #fff;
}
.cards {
  transition: 1s;
}
.cards:hover {
  box-shadow: 0px 0px 20px #c5c8cc;
  transition: 1s;
  cursor: pointer;
}
.card-vesse {
  margin-left: 5px;
}
/*------------------------------------SUBBANNER-------------------------*/
.header-part {
  display: flex;
}
/* .main-headercro {
  display: flex;
} */

.header-croce {
  display: flex;
  padding-right: 20px;
}
.header-croce2 {
  margin-top: 26px;
}
.header-croce1 h1 {
  font-size: 42px;
  font-weight: 700;
  color: #666666;
}
.header-croce3 i {
  font-size: 20px;
  margin-top: 25px;
  color: #666666;
}
.sub-header {
  margin-top: 13px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.estimated a {
  color: #0078db;
}
.header-part2 {
  border-right: 0.3px solid #666;
}
.rerastatu {
  height: 20px;
  width: 160px;
  padding: 5px;
  background-color: #16a085;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.registred {
  color: #15c05d;
  background-color: #f4f5f7;
  margin-left: 5px;
}
.registern {
  border-left: 0.5px solid #42526e;
  border-right: 0.5px solid #42526e;
  background-color: #f4f5f7;
  padding: 0px 6px;
}
.website {
  background-color: #f4f5f7;
}
.bedroom {
  font-size: 30px;
  color: #666666;
  font-weight: 600;
  margin-left: 15px;
  padding-top: 10px;
}
.sub-bannerbtn {
  background-color: #3270fc;
  color: #fff;
  padding: 10px 20px;
  font-size: 15px;
  margin-top: 10px;
  margin-left: -2px;
}
.featuredd {
  font-size: small;
  padding-left: 8px;
  background: linear-gradient(296.16deg, #ffa63e 6.58%, #ff6c3e 50%);
  color: #fff;
  width: 120px;
  height: 20px;
  margin-top: 10px;
}
.govi {
  font-weight: 700;
}
.Independent h2 {
  font-size: 16px;
}
.Independent p {
  font-size: 12px;
}
/* @media screen and(max-width:576px) {
  .slider-imgs {
    width: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11rem;
    transition: 2s;
  }
 .recommend-box{
  padding-left: 45px;
 }
}  */
.viewall {
  margin-top: 15px;
}
.box-header {
  display: flex;
}
.sreevi p {
  font-size: 12px;
}
.sreevi h2 {
  font-size: 18px;
  font-weight: bold;
}
.featured-header {
  display: flex;
  padding-top: 5px;
}
.featured {
  font-size: 12px;
  padding-left: 5px;
  background: linear-gradient(296.16deg, #ffa63e 6.58%, #ff6c3e 50%);
  color: #fff;
  width: 120px;
  height: 20px;
}
.membes {
  padding-left: 5px;
  border-left: 1px solid #666;
  font-size: 8px;
}
.sub-ready {
  display: flex;
}
.sub-ready i {
  color: #3399cc;
  padding-top: 2px;
}
.sub-ready p {
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #f0f9ff;
}
.posted {
  font-size: 12px;
  padding-left: 5px;
  border-left: 1px solid #ddd;
  background-color: #f0f9ff;
}
.main-ready {
  display: flex;
  margin-top: 10px;
}
.main-subbanner {
  margin-top: 70px;
}
.overview-banner2 {
  background-color: white;
  margin-left: 10px;
  border-radius: 10px;
  margin-top: 30px;
}
.whyshould {
  margin-top: 40px;
  background-color: white;
  font-size: 25px;
  padding: 30px 10px;
  border-radius: 10px;
}

@media only screen and (max-width: 670px) {
  .slider-imgs {
    width: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16rem;
    text-align: center;
    padding-right: 20px;
  }
  .header-croce2,
  .estimated,
  .Independent,
  .paragraph {
    display: none;
  }
  .header-croce1 h1 {
    font-size: 35px;
    padding-top: 7px;
  }
  .bedroom {
    font-size: 20px;
    /* padding-top: -5px; */
  }
  .registern {
    margin: 7px 0px;
  }
  .header-croce {
    display: flex;
  }
  .recommend-box {
    padding-left: 45px;
  }
  .overview-banner2 {
    margin: 10px;
  }
  .explo-btnbtn {
    /* margin-left: -14px; */
  }
  .dealer-btn {
    font-size: 13px;
  }
  .enquiry-btn {
    margin-left: -5px;
    font-size: 13px;
  }
  .review-btn {
    margin-top: -10px;
    margin-left: -5px;
    font-size: 12px;
  }
  .whyshould {
    border-radius: 10px;
  }
  .whyshould h1 {
    font-size: 12px;
  }
  .residents {
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 20px;
    width: 100%;
    margin-left: -20px;
  }
  .review-btn {
    margin-top: 15px;
  }
  .registry-btn {
    margin-left: -5px;
    margin-top: 8px;
    font-size: 10px;
  }
  .recent-body h2 {
    font-size: 12px;
  }
  .avg-body h2 {
    font-size: 11px;
  }
  /* .recent-body p {
    font-size: 11px;
  } */
  .transationpr {
    margin-left: -20px;
    margin-top: -15px;
  }
  .dealer-btn {
    margin-left: -6px;
  }
  .button-download {
    margin-left: -5px;
  }
  .button-download i,
  h2 {
    font-size: 11px;
  }
  .societyind {
    margin-top: -90px;
  }
  .viewall {
    margin-top: 10px;
  }
  .lastarticle .name {
    /* padding-left: 43px; */
    text-align: center;
  }
  /* .slider-img {
    margin: 40px;
  } */
}
@media only screen and (min-width: 1020px) {
  /* .positive-card {
    display: grid;
    grid-template-columns: 6fr 6fr;
    width: 100%;
  } */
}
